import React from 'react'
import {Link, useHistory} from 'react-router-dom'
import axios from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

function Navbar() {
    const history = useHistory()
    const logout = (e) => {
        e.preventDefault()
        axios.post('/api/adminlogout').then(res => {
            if (res.data.status === 200){
                localStorage.clear()
                history.push('/adminlogin')
            }
        })
    }
  return (
    <nav className="sb-topnav navbar navbar-expand navbar-dark bg-success">
        <Link className="navbar-brand ps-3 text-center" to="/admin101/dashboard">Atelier Mbike</Link>
        <button className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0 faBar" id="sidebarToggle"><FontAwesomeIcon icon={faBars} /></button>
        <ul className='navbar-nav m-auto'>
            <li className="nav-item">
                <Link className='nav-link active text-capitalize' to="/admin101/rapportactivity">rapport d'activités</Link>
            </li>
            <li className="nav-item">
                <Link className='nav-link active text-capitalize' to="/admin101/prevision">prévision</Link>
            </li>
            <li className="nav-item">
                <Link className='nav-link active text-capitalize' to="/admin101/fiche">catalogue</Link>
            </li>
            <li className="nav-item">
                <Link className='nav-link active text-capitalize' to="/admin101/export">extraction</Link>
            </li>
        </ul>
        <ul className="navbar-nav ms-auto ms-md-0 me-3 me-lg-4">
            <li className="nav-item dropdown">
                <Link to="#" className="nav-link dropdown-toggle text-capitalize" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">{localStorage.getItem('admin_name')}</Link>
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                    <li><Link to="" className="dropdown-item" onClick={logout}><FontAwesomeIcon icon={faSignOutAlt} /> Se déconnecter</Link></li>
                </ul>
            </li>
        </ul>
    </nav>
  )
}

export default Navbar

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'

function InterventionEdit(props) {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [reparations, setReparations] = useState([])
  const [intervention, setIntervention] = useState({
    nom:'',
    tarif:'',
    reparation_id:'',
    error:[],
  })

  useEffect(() => {
    const id = props.match.params.id
    axios.get(`/api/interventionedit/${id}`).then(res => {
      if (res.data.status === 200) {
        setIntervention(res.data.intervention)
        fetchdata()
        setLoading(false)
      } else {
        history.push('/admin101/intervention')
      }
    })
  }, [props.match.params.id, history])

  const fetchdata = () => {
    axios.get('/api/reparationlist').then(res => {
      setReparations(res.data.reparation)
    })
  }

  const reparationlist = reparations.map(reparation => 
    <option className='text-capitalize' key={reparation.id} value={reparation.id}>{reparation.nom}</option>
    )

  const handleInput = (e) => {
    e.persist()
    setIntervention({...intervention, [e.target.name]: e.target.value })
  }

  const interventionSubmit = (e) => {
    e.preventDefault()
    const id = props.match.params.id
    const data = {
      nom: intervention.nom,
      tarif: intervention.tarif,
      reparation_id: intervention.reparation_id,
    }
    axios.put(`/api/interventionupdate/${id}`, data).then(res => {
      if(res.data.status === 200){
        history.push('/admin101/intervention')
      } else {
        setIntervention({...intervention, error: res.data.errors})
        console.log(res.data.errors)
      }
    })
  }

  if (loading) {
    return <p></p> 
  }

  return (
    <div className='container mt-5'>
      <div className='card'>
        <div className='card-header text-capitalize h4'>modification intervention</div>
        <div className='card-body'>
          <form className='form-group' onSubmit={interventionSubmit} id="intervention_form">
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='nom' className="form-control" placeholder="Nom" onChange={handleInput} value={intervention.nom} />
              </div>
              <div className="col-md form-group">
                <input type="text" name='tarif' className="form-control" placeholder="Tarif" onChange={handleInput} value={intervention.tarif} />
              </div>
            </div>
            <div className='mb-3 form-group'>
              <select name="reparation_id" className='form-select text-capitalize' value={intervention.reparation_id} onChange={handleInput} >
                <option value="" className='text-capitalize'>catégorie</option>
                {reparationlist}
              </select>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <button type='submit' className="border rounded-pill text-capitalize btn btn-outline-primary">modifier</button>
              </div>
              <div className="col-auto">
                <Link to='/admin101/intervention' className='text-capitalize btn btn-outline-secondary border rounded-pill' >retour</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default InterventionEdit

import { faMessage, faPhone, faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function Contact() {
  return (
    <div className="container mt-4 mb-4">
    <h1 className="text-center">Contactez - nous</h1>
    <div className="row mt-5">
        <div className="col-lg col-md-auto mb-3">
            <div className="row">
                <div className="col-lg col-md-auto">
                    <p><span className="text-capitalize fw-bold">atelier mbike</span><br/> 12, Rue Rabearivelo, Antsahavola<br/> Antananarivo, 101</p>
                    <p className='fw-bold'><FontAwesomeIcon icon={faMessage} /> reparation@mbike.mg</p>
                </div>
                <div className="col-lg col-md-auto">
                    <p><span className="text-capitalize fw-bold">horaires</span><br/> Lundi - Samedi : 7:00 - 16:00<br/> Dimanche : Fermé</p>
                    <p className='fw-bold'><FontAwesomeIcon icon={faPhone} /> 034 83 738 62</p>
                </div>
            </div>
            <div>
                <form>
                    <div className='row'>
                        <div className='col-lg col-md-auto'>
                            <div className='form-group mb-3'>
                                <label className='text-capitalize'>nom</label>
                                <input type="text" name="nom" className="form-control" />
                            </div>
                        </div>
                        <div className='col-lg col-md-auto'>
                            <div className='form-group mb-3'>
                                <label className='text-capitalize'>prenom</label>
                                <input type="text" name="prenom" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg col-md-auto'>
                            <div className='form-group mb-3'>
                                <label className='text-capitalize'>email</label>
                                <input type="text" name="email" className="form-control" />
                            </div>
                        </div>
                        <div className='col-lg col-md-auto'>
                            <div className='form-group mb-3'>
                                <label className='text-capitalize'>telephone</label>
                                <input type="text" name="phone" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className='form-group mb-3'>
                        <label className='text-capitalize'>message</label>
                        <textarea name="message" className='form-control' id="" cols="30" rows="7"></textarea>
                    </div>
                    <div className='form-group text-center'>
                        <button type='submit' className="border rounded-pill text-capitalize btn btn-success"><FontAwesomeIcon icon={faPaperPlane} /> envoyer</button> <br/>
                    </div>
                </form>
            </div>
        </div>
        <div className="col-lg col-md-auto">
            <div className="ratio ratio-1x1">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d367.93940588816855!2d47.52315690214188!3d-18.9093781909616!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x21faffaf921bfab1%3A0xebae07ca5c4c7831!2sMbike%20adventure!5e1!3m2!1sfr!2smg!4v1654589079049!5m2!1sfr!2smg" data-map="JTdCJTIycG9zaXRpb25UeXBlJTIyJTNBJTIybWFwLWVtYmVkJTIyJTJDJTIyYWRkcmVzcyUyMiUzQSUyMkFudHNhaGF2b2xhJTJDJTIwQW50YW5hbmFyaXZvJTJDJTIwTWFkYWdhc2NhciUyMiUyQyUyMnpvb20lMjIlM0ExMCUyQyUyMnR5cGVJZCUyMiUzQSUyMnJvYWQlMjIlMkMlMjJsYW5nJTIyJTNBJTIyJTIyJTJDJTIyYXBpS2V5JTIyJTNBJTIyJTIyJTJDJTIyZW1iZWQlMjIlM0ElMjJodHRwcyUzQSUyRiUyRnd3dy5nb29nbGUuY29tJTJGbWFwcyUyRmVtYmVkJTNGcGIlM0QhMW0xOCExbTEyITFtMyExZDM2Ny45Mzk0MDU4ODgxNjg1NSEyZDQ3LjUyMzE1NjkwMjE0MTg4ITNkLTE4LjkwOTM3ODE5MDk2MTYhMm0zITFmMCEyZjAhM2YwITNtMiExaTEwMjQhMmk3NjghNGYxMy4xITNtMyExbTIhMXMweDIxZmFmZmFmOTIxYmZhYjElMjUzQTB4ZWJhZTA3Y2E1YzRjNzgzMSEyc01iaWtlJTI1MjBhZHZlbnR1cmUhNWUxITNtMiExc2ZyITJzbWchNHYxNjU0NTg5MDc5MDQ5ITVtMiExc2ZyITJzbWclMjIlN0Q=" title='map'></iframe>
            </div>
        </div>
    </div>
    </div>
  )
}

export default Contact

import AddRendezvous from '../components/client/account/AddRendezVous'
import AddVelo from '../components/client/account/AddVelo'
import Dashboard from '../components/client/account/Dashboard'
import ListeRendezVous from '../components/client/account/ListeRendezVous'
import ListeVelo from '../components/client/account/ListeVelo'
import Profile from '../components/client/account/Profile'

const accounts = [
    { path: '/account', exact: true, name: 'Account' },
    { path: '/account/addrendezvous', exact: true, name: 'AddRendezVous', component: AddRendezvous },
    { path: '/account/addvelo', exact: true, name: 'AddVelo', component: AddVelo },
    { path: '/account/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/account/listerendezvous/:id.:nom', exact: true, name: 'ListeRendezVous', component: ListeRendezVous },
    { path: '/account/listevelo/:id.:nom', exact: true, name: 'ListeVelo', component: ListeVelo },
    { path: '/account/profile/:id.:nom', exact: true, name: 'Profile', component: Profile },
]

export default accounts
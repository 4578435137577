import React from "react";
import "../../../assets/admin/css/modal.css";

function ReservationDetails(props) {
  if (!props.show) {
    return null;
  }
  return (
    <div className="test-modal" onClick={props.onClose}>
      <div className="test-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="test-modal-header">
          <h6 className="test-modal-title text-capitalize">
            réservation de {props.detail.velo.user.nom}{" "}
            {props.detail.velo.user.prenom}
          </h6>
          <button className="btn btn-close" onClick={props.onClose}></button>
        </div>
        <div className="test-modal-body">
          <div className="card mb-3">
            <div className="card-header text-white bg-success">
              <h5 className="card-title text-capitalize">
                {props.detail.velo.user.nom} {props.detail.velo.user.prenom}
              </h5>
            </div>
            <div className="card-body">
              <h6 className="card-title">
                Tel : {props.detail.velo.user.phone}
              </h6>
              <h6 className="card-title">
                Email : {props.detail.velo.user.email}
              </h6>
              <h6 className="card-title">
                Adresse :{" "}
                <span className="text-capitalize">
                  {props.detail.adresse_domicile}
                </span>
              </h6>
            </div>
          </div>
          <div className="row row-cols-1 row-cols-md-2 g-2">
            <div className="col">
              <div className="card mb-3">
                <div className="card-header text-white bg-success">
                  <h5 className="card-title text-capitalize">réservation</h5>
                </div>
                <div className="card-body">
                  {props.detail.intervention !== "commande pièce" ? (
                    <>
                      <h6 className="card-title">
                        Date : {props.detail.date} &nbsp; &nbsp; || &nbsp;
                        &nbsp; Etat :{" "}
                        <span className="text-capitalize">
                          {props.detail.etat === "a traiter" ? (
                            <span className="badge rounded-pill p-2 text-bg-warning">
                              {props.detail.etat}
                            </span>
                          ) : props.detail.etat === "en cours" ? (
                            <span className="badge rounded-pill p-2 text-bg-primary">
                              {props.detail.etat}
                            </span>
                          ) : props.detail.etat === "réalisé" ? (
                            <span className="badge rounded-pill p-2 text-bg-success">
                              {props.detail.etat}
                            </span>
                          ) : props.detail.etat === "en attente" ? (
                            <span className="badge rounded-pill p-2 text-bg-danger">
                              {props.detail.etat}
                            </span>
                          ) : props.detail.etat === "annulé" ? (
                            <span className="badge rounded-pill p-2 text-bg-danger">
                              {props.detail.etat}
                            </span>
                          ) : null}
                        </span>
                      </h6>
                      <h6 className="card-title">
                        Intervention :{" "}
                        <span className="text-capitalize">
                          {props.detail.intervention}
                        </span>
                      </h6>
                      <p className="card-text"></p>
                      <h6 className="card-title">
                        Problèmes constatés :{" "}
                        <span className="text-capitalize">
                          {props.detail.pbm_constates}
                        </span>
                      </h6>
                    </>
                  ) : (
                    <>
                      <h6 className="card-title">
                        Utile le : {props.detail.date} / À commander le :{" "}
                        {props.detail.date_pem} <br />
                        <br />
                        Etat :{" "}
                        <span className="text-capitalize">
                          {props.detail.etat === "a traiter" ? (
                            <span className="badge rounded-pill p-2 text-bg-warning">
                              {props.detail.etat}
                            </span>
                          ) : props.detail.etat === "en cours" ? (
                            <span className="badge rounded-pill p-2 text-bg-primary">
                              {props.detail.etat}
                            </span>
                          ) : props.detail.etat === "réalisé" ? (
                            <span className="badge rounded-pill p-2 text-bg-success">
                              {props.detail.etat}
                            </span>
                          ) : props.detail.etat === "en attente" ? (
                            <span className="badge rounded-pill p-2 text-bg-danger">
                              {props.detail.etat}
                            </span>
                          ) : props.detail.etat === "annulé" ? (
                            <span className="badge rounded-pill p-2 text-bg-danger">
                              {props.detail.etat}
                            </span>
                          ) : null}
                        </span>
                      </h6>
                      <h6 className="card-title">
                        Intervention :{" "}
                        <span className="text-capitalize">
                          {props.detail.intervention}
                        </span>
                      </h6>
                      <p className="card-text"></p>
                      <h6 className="card-title">
                        Lien de la pièce :{" "}
                        <a href={props.detail.lien} target="blank">
                          {props.detail.lien}
                        </a>
                      </h6>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card mb-3">
                <div className="card-header text-white bg-success">
                  <h5 className="card-title text-capitalize">vélo</h5>
                </div>
                <div className="card-body">
                  <h6 className="card-title">
                    Marque :{" "}
                    <span className="text-uppercase">
                      {props.detail.velo.marque}
                    </span>{" "}
                    &nbsp; &nbsp; || &nbsp; &nbsp; Type :{" "}
                    <span className="text-uppercase">
                      {props.detail.velo.type}
                    </span>
                  </h6>
                  <p className="card-text"></p>
                  <h6 className="card-title">
                    Modele :{" "}
                    <span className="text-uppercase">
                      {props.detail.velo.modele}
                    </span>{" "}
                    &nbsp; &nbsp; || &nbsp; &nbsp; Taille :{" "}
                    <span className="text-uppercase">
                      {props.detail.velo.taille}
                    </span>
                  </h6>
                  <p className="card-text"></p>
                  <h6 className="card-title">
                    Coulleur :{" "}
                    <span className="text-capitalize">
                      {props.detail.velo.couleur}
                    </span>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReservationDetails;

import axios from 'axios'
import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'

function ClientAdd() {
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [client, setClient] = useState({
        nom:'',
        prenom:'',
        email:'',
        phone:'',
        password:'',
        error:[],
    })

    const handleInput = (e) => {
        e.persist()
        setClient({ ...client, [e.target.name]: e.target.value })
    }

    const clientSubmit = (e) => {
      setLoading(true)
        e.preventDefault()
        const data = {
            nom: client.nom,
            prenom: client.prenom,
            email: client.email,
            phone: client.phone,
            password: client.password,
        }
        axios.post(`/api/clientadd`, data).then(res => {
            if (res.data.status === 200) {
              setLoading(false)
                history.push('/admin101/client')
            } else {
                setClient({ ...client, error: res.data.errors })
                setLoading(false)
            }
        })
    }

    if(loading){
      return (
        <div className="d-flex justify-content-center container py-5">
          <div className="spinner-border text-success my-5" role='status'></div>
        </div>
      )
    }

  return (
    <div className='container mt-5'>
      <div className='card'>
        <div className='card-header text-capitalize h4'>ajout client</div>
        <div className='card-body'>
          <form className='form-group' onSubmit={clientSubmit} id="client_form">
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='nom' className="form-control" placeholder="Nom" onChange={handleInput} value={client.nom} />
                <span className="text-danger">{client.error.nom}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='prenom' className="form-control" placeholder="Prénom" onChange={handleInput} value={client.prenom} />
                <span className="text-danger">{client.error.prenom}</span>
              </div>
            </div>
            <div className='mb-3 form-group'>
              <input type="text" name='email' className="form-control" placeholder="Email" onChange={handleInput} value={client.email} />
              <span className="text-danger">{client.error.email}</span>
            </div>
            <div className='mb-3 form-group'>
              <input type="text" name='phone' className="form-control" placeholder="Téléphone" onChange={handleInput} value={client.phone} />
              <span className="text-danger">{client.error.phone}</span>
            </div>
            <div className='mb-3 form-group'>
              <input type="password" name='password' className="form-control" placeholder="Mot de passe" onChange={handleInput} value={client.password} />
              <span className="text-danger">{client.error.password}</span>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <button type='submit' className="border rounded-pill text-capitalize btn btn-outline-primary">ajouter</button>
              </div>
              <div className="col-auto">
                <Link to='/admin101/client' className='text-capitalize btn btn-outline-secondary border rounded-pill' >retour</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ClientAdd

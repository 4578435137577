import Dashboard from "../components/admin/Dashboard"
import Employe from "../components/admin/employe/Employe"
import EmployeAdd from "../components/admin/employe/EmployeAdd"
import EmployeEdit from "../components/admin/employe/EmployeEdit"
import Intervention from "../components/admin/atelier/Intervention"
import InterventionAdd from "../components/admin/atelier/InterventionAdd"
import InterventionEdit from "../components/admin/atelier/InterventionEdit"
import Maintenance from "../components/admin/maintenance/Maintenance"
import Mecano from "../components/admin/mecano/Mecano"
import MecanoAdd from "../components/admin/mecano/MecanoAdd"
import Reparation from "../components/admin/atelier/Reparation"
import Reservation from "../components/admin/client/Reservation"
import ReservationAdd from "../components/admin/client/ReservationAdd"
import Velo from "../components/admin/client/Velo"
import VeloAdd from "../components/admin/client/VeloAdd"
import VeloEdit from "../components/admin/client/VeloEdit"
import Client from "../components/admin/client/Client"
import ClientAdd from "../components/admin/client/ClientAdd"
import ClientEdit from "../components/admin/client/ClientEdit"
import ClientAdresse from "../components/admin/client/ClientAdresse"
import ReservationDetails from "../components/admin/client/ReservationDetails"
import Fiche from "../components/admin/catalogue/Fiche"
import FicheAdd from "../components/admin/catalogue/FicheAdd"
import Prevision from "../components/admin/prevision/Prevision"
import Details from "../components/admin/maintenance/Details"
import Rapport from "../components/admin/maintenance/Rapport"
import Export from "../components/admin/maintenance/Export"

const routes = [
    { path: '/admin101', exact: true, name: 'Admin' },
    { path: '/admin101/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
    { path: '/admin101/employe', exact: true, name: 'Employe', component: Employe },
    { path: '/admin101/employeadd', exact: true, name: 'EmployeAdd', component: EmployeAdd },
    { path: '/admin101/employeedit/:id', exact: true, name: 'EmployeEdit', component: EmployeEdit },
    { path: '/admin101/intervention', exact: true, name: 'Intervention', component: Intervention },
    { path: '/admin101/interventionadd', exact: true, name: 'InterventionAdd', component: InterventionAdd },
    { path: '/admin101/interventionedit/:id', exact: true, name: 'InterventionEdit', component: InterventionEdit },
    { path: '/admin101/maintenance', exact: true, name: 'Maintenance', component: Maintenance },
    { path: '/admin101/mecano', exact: true, name: 'Mecano', component: Mecano },
    { path: '/admin101/mecanoadd', exact: true, name: 'MecanoAdd', component: MecanoAdd },
    { path: '/admin101/reparation', exact: true, name: 'Reparation', component: Reparation },
    { path: '/admin101/reservation', exact: true, name: 'Reservation', component: Reservation },
    { path: '/admin101/reservationadd', exact: true, name: 'ReservationAdd', component: ReservationAdd },
    { path: '/admin101/reservationdetails/:id.:proprio', exact: true, name: 'ReservationDetails', component: ReservationDetails },
    { path: '/admin101/velo', exact: true, name: 'Velo', component: Velo },
    { path: '/admin101/veloadd', exact: true, name: 'VeloAdd', component: VeloAdd },
    { path: '/admin101/veloedit/:id', exact:true, name: 'VeloEdit', component: VeloEdit },
    { path: '/admin101/client', exact: true, name: 'Client', component: Client },
    { path: '/admin101/clientadd', exact: true, name: 'ClientAdd', component: ClientAdd },
    { path: '/admin101/clientedit/:id', exact: true, name: 'ClientEdit', component: ClientEdit },
    { path: '/admin101/clientadresse', exact: true, name: 'ClientAdresse', component: ClientAdresse },
    { path: '/admin101/fiche', exact: true, name: 'Fiche', component: Fiche },
    { path: '/admin101/ficheadd', exact: true, name: 'FicheAdd', component: FicheAdd },
    { path: '/admin101/prevision', exact: true, name: 'Prevision', component: Prevision },
    { path: '/admin101/maintenancedetails/:id', exact: true, name:'Details', component: Details },
    { path: '/admin101/rapportactivity', exact: true, name: 'RapportActivity', component: Rapport},
    { path: '/admin101/export', exact: true, name: 'Export', component: Export},
]

export default routes
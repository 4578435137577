import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faPen } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function Intervention() {
  const [interventions, setInterventions] = useState([])

  useEffect(() => { 
    fetchdata()
  }, [])
  
  const fetchdata = () => {
    axios.get('/api/interventionlist').then(res => {
      setInterventions(res.data.intervention)
    })
  }

  const interventiondelete = (id) => {
    axios.delete(`/api/interventiondelete/${id}`).then(res => {
      fetchdata()
    })
  }

  // const interventionlist = interventions.map(intervention => 
  //   <tr key={intervention.id}>
  //     <td className='text-capitalize text-left'>{intervention.nom}</td>
  //     <td className='text-capitalize text-left'>{intervention.reparation.nom}</td>
  //     <td className='text-capitalize text-center'>{(intervention.tarif).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ar</td>
  //     <td className='d-flex justify-content-around' >
  //       <Link to={`/admin101/interventionedit/${intervention.id}`}><FontAwesomeIcon icon={faPen} className='btn btn-warning' /></Link>
  //       <span><FontAwesomeIcon icon={faTrashCan} className='btn btn-danger' onClick={() => interventiondelete(intervention.id)} /></span>
  //     </td>
  //   </tr>
  // )

  return (
    <div className='container mt-5'>
    <div className='d-flex flex-row-reverse'>
      <Link to='/admin101/interventionadd' className='btn btn-primary'>Ajouter intervention</Link>
    </div>
      <div className="mt-4">
        <div className="card">
          <div className="card-header text-capitalize">
            <p className='h4 text-center'>liste des interventions</p>
          </div>
          <div className="card-body">
            <table className="table table-bordered table-striped">
              <thead>
                <tr className="text-capitalize text-center">
                  <th><span className='btn fw-bold pe-none'>désignation</span></th>
                  <th><span className='btn fw-bold pe-none'>catégorie</span></th>
                  <th><span className='btn fw-bold pe-none'>tarif</span></th>
                  <th><span className='btn fw-bold pe-none'>actions</span></th>
                </tr>
              </thead>
              <tbody>
                {interventions ? interventions.map(intervention => 
                  <tr key={intervention.id}>
                    <td className='text-capitalize text-left'>{intervention.nom}</td>
                    <td className='text-capitalize text-left'>{intervention.reparation.nom}</td>
                    <td className='text-capitalize text-center'>{(intervention.tarif).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ar</td>
                    <td className='d-flex justify-content-around' >
                      <Link to={`/admin101/interventionedit/${intervention.id}`}><FontAwesomeIcon icon={faPen} className='btn btn-warning' /></Link>
                      <span><FontAwesomeIcon icon={faTrashCan} className='btn btn-danger' onClick={() => interventiondelete(intervention.id)} /></span>
                    </td>
                  </tr>) : null
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Intervention

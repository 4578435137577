import Home from "../components/client/Home"
import Tarif from "../components/client/Tarif"
import Contact from "../components/client/Contact"
import Login from "../components/client/auth/Login"
import SignUp from "../components/client/auth/SignUp"
import Forgotpwd from "../components/client/auth/Forgotpwd"

const clients = [
    { path: '/', exact: true, name: 'Home', component: Home },
    { path: '/tarif', exact: true, name: 'Tarif', component: Tarif },
    { path: '/contact', exact: true, name: 'Contact', component: Contact },
    { path: '/login', exact: true, name: 'Login', component: Login },
    { path: '/signup', exact: true, name: 'Signup', component: SignUp },
    { path: '/forgotpassword', exact: true, name: 'Forgotpwd', component: Forgotpwd },
]

export default clients
import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert'

function Profile() {
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const [auth, setAuth] = useState([])
  const [me, setMe] = useState({
    nom:'',
    prenom:'',
    email:'',
    phone:'',
    password:'',
    error:[],
  })

  useEffect(() => {
    fetchdata()
  }, [])

  const fetchdata = () => {
    axios.get(`/api/me/${localStorage.getItem('auth_id')}.${localStorage.getItem('auth_name')}`).then(res => {
      setAuth(res.data.user)
      setLoading(false)
    })
  }

  var nom = auth.map(el => {
    return nom = el.nom
  })

  var prenom = auth.map(el => {
    return prenom = el.prenom
  })

  var phone = auth.map(el => {
    return phone = el.phone
  })

  var email = auth.map(el => {
    return email = el.email
  })

  const handleInput = (e) => {
    e.persist()
    setMe({...me, [e.target.name]: e.target.value })
  }

  const meSubmit = (e) => {
    e.preventDefault()
    var pwd = document.getElementById('pwd').value
    var unom = document.getElementById('nom').value
    var uprenom = document.getElementById('prenom').value
    var uemail = document.getElementById('email').value
    var uphone = document.getElementById('phone').value
    var data = {}
    if(pwd.length === 0){
      data = {
          nom: unom,
          prenom: uprenom,
          phone: uphone,
          email: uemail,
      }
    } else {
        data = {
            nom: unom,
            prenom: uprenom,
            phone: uphone,
            email: uemail,
            password: me.password
        }
    }
    axios.put(`/api/accountupdate/${localStorage.getItem('auth_id')}.${localStorage.getItem('auth_name')}`, data).then(res => {
      if(res.data.status === 200) {
        history.push('/account/dashboard')
        localStorage.removeItem('auth_name')
        localStorage.setItem('auth_name', me.nom)
        swal('Success', res.data.message, 'success')
      } else {
        setMe({...me, error: res.data.errors})
      }
    })
  }

  if(loading){
    return (
      <div className="d-flex justify-content-center m-5">
        <div className="spinner-border text-success" role="status"></div>
      </div>
    )
  }

  return (
    <div className='container mt-5'>
    <div className='card'>
      <div className='card-header text-capitalize text-center h4'>modification de mon profil</div>
      <div className='card-body'>
        <form className='form-group' onSubmit={meSubmit} id="me_form">
          <div className="row mb-3 form-group">
            <div className="col-md form-group">
              <input type="text" name='nom' id='nom' className="form-control" onChange={handleInput} value={me.nom || nom} placeholder="Nom" />
            </div>
            <div className="col-md form-group">
              <input type="text" name='prenom' id='prenom' className="form-control" onChange={handleInput} value={me.prenom || prenom} placeholder="Prénom" />
            </div>
          </div>
          <div className='mb-3 form-group'>
            <input type="text" name='email' id='email' className="form-control" onChange={handleInput} value={me.email || email} placeholder="Email" />
          </div>
          <div className='mb-3 form-group'>
            <input type="text" name='phone' id='phone' onChange={handleInput} value={me.phone || phone} className="form-control" placeholder="Téléphone"  />
          </div>
          <div className='mb-3 form-group'>
            <input type="password" id='pwd' name='password' onChange={handleInput} value={me.password || ''} className="form-control" placeholder="Mot de passe" />
          </div>
          <div className="row justify-content-center">
            <div className="col-auto">
              <button type='submit' className="border rounded-pill text-capitalize btn btn-outline-primary">modifier</button>
            </div>
            <div className="col-auto">
              <Link to='/account/dashboard' className='text-capitalize btn btn-outline-secondary border rounded-pill' >retour</Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  )
}

export default Profile

import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel'

function Export() {
  const [form, setForm] = useState("form1");
  const [maintenances, setMaintenances] = useState([])
  const [clients, setClients] = useState([])
  const [velos, setVelos] = useState([])
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const date = new Date()
  const now = date.toLocaleDateString()
  const handleForm = (e) => {
    setForm(e.target.value);
  };
  const fetchdata = () => {
    axios.get('/api/rapportactivity').then(res => {
      setMaintenances(res.data.rapportactivity)
    })
    axios.get('/api/clientadresse').then(res => {
      setClients(res.data.adresse)
    })
    axios.get('/api/velolistprevision').then(res => {
      setVelos(res.data.velo)
    })
  }

  useEffect(() => {
    fetchdata()
  },[])

  const form1 = () => {
    const handleStartDate = (date) => {
      setStartDate(date);
    };
  
    const handleEndDate = (date) => {
      setEndDate(date);
    };
    const filtered = maintenances.filter((item) => {
      const itemDate = moment(item.date_pem, "YYYY-MM-DD")
      if(startDate && endDate) {
        return itemDate.isBetween(startDate, endDate, "day", "[]")
      } else {
        return true
      }
    })
    return (
      <>
        <div className="d-flex justify-content-around mb-3">
          <ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-success" table="table-to-xls" filename={"export_maintenances_"+now} sheet="Feuil 1" buttonText="Export données"/>
          <div className="d-flex align-items-center">
            <div>Début : &nbsp;</div>
            <div>
              <input
                type="date"
                className="form-control"
                onChange={(e) => handleStartDate(e.target.value)}
              />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div>Fin : &nbsp;</div>
            <div>
              <input
                type="date"
                className="form-control"
                onChange={(e) => handleEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>
        <table className="table table-bordered table-striped" id="table-to-xls">
          <thead>
            <tr className="text-capitalize text-center">
              <th>
                <span className="btn fw-bold pe-none">Facture n°</span>
              </th>
              <th>
                <span className="btn fw-bold pe-none">Client</span>
              </th>
              <th>
                <span className="btn fw-bold pe-none">&nbsp;&nbsp;&nbsp;Date&nbsp;&nbsp;&nbsp;</span>
              </th>
              <th>
                <span className="btn fw-bold pe-none">&nbsp;&nbsp;&nbsp;Durée&nbsp;&nbsp;&nbsp;</span>
              </th>
              <th>
                <span className="btn fw-bold pe-none">Responsable</span>
              </th>
              <th>
                <span className="btn fw-bold pe-none">État</span>
              </th>
              <th>
                <span className="btn fw-bold pe-none">Intervention</span>
              </th>
              <th>
                <span className="btn fw-bold pe-none">Main d'oeuvre (Ar)</span>
              </th>
              <th>
                <span className="btn fw-bold pe-none">Pièces (Ar)</span>
              </th>
              <th>
                <span className="btn fw-bold pe-none">Montant (Ar)</span>
              </th>
              <th>
                <span className="btn fw-bold pe-none">Statut paiement</span>
              </th>
            </tr>
          </thead>
          <tbody>
          {filtered.map((item) =>
                item.etat === "annulé" ? null : (
                  <tr key={item.id}>
                    <td className="text-capitalize text-center">
                      {item?.date_pem?.slice(0, 7)}
                      -
                      {item.id < 10
                        ? "00" + item.id
                        : item.id < 100
                        ? "0" + item.id
                        : item.id}
                    </td>
                    <td className="text-capitalize">
                      {item.velo.user.nom} {item.velo.user.prenom} -{" "}
                      <span className="text-uppercase">
                        {item.velo.type} {item.velo.marque} {item.velo.modele}
                      </span>
                    </td>
                    <td className="text-center">
                      {item.date_pem}
                    </td>
                    <td className="text-center">
                      {(item.hf_pem ? item.hf_pem.slice(0, 2) : null) -
                        (item.hd_pem ? item.hd_pem.slice(0, 2) : null)}{" "}
                      h{" "}
                      {(item.hf_pem ? item.hf_pem.slice(3, 5) : null) -
                        (item.hd_pem ? item.hd_pem.slice(3, 5) : null)}{" "}
                      mn
                    </td>
                    <td className="text-capitalize text-center">
                      {item.mecano}
                    </td>
                    <td className="text-capitalize text-center">{item.etat}</td>
                    <td className="text-capitalize text-center">{item.intervention}</td>
                    <td className="text-end">
                      {item.count_m
                        ? item.count_m
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "-"}
                    </td>
                    <td className="text-end">
                      {item.count_p
                        ? item.count_p
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "-"}
                    </td>
                    <td className="text-end">
                      {item.count
                        ? item.count
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ") : "-"}
                    </td>
                    <td className="text-capitalize text-center">{item.statut}</td>
                  </tr>
                )
              )}  
          </tbody>   
        </table>
      </>
    )
  }
  
  const form2 = () => {
    return (
      <>
        <ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-success mb-3" table="table-to-xls" filename={"export_clients_"+now} sheet="Feuil 1" buttonText="Export données"/>
        <table className="table table-bordered table-striped" id="table-to-xls">
          <thead>
            <tr className="text-capitalize text-center">
              <th>
                <span className="btn fw-bold pe-none">Nom</span>
              </th>
              <th>
                <span className="btn fw-bold pe-none">Prénom</span>
              </th>
              <th>
                <span className="btn fw-bold pe-none">Email</span>
              </th>
              <th>
                <span className="btn fw-bold pe-none">Téléphone</span>
              </th>
              <th>
                <span className="btn fw-bold pe-none">Adresse</span>
              </th>
            </tr>
          </thead>
          <tbody>
          {clients.map((item) =>
                (
                  <tr key={item.id}>
                    <td className="text-capitalize text-center">
                      {item.nom}
                    </td>
                    <td className="text-capitalize text-center">
                      {item.prenom}
                    </td>
                    <td className="text-center">
                      {item.email}
                    </td>
                    <td className="text-capitalize text-center">
                      {item.phone}
                    </td>
                    <td className="text-capitalize text-center">{item.adresse?.adresse_domicile}</td>
                  </tr>
                )
              )}  
          </tbody>   
        </table>
      </>
    )
  }
  
  const form3 = () => {
    return (
      <>
        <ReactHTMLTableToExcel id="test-table-xls-button" className="download-table-xls-button btn btn-success mb-3" table="table-to-xls" filename={"export_vélos_"+now} sheet="Feuil 1" buttonText="Export données"/>
        <table className="table table-bordered table-striped" id="table-to-xls">
          <thead>
            <tr className="text-capitalize text-center">
              <th>
                <span className="btn fw-bold pe-none">Propriétaire</span>
              </th>
              <th>
                <span className="btn fw-bold pe-none">Type</span>
              </th>
              <th>
                <span className="btn fw-bold pe-none">Marque</span>
              </th>
              <th>
                <span className="btn fw-bold pe-none">Modèle</span>
              </th>
              <th>
                <span className="btn fw-bold pe-none">Taille</span>
              </th>
              <th>
                <span className="btn fw-bold pe-none">Couleur</span>
              </th>
            </tr>
          </thead>
          <tbody>
          {velos.map((item) =>
                (
                  <tr key={item.id}>
                    <td className="text-capitalize text-center">
                      {item.user.nom} {item.user.prenom}
                    </td>
                    <td className="text-capitalize text-center">
                      {item.type}
                    </td>
                    <td className="text-center">
                      {item.marque}
                    </td>
                    <td className="text-capitalize text-center">
                      {item.modele}
                    </td>
                    <td className="text-capitalize text-center">{item.taille}</td>
                    <td className="text-capitalize text-center">{item.couleur}</td>
                  </tr>
                )
              )}  
          </tbody>   
        </table>
      </>
    )
  }

  return (
    <div className='container-fluid mt-5'>
      <div className="d-flex align-items-center justify-content-around mb-4">
      <label htmlFor="curatif" role="button">
          <input
            type="radio"
            onChange={handleForm}
            value="form1"
            checked={form === "form1"}
            id="curatif"
          />
          &nbsp;&nbsp;<span className="text-capitalize">maintenances</span>
        </label>
        <label htmlFor="preventif" role="button">
          <input
            type="radio"
            onChange={handleForm}
            value="form2"
            checked={form === "form2"}
            id="preventif"
          />
          &nbsp;&nbsp;
          <span className="text-capitalize">clients</span>
        </label>
        <label htmlFor="commande" role="button">
          <input
            type="radio"
            onChange={handleForm}
            value="form3"
            checked={form === "form3"}
            id="commande"
          />
          &nbsp;&nbsp;<span className="text-capitalize">vélos</span>
        </label>
      </div>
      <div className="card">
        <div className="card-body">
          {form === "form1" && form1()}
          {form === "form2" && form2()}
          {form === "form3" && form3()}
        </div>
      </div>
    </div>
  )
}

export default Export

import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import { Link } from 'react-router-dom'
import Pagination from '../Pagination'

function Velo() {
  const [velos, setVelos] = useState([])
  const [query, setQuery] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  useEffect(() => {
    fetchdata(currentPage)
  }, [currentPage])

  const fetchdata = (page) => {
    axios.get(`/api/velolist?page=${page}`).then(res => {
      setVelos(res.data.velo.data)
      setTotalPage(res.data.velo.last_page)
    })
  }

  const deletevelo = (id) => {
    axios.delete(`/api/velodelete/${id}`)
    fetchdata()
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }
    
  return (
    <div className='container mt-5'>
      <div className='d-flex flex-row-reverse'>
        <Link to='/admin101/veloadd' className='btn btn-primary'>Ajouter vélo</Link>
      </div>
      <div className='mt-4 d-flex align-items-center justify-content-center'>
        <Pagination currentPage={currentPage} totalPage={totalPage} onPageChange={handlePageChange} />
      </div>
      <div className="mt-4">
        <div className="card">
          <div className="card-header text-capitalize">
            <p className='h4 text-center'>liste des vélos clients</p>
          </div>
          <div className="card-body">
            <div className='col-md-6 mb-3 m-auto'>
              <input type="search" name="search" className='form-control rounded' placeholder='Search' onChange={e => setQuery(e.target.value)} />
            </div>
            <table className="table table-bordered table-striped">
              <thead>
                <tr className="text-capitalize text-center">
                  <th><span className='btn fw-bold pe-none'>propriétaire</span></th>
                  <th><span className='btn fw-bold pe-none'>type</span></th>
                  <th><span className='btn fw-bold pe-none'>marque</span></th>
                  <th><span className='btn fw-bold pe-none'>modele</span></th>
                  <th><span className='btn fw-bold pe-none'>taille</span></th>
                  <th><span className='btn fw-bold pe-none'>couleur</span></th>
                  <th><span className='btn fw-bold pe-none'>actions</span></th>
                </tr>
              </thead>
              <tbody>
                {velos ? velos.filter(el => el.user.nom.toLowerCase().includes(query) || el.user.prenom.toLowerCase().includes(query) || el.marque.toLowerCase().includes(query) || el.modele.toLowerCase().includes(query)).map(velo => 
                  <tr key={velo.id} className='text-capitalize text-center'>
                    <td>{velo.user.nom} {velo.user.prenom}</td>
                    <td>{velo.type}</td>
                    <td>{velo.marque}</td>
                    <td>{velo.modele}</td>
                    <td>{velo.taille}</td>
                    <td>{velo.couleur}</td>
                    <td>
                      <div className="d-flex justify-content-around">
                      <Link to={`/admin101/veloedit/${velo.id}`}><FontAwesomeIcon icon={faPen} className='btn btn-warning' /></Link>&nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon icon={faTrash} className='btn btn-danger' onClick={() => deletevelo(velo.id)} />
                      </div>
                    </td>
                  </tr>) : null
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Velo

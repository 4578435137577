import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'

function Forgotpwd() {
  return (
    <div className='container py-5'>
      <div className='row justify-content-center'>
        <div className='col-md-6'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='text-capitalize text-center'>mot de passe oublié ?</h4>
            </div>
            <div className='card-body'>
              <form>
                <div className='form-group mb-3'>
                  <label className='mb-3'>Veuillez entrer votre adresse e-mail. Nous vous enverrons des instructions pour vous aider à réinitialiser votre mot de passe.</label>
                  <input type="text" name="email" className="form-control" value="" />
                </div>
                <div className='form-group text-center'>
                  <button className="border rounded-pill text-capitalize btn btn-success"><FontAwesomeIcon icon={faPaperPlane} /> envoyer</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Forgotpwd

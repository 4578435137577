import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import accounts from '../../routes/accounts'

function Account() {
  return (
    <Switch>
      {accounts.map((account, i) => {
        return (
            account.component && (
                <Route
                    key={i}
                    path={account.path}
                    exact={account.exact}
                    name={account.name}
                    render={(props) => (
                        <account.component {...props} />
                    )} 
                />
            )
        )
      })}
      <Redirect from="/account" to="/account/dashboard" />
    </Switch>
  )
}

export default Account

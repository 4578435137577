import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Calendar } from "react-calendar";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

function Form2() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [velos, setVelos] = useState([]);
  const [clients, setClients] = useState([]);
  const [userId, setUserId] = useState(null);
  const [veloId, setVeloId] = useState(null);
  const [date, setDate] = useState(new Date());
  const [reservation, setReservation] = useState({
    velo_id: "",
    date: "",
    intervention: "",
    pbm_constates: "",
    adresse_domicile: "",
    error: [],
  });
  const [mecanos, setMecanos] = useState([]);
  const [mecano, setMecano] = useState(null);
  const [maintenances, setMaintenances] = useState([]);
  const [pies, setPies] = useState([]);
  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = () => {
    axios.get("/api/clientlistprevision").then((res) => {
      setClients(res.data.client);
    });
    axios.get("/api/velolistprevision").then((res) => {
      setVelos(res.data.velo);
    });
    axios.get("/api/mecanolist").then((res) => {
      setMecanos(res.data.mecano);
    });
  };

  const clientlist = clients.map((client) => ({
    value: client.id,
    label: `${client.nom} ${client.prenom}`,
  }));

  const velolist =
    userId &&
    velos
      .filter((el) => el.user.id === userId.value)
      .map((velo) => ({
        value: velo.id,
        label: `${velo.marque} ${velo.modele}`,
      }));

  const adresse =
    userId &&
    clients
      .filter((el) => el.id === userId.value)
      .map((domicile) => domicile?.reservations[0]?.adresse_domicile);

  const mecanolist = mecanos.map((mecano) => ({
    value: mecano.prenom,
    label: mecano.prenom,
  }));

  const handleUserId = (userId) => {
    setUserId(userId);
  };

  const handleVeloId = (veloId) => {
    setVeloId(veloId);
  };

  const handleMecano = (mecano) => {
    setMecano(mecano);
  };

  const addMaintenances = () => {
    setMaintenances([
      ...maintenances,
      { designation: "", quantite: "", prix: "" },
    ]);
  };

  const handleMaintenances = (i, e) => {
    const val = [...maintenances];
    val[i][e.target.name] = e.target.value;
    setMaintenances(val);
  };

  const removeMaintenances = (i) => {
    const val = [...maintenances];
    val.splice(i, 1);
    setMaintenances(val);
  };

  const addPieces = () => {
    setPies([...pies, { designation: "", quantite: "", prix: "" }]);
  };

  const handlePieces = (i, e) => {
    const val = [...pies];
    val[i][e.target.name] = e.target.value;
    setPies(val);
  };

  const removePieces = (i) => {
    const val = [...pies];
    val.splice(i, 1);
    setPies(val);
  };

  var count_m = 0;
  maintenances.map((el) => {
    return (count_m += Number(el.prix * el.quantite));
  });

  var count_p = 0;
  pies.map((el) => {
    return (count_p += Number(el.prix * el.quantite));
  });

  var count = count_m + count_p;

  const previsionSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      velo_id: veloId?.value,
      date:
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate(),
      date_pem:
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate(),
      intervention: "entretien préventif",
      pbm_constates: "Réparation prévisionnelle",
      adresse_domicile: adresse?.[0] || "Antsahavola",
      mecano: mecano?.value,
      maintenance: maintenances,
      count_m: count_m,
      piece: pies,
      count_p: count_p,
      count: count,
    };
    axios.post(`/api/prevision`, data).then((res) => {
      if (res.data.status === 200) {
        setLoading(false);
        history.push("/admin101/maintenance");
      } else {
        setLoading(false);
        setReservation({ ...reservation, error: res.data.errors });
      }
    });
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center container py-5">
        <div className="spinner-border text-success my-5" role="status"></div>
      </div>
    );
  }

  return (
    <form
      className="form-group"
      onSubmit={previsionSubmit}
      id="reservation_form"
    >
      <div className="mb-3 form-group d-flex align-items-center justify-content-around">
        <div className="w-25">
          <Select
            options={clientlist}
            value={userId}
            isClearable
            placeholder="Nom du client"
            onChange={handleUserId}
          />
        </div>
        <div className="w-25">
          <Select
            options={velolist}
            value={veloId}
            isClearable
            placeholder="Vélo du client"
            onChange={handleVeloId}
          />
          <span className="text-danger">{reservation.error.velo_id}</span>
        </div>
        <div className="w-25">
          <Select
            options={mecanolist}
            value={mecano}
            isClearable
            placeholder="Mécanicien"
            onChange={handleMecano}
          />
          <span className="text-danger">{reservation.error.mecano}</span>
        </div>
      </div>
      <div className="mb-3 form-group">
        <Calendar name="date" onChange={setDate} value={date} />
        <span className="text-danger">{reservation.error.date}</span>
      </div>
      <div className="row">
        <div className="col-md-6 mb-2">
          <h6 className="card-title text-center text-capitalize">
            maintenances&nbsp;
            <FontAwesomeIcon
              className="btn btn-secondary fw-bold"
              icon={faPlus}
              onClick={addMaintenances}
            />
          </h6>
          {maintenances?.map((maintenance, i) => (
            <div key={i} className="d-flex justify-content-between mb-3 m-2">
              <input
                type="text"
                className="form-control"
                name="designation"
                onChange={(e) => handleMaintenances(i, e)}
                value={maintenance.designation}
                placeholder="Désignation"
              />
              <input
                type="number"
                min="0"
                className="form-control mx-2"
                name="quantite"
                onChange={(e) => handleMaintenances(i, e)}
                value={maintenance.quantite}
                placeholder="Quantité"
              />
              <input
                type="number"
                min="0"
                className="form-control me-2"
                name="prix"
                onChange={(e) => handleMaintenances(i, e)}
                value={maintenance.prix}
                placeholder="Prix Unitaire"
              />
              <FontAwesomeIcon
                className="btn btn-danger fw-bold"
                icon={faMinus}
                onClick={() => removeMaintenances(i)}
              />
            </div>
          ))}
          {count_m ? (
            <p className="h6 fw-bold text-end">
              Sous-total main d'œuvre :{" "}
              {count_m?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ar
            </p>
          ) : null}
        </div>
        <div className="col-md-6 mb-2">
          <h6 className="card-title text-center text-capitalize">
            pièces&nbsp;
            <FontAwesomeIcon
              className="btn btn-secondary fw-bold"
              icon={faPlus}
              onClick={addPieces}
            />
          </h6>
          {pies?.map((piece, i) => (
            <div key={i} className="d-flex justify-content-between mb-3">
              <input
                type="text"
                className="form-control"
                name="designation"
                onChange={(e) => handlePieces(i, e)}
                value={piece.designation}
                placeholder="Désignation"
              />
              <input
                type="number"
                min="0"
                className="form-control mx-2"
                name="quantite"
                onChange={(e) => handlePieces(i, e)}
                value={piece.quantite}
                placeholder="Quantité"
              />
              <input
                type="number"
                min="0"
                className="form-control me-2"
                name="prix"
                onChange={(e) => handlePieces(i, e)}
                value={piece.prix}
                placeholder="Prix Unitaire"
              />
              <FontAwesomeIcon
                className="btn btn-danger fw-bold"
                icon={faMinus}
                onClick={() => removePieces(i)}
              />
            </div>
          ))}
          {count_p ? (
            <p className="h6 fw-bold text-end">
              Sous-total pièce :{" "}
              {count_p?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ar
            </p>
          ) : null}
        </div>
        <p className="fw-bold text-center mt-2">
          <span className="h4">Total :</span>
          <span className="h4">
            {" "}
            {count?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ar
          </span>
        </p>
      </div>
      <div className="row justify-content-center">
        <div className="col-auto">
          <button
            type="submit"
            className="border rounded-pill text-capitalize btn btn-outline-primary"
          >
            ajouter
          </button>
        </div>
        <div className="col-auto">
          <Link
            to="/admin101/reservation"
            className="text-capitalize btn btn-outline-secondary border rounded-pill"
          >
            retour
          </Link>
        </div>
      </div>
    </form>
  );
}

export default Form2;

import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert'

function SignUp() {
  const history = useHistory()
  const [loading, setLoading] = useState()
  const [signInput, setSignInput] = useState({
    nom:'',
    prenom:'',
    phone:'',
    email:'',
    password:'',
    error: [],
  })

  const handleInput = (e) => {
    e.persist()
    setSignInput({...signInput, [e.target.name]: e.target.value})
  }

  const signSubmit = (e) => {
    e.preventDefault()
    const data = {
      nom: signInput.nom,
      prenom: signInput.prenom,
      phone: signInput.phone,
      email: signInput.email,
      password: signInput.password,
    }
    axios.get('/sanctum/csrf-cookie').then(() => {
      setLoading(true)
      axios.post(`/api/signup`, data).then(res => {
        if (res.data.status === 200) {
          localStorage.setItem('XSRF-TOKEN=eyJpdiI6IlRJb21lQUZHV21aN1RIUDRvaHF0cVE9PSIsInZhbHVlIjoiSzNyQ0M5WUZvQ3B3RFhNZk9Pd3p6UVZ5S2k0bk9tcUNuWnVGVis0ZkhGSHJ1TFp4TXBsTXF6U0FaME9mV0hrOFlUWjVZdGtJQnAxZ0RhaGhBR2NGa2xZRTJnWDVIYXVOVlU5TjlOeFpWTFEzVTdacnozeE9lNjNkRkU1cTEwcGQiLCJtYWMiOiI1ZjRjOTEyZjczOGRmNDc1NGIxZDA0YTM1YWVlMzU1YzhjYTMxNWIxZWJjMGFmNTY5NzcxMzVlNGU0YTg5YTAzIiwidGFnIjoiIn0%3D', "cAth1HQWQJlbK3v13viUS42QfR661l80P1YHG4j6pn7gx0102bG3p18N68Q1v9kZVwtBAKD7WNXOLQ0sVY34bSkncw1ko7ZOiyap5zgiOJtuLs6TPCRNEr3ZVb19FNFhhWpR0QJ5y387x9aA5o8caESczx2329rg4beLwNui26N9w9hfpH6371A340N8o6L0fUEJ1YH4EClD5Cms6CgOgM60NY16UvF5tR366y33ZoeriXqp5g11HF56B5egz8c")
          localStorage.setItem('auth_name', res.data.name)
          localStorage.setItem('auth_id', res.data.id)
          swal('Success', res.data.message, 'success')
          history.push('/account/addvelo')
          setLoading(false)
        } else {
          setSignInput({...signInput, error: res.data.errors})
          setLoading(false)
        }
      })
    })
  }

  if (loading) {
    return (
      <div className="d-flex justify-content-center container py-5">
        <div className="spinner-border text-success my-5" role="status"></div>
      </div> 
    )
  }

  return (
    <div className='container py-5'>
      <div className='row justify-content-center'>
        <div className='col-md-6'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='text-capitalize text-center'>inscription</h4>
            </div>
            <div className='card-body'>
              <form onSubmit={signSubmit}>
                <div className='row'>
                  <div className='col'>
                  <div className='form-group mb-3'>
                  <label className='text-capitalize'>nom</label>
                  <input type="text" name="nom" className="form-control" onChange={handleInput} value={signInput.nom} /> 
                  <span className='text-danger'>{signInput.error.nom}</span>
                </div>
                  </div>
                  <div className='col'>
                  <div className='form-group mb-3'>
                  <label className='text-capitalize'>prenom</label>
                  <input type="text" name="prenom" className="form-control" onChange={handleInput} value={signInput.prenom} />
                  <span className='text-danger'>{signInput.error.prenom}</span>
                </div>
                  </div>
                </div>
                <div className='form-group mb-3'>
                  <label className='text-capitalize'>téléphone</label>
                  <input type="text" name="phone" className="form-control" onChange={handleInput} value={signInput.phone} />
                  <span className='text-danger'>{signInput.error.phone}</span>
                </div>
                <div className='form-group mb-3'>
                  <label className='text-capitalize'>email</label>
                  <input type="text" name="email" className="form-control" onChange={handleInput} value={signInput.email} />
                  <span className='text-danger'>{signInput.error.email}</span>
                </div>
                <div className='form-group mb-3'>
                  <label className='text-capitalize'>mot de passe</label>
                  <input type="password" name="password" className="form-control" onChange={handleInput} value={signInput.password} />
                  <span className='text-danger'>{signInput.error.password}</span>
                </div>
                <div className='form-group text-center'>
                  <button type='submit' className="border rounded-pill text-capitalize btn btn-outline-success">s'inscrire</button> <br/>
                  <p className="mt-3 text-capitalize">déja membre ? <Link to="/login" className='text-decoration-none text-primary'>connexion</Link></p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignUp

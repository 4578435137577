import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

function Facture(props) {
    const imgUrl = 'https://api.reparation.mbike.mg/img/fiches/favicon.png'
    // const imgUrl = 'http://localhost:8000/img/fiches/favicon.png'
    var date = new Date()
    var now = date.toLocaleDateString()
      const handlePrint = (id) => {
        document.title = "Facture_" + props.date_pem.slice(0,7) + "-" + (props.unik < 10 ? '00'+props.unik : props.unik < 100 ? '0'+props.unik : props.unik) + "_" + props.nom
        var content = document.getElementById(id).outerHTML
        var origin = document.body.outerHTML
        document.body.outerHTML = content
        window.print()
        document.body.outerHTML = origin
        window.location.reload()
      }

  return (
    <div>     
      <button className='btn btn-success' onClick={() => {handlePrint("facture")}}><FontAwesomeIcon icon={faPrint} /> Facture</button>
      <div className="print-source" id='facture'>
        <table className='table'>
          <tbody>
            <tr>
              <td colSpan="2"><img src={imgUrl} alt="" width="12.5%" /><br /><span className="fw-bolder">Atelier MBIKE Madagascar</span><br />12, Rue Rabearivelo, Antsahavola Antananarivo<br />reparation@mbike.mg<br />034 83 738 62</td>
              <td colSpan="2" className='text-end'><h1 className='text-uppercase'>facture</h1><span className='fst-italic'>N° : {props.date_pem.slice(0,7)}-{props.unik < 10 ? '00'+props.unik : props.unik < 100 ? '0'+props.unik : props.unik}<br/>Date d'édition : {now}</span><br /><span className='text-capitalize fw-bolder'>{props.nom}</span><br />{props.email}<br />{props.phone}<br /><span className="fst-italic">Vélo : </span><span className='text-uppercase fst-italic'>{props.velo}</span><br /><span className="fst-italic">Intervention : </span><span className='text-uppercase fst-italic'>{props.inter}</span></td>
            </tr>
            <tr className='border'>
              <th className="text-center border">Désignation</th>
              <th className="text-center border">Qte</th>
              <th className="text-center border">P.U.</th>
              <th className="text-center border">Total</th>
            </tr>
            {props.main.length > 0 ? <tr className='border'>
              <td colSpan='4' className='fw-bolder text-center'>Main d'œuvre</td>
            </tr> : null}
            {props.main ? props.main.map((el, i) =>( 
              <tr key={i} className='border'>
                <td>{el.designation}</td>
                <td className="text-center border">{el.quantite}</td>
                <td className='text-end'>{el.prix ? ((el.prix).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")) : ""} Ar</td>
                <td className='text-end'>{el.prix ? ((Number(el.prix) * Number(el.quantite)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")) : ""} Ar</td>
              </tr>
            )) : null}
            {props.main_t > 0 ? <tr className='border'><td colSpan='3' className='fw-bolder border text-end text-capitalize'>sous-total</td><td className='text-end border fw-bolder'>{(props.main_t).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ar</td></tr> : null}
            {props.pies.length > 0 ? <tr className='border'>
              <td colSpan="4" className="text-center fw-bolder">Pièces</td>
            </tr> : null}
            {props.pies ? props.pies.map((el, i) =>( 
              <tr key={i} className='border'>
                <td>{el.designation}</td>
                <td className="text-center border">{el.quantite}</td>
                <td className='text-end'>{el.prix ? ((el.prix).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")) : ""} Ar</td>
                <td className='text-end'>{el.prix ? ((Number(el.prix) * Number(el.quantite)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")) : ""} Ar</td>
              </tr>
            )) : null}
            {props.pies_t > 0 ? <tr className='border'><td colSpan='3' className='fw-bolder border text-end text-capitalize'>sous-total</td><td className='text-end border fw-bolder'>{(props.pies_t).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ar</td></tr> : null}
            {props.total ? <tr><td colSpan='3' className='fw-bolder text-end border text-capitalize'>total</td><td className='fw-bolder text-end border'>{(props.total).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ar</td></tr> : null}
          </tbody>
        </table>
        <table width="100%">
          <tbody>
            <tr>
              <td width="50%" height='80' className='fst-italic'>Signature du Responsable d'atelier</td>
              <td width="50%" height='80' className='text-end fst-italic'>Signature du Client</td>
            </tr>
          </tbody>
        </table>
        <br />
        <br />
        <hr />
        <p className="text-center lh-1">Atelier MBIKE Madagascar - RCS : 2012 B 00161 - NIF : 5000748744 - STAT : 77302 11 2012 0 10175<br />12, Rue Rabearivelo, Antsahavola Antananarivo<br />reparation@mbike.mg - 034 83 738 62</p>
      </div>
    </div>
  )
}

export default Facture
import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { Calendar } from 'react-calendar'
import swal from 'sweetalert'

function AddRendezVous() {
  const history = useHistory()
  const [velos, setVelos] = useState([])
  const [date, setDate] = useState(new Date())
  const [reservation, setReservation] = useState({
    velo_id:'',
    date:'',
    intervention:'',
    pbm_constates:'',
    adresse_domicile:'',
    error:[],
  })

  useEffect(() => {
    fetchdata()
  }, [])

  const fetchdata = () => {
    axios.get(`/api/bike/${localStorage.getItem('auth_id')}.${localStorage.getItem('auth_name')}`).then(res => {
      setVelos(res.data.bike)
    })
  }

  const velolist = velos.map(velo => 
    <option className='text-capitalize' key={velo.id} value={velo.id}>{velo.type} {velo.marque} {velo.modele} ({velo.taille} / {velo.couleur})</option>
    )

  const handleInput = (e) => {
    e.persist()
    setReservation({...reservation, [e.target.name]: e.target.value})
  }

  const reservationSubmit = (e) => {
    e.preventDefault()
    const data = {
      velo_id: reservation.velo_id,
      date: date.getFullYear()+"/"+(date.getMonth()+1)+"/"+date.getDate(),
      intervention: reservation.intervention,
      pbm_constates: reservation.pbm_constates,
      adresse_domicile: reservation.adresse_domicile,
    }
    axios.post(`/api/rdvadd`, data).then((res) => {
      if(res.data.status === 200){
        history.push('/account/dashboard')
        swal('Success', res.data.message, 'success')
      } else {
        setReservation({...reservation, error: res.data.errors})
      }
    })
  }
  
  return (
    <div className='container mt-5'>
    <div className='card'>
      <div className='card-header text-capitalize h4'>ajout réservation</div>
      <div className='card-body'>
        <form className='form-group' onSubmit={reservationSubmit} id="reservation_form">
          <div className='mb-3 form-group'>
            <select name="velo_id" className='form-select text-capitalize' value={reservation.velo_id} onChange={handleInput} >
              <option value="" className='text-capitalize'>sélectionnez votre vélo</option>
              {velolist}
            </select>
            <span className="text-danger">{reservation.error.velo_id}</span>
          </div>
          <div className='mb-3 form-group'>
            <Calendar name='date' onChange={setDate} value={date} />
            <span className="text-danger">{reservation.error.date}</span>
          </div>
          <div className="mb-3 form-group">
            <input type="text" name='intervention' className="form-control" placeholder="Intervention" onChange={handleInput} value={reservation.intervention} />
            <span className="text-danger">{reservation.error.intervention}</span>
          </div>
          <div className="mb-3 form-group">
            <input type="text" name='adresse_domicile' className="form-control" placeholder="Adresse du client" onChange={handleInput} value={reservation.adresse_domicile} />
            <span className="text-danger">{reservation.error.adresse_domicile}</span>
          </div>
          <div className="mb-3 form-group">
            <textarea name="pbm_constates" className='form-control' placeholder='Expliquez brièvement les problèmes constatés' cols="30" rows="10" onChange={handleInput} value={reservation.pbm_constates}></textarea>
            <span className="text-danger">{reservation.error.pbm_constates}</span>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto">
              <button type='submit' className="border rounded-pill text-capitalize btn btn-outline-primary">ajouter</button>
            </div>
            <div className="col-auto">
              <Link to='/account/dashboard' className='text-capitalize btn btn-outline-secondary border rounded-pill' >retour</Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  )
}

export default AddRendezVous

import axios from 'axios'
import React, { useState } from 'react'

function ReparationAdd({setTest}) {
  const [reparation, setReparation] = useState({
    nom:'',
  })

  const handleInput = (e) => {
    e.persist()
    setReparation({...reparation, [e.target.name]: e.target.value})
  }

  const reparationSubmit = (e) => {
    e.preventDefault()
    const data = {
      nom: reparation.nom,
    }
    axios.post(`/api/reparationadd`, data).then((res) => {
      setTest(true)
      setReparation({nom:'',})
    })
  }
  return (
    <div className='container text-center mt-5'>
      <div className="card">
        <div className="card-header text-capitalize h4">ajout réparation</div>
        <div className="card-body">
          <form className="form-group" onSubmit={reparationSubmit} id='reparation_form'>
            <div className="row justify-content-center">
              <div className="col-auto">
                <input type="text" name="nom" className='form-control' placeholder='Réparation' onChange={handleInput} value={reparation.nom} />
              </div>
              <div className="col-auto">
                <button type="submit" className='border rounded-pill text-capitalize btn btn-outline-primary'>ajouter</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ReparationAdd

import React, { useEffect, useState } from 'react'
import axios from 'axios'

function ClientAdresse() {
  const [clients, setClients] = useState([])
  const [query, setQuery] = useState("")

  useEffect(() => {
    fetchdata()
  }, [])

  const fetchdata = (page) => {
    axios.get(`/api/clientadresse?page=${page}`).then(res => {
      setClients(res.data.adresse)
    })
  }

  return (
    <div className='container mt-5'>
    <div className="mt-4">
      <div className="card">
        <div className="card-header text-capitalize">
          <p className='h4 text-center'>adresse des clients</p>
        </div>
        <div className="card-body">
          <div className='col-md-6 mb-3 m-auto'>
            <input type="search" name="search" className='form-control rounded' placeholder='Search' onChange={e => setQuery(e.target.value)} />
          </div>
          <table className="table table-bordered table-striped">
            <thead>
              <tr className="text-capitalize text-center">
                <th><span className='btn fw-bold pe-none'>nom</span></th>
                <th><span className='btn fw-bold pe-none'>prénom</span></th>
                <th><span className='btn fw-bold pe-none'>adresse</span></th>
              </tr>
            </thead>
            <tbody>
              {clients ? clients.filter(el => el.nom.toLowerCase().includes(query) || el.prenom.toLowerCase().includes(query)).map((client) => 
                  client.adresse ? (
                    <tr key={client.id}>
                      <td className='text-uppercase text-center'>{client.nom}</td>
                      <td className='text-capitalize text-center'>{client.prenom}</td>
                      <td className='text-capitalize text-left'>{client.adresse.adresse_domicile}</td>
                    </tr>) : (null)
              ): (null)}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  )
}

export default ClientAdresse

import axios from 'axios'
import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'

function EmployeAdd() {
  const history = useHistory()
  const [employe, setEmploye] = useState({
    nom:'',
    prenom:'',
    email:'',
    password:'',
    role:'',
    error:[],
  })

  const handleInput = (e) => {
    e.persist()
    setEmploye({...employe, [e.target.name]: e.target.value })
  }

  const employeSubmit = (e) => {
    e.preventDefault()
    const data = {
      nom: employe.nom,
      prenom: employe.prenom,
      email: employe.email,
      password: employe.password,
      role: employe.role,
    }
    axios.post(`/api/employeadd`, data).then((res) => {
      if(res.data.status === 200){
        history.push('/admin101/employe')
      } else {
        setEmploye({...employe, error: res.data.errors})
      }
    })
  }

  return (
    <div className='container mt-5'>
      <div className='card'>
        <div className='card-header text-capitalize h4'>ajout employé</div>
        <div className='card-body'>
          <form className='form-group' onSubmit={employeSubmit} id="employe_form">
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='nom' className="form-control" placeholder="Nom" onChange={handleInput} value={employe.nom} />
                <span className="text-danger">{employe.error.nom}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='prenom' className="form-control" placeholder="Prénom" onChange={handleInput} value={employe.prenom} />
                <span className="text-danger">{employe.error.prenom}</span>
              </div>
            </div>
            <div className='mb-3 form-group'>
              <input type="text" name='email' className="form-control" placeholder="Email" onChange={handleInput} value={employe.email} />
              <span className="text-danger">{employe.error.email}</span>
            </div>
            <div className='mb-3 form-group'>
              <input type="password" name='password' className="form-control" placeholder="Mot de passe" onChange={handleInput} value={employe.password} />
              <span className="text-danger">{employe.error.password}</span>
            </div>
            <div className='mb-3 form-group'>
              <select name="role" className='form-select text-capitalize' value={employe.role} onChange={handleInput} >
                <option value="" className='text-capitalize'>role</option>
                <option value="super" className='text-capitalize'>super admin</option>
                <option value="admin" className='text-capitalize'>admin</option>
              </select>
              <span className="text-danger">{employe.error.role}</span>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <button type='submit' className="border rounded-pill text-capitalize btn btn-outline-primary">ajouter</button>
              </div>
              <div className="col-auto">
                <Link to='/admin101/employe' className='text-capitalize btn btn-outline-secondary border rounded-pill' >retour</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EmployeAdd

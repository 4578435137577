import React, {useState} from 'react'
import { useHistory } from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert'
import logo from "../../../assets/client/icons/logo.jpg"

function LoginAdmin() {
  const [loading, setLoading] = useState()
  const history = useHistory()
  const [loginInput, setLoginInput] = useState({
    email:'',
    password:'',
    error: [],
  })

  const handleInput = (e) => {
    e.persist()
    setLoginInput({...loginInput, [e.target.name]: e.target.value })
  }

  const loginSubmit = (e) => {
    e.preventDefault()
    const data = {
      email: loginInput.email,
      password: loginInput.password,
    }
    axios.get('/sanctum/csrf-cookie').then(() => {
      setLoading(true)
      axios.post(`/api/adminlogin`, data).then(res => {
        if (res.data.status === 200) {
          localStorage.setItem('XSRF-TOKEN=eyJpdiI6IjRQbHIwWGkyOVhSRVZxWWw5V3doY1E9PSIsInZhbHVlIjoicWovMHF5OTdCRDVRblo3ZGlRWVUxTEgvaXNFUlpUSXJnS0dhWmVzelhwODdwbWN4THZFVVpOaTlrdkdXWTVaK3BXSWZTb21UV3lxWHFhTEJiTmdKeUZqVVNHTTZuY21CMEd5b203UklQZURhYVJKeGdxQzJ2MTBNbkxvdDZlNmYiLCJtYWMiOiJiMjIxNjlmYzc1Yjg4MDdmMDlmNTA5MDYxOTQ5NTBiNDNkN2Q5YTMzYjdkODQ4NzA5MzZmMWFlMjQ3OWZlMzZlIiwidGFnIjoiIn0%3D', res.data.token)
          localStorage.setItem('admin_name', res.data.name)
          localStorage.setItem('admin_role', res.data.role)
          history.push('/admin101')
          setLoading(false)
        } else if(res.data.status === 401) {
          swal('Warning', res.data.message, 'warning')
          setLoading(false)
        } else{
          setLoginInput({...loginInput, error: res.data.errors})
          setLoading(false)
        }
      })
    })
  }
  
    if (loading) {
    return (
      <div className="d-flex justify-content-center container py-5">
        <div className="spinner-border text-success my-5" role="status"></div>
      </div> 
    )
  }

  return (
    <div>
      <div className='container py-5'>
        <div className='row justify-content-center'>
          <div className='col-md-6'>
            <div className='card'>
              <div className='card-header'>
                <p className='text-center py-2'><img src={logo} alt="" width="17.5%" className='rounded'/></p>
                <h4 className='text-capitalize text-center'>atelier mbike</h4>
              </div>
              <div className='card-body'>
                <form onSubmit={loginSubmit}>
                  <div className='form-group mb-3'>
                    <label className='text-capitalize'>email</label>
                    <input type="text" name="email" className="form-control" onChange={handleInput} value={loginInput.email}  />
                    <span className='text-danger'>{loginInput.error.email}</span>
                  </div>
                  <div className='form-group mb-3'>
                    <label className='text-capitalize'>mot de passe</label>
                    <input type="password" name="password" className="form-control" onChange={handleInput} value={loginInput.password}  />
                    <span className='text-danger'>{loginInput.error.password}</span>
                  </div>
                  <div className='form-group text-center'>
                    <button type='submit' className="border rounded-pill text-capitalize btn btn-outline-success">se connecter</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginAdmin

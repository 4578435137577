import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'

function EmployeEdit(props) {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [employe, setEmploye] = useState({
    nom:'',
    prenom:'',
    email:'',
    password:'',
    role:'',
    error:[],
  })

  useEffect(() => {
    const id = props.match.params.id
    axios.get(`/api/employeedit/${id}`).then(res => {
      if (res.data.status === 200) {
        setEmploye(res.data.employe)
        setLoading(false)
      } else {
        history.push('/admin101/employe')
      }
    })
  }, [props.match.params.id, history])

  const handleInput = (e) => {
    e.persist()
    setEmploye({...employe, [e.target.name]: e.target.value })
  }

  const employeSubmit = (e) => {
    e.preventDefault()
    var pwd = document.getElementById('pwd').value
    const id = props.match.params.id
    var data = {}
    if (pwd.length === 0) {
      data = {
        nom: employe.nom,
        prenom: employe.prenom,
        email: employe.email,
        role: employe.role,
      }
    } else {
      data = {
        nom: employe.nom,
        prenom: employe.prenom,
        email: employe.email,
        role: employe.role,
        password: employe.password
      }
    }
    axios.put(`/api/employeupdate/${id}`, data).then(res => {
      if(res.data.status === 200){
        history.push('/admin101/employe')
      } else {
        setEmploye({...employe, error: res.data.errors})
      }
    })
  }

  if (loading) {
    return <p></p> 
  }

  return (
    <div className='container mt-5'>
      <div className='card'>
        <div className='card-header text-capitalize h4'>modification employé</div>
        <div className='card-body'>
          <form className='form-group' onSubmit={employeSubmit} id="employe_form">
            <div className="mb-3">
                <input type="text" name='nom' className="form-control" placeholder="Nom" onChange={handleInput} value={employe.nom} />
            </div>
            <div className='mb-3'>
            <input type="text" name='prenom' className="form-control" placeholder="Prénom" onChange={handleInput} value={employe.prenom} />
            </div>
            <div className='mb-3'>
            <input type="text" name='email' className="form-control" placeholder="email" onChange={handleInput} value={employe.email} />
            </div>
            <div className='mb-3'>
              <input type="password" id='pwd' name='password' className="form-control" placeholder="Mot de passe" onChange={handleInput} value={employe.password || ''} />
            </div>
            <div className='mb-3 form-group'>
              <select name="role" className='form-select text-capitalize' value={employe.role} onChange={handleInput} >
                <option value="" className='text-capitalize'>role</option>
                <option value="super" className='text-capitalize'>super admin</option>
                <option value="admin" className='text-capitalize'>admin</option>
              </select>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <button type='submit' className="border rounded-pill text-capitalize btn btn-outline-primary">modifier</button>
              </div>
              <div className="col-auto">
                <Link to='/admin101/employe' className='text-capitalize btn btn-outline-secondary border rounded-pill' >retour</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EmployeEdit

import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import axios from "axios";
import LoginAdmin from "./components/admin/auth/LoginAdmin";
import AdminRoute from "./AdminRoute";
import Master from "./layouts/client/Master";

axios.defaults.baseURL = "https://api.reparation.mbike.mg";
// axios.defaults.baseURL = "http://localhost:8000";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.withCredentials = true;
axios.interceptors.request.use(function (config) {
  var token = "";
  if (
    !localStorage.getItem(
      "XSRF-TOKEN=eyJpdiI6IlRJb21lQUZHV21aN1RIUDRvaHF0cVE9PSIsInZhbHVlIjoiSzNyQ0M5WUZvQ3B3RFhNZk9Pd3p6UVZ5S2k0bk9tcUNuWnVGVis0ZkhGSHJ1TFp4TXBsTXF6U0FaME9mV0hrOFlUWjVZdGtJQnAxZ0RhaGhBR2NGa2xZRTJnWDVIYXVOVlU5TjlOeFpWTFEzVTdacnozeE9lNjNkRkU1cTEwcGQiLCJtYWMiOiI1ZjRjOTEyZjczOGRmNDc1NGIxZDA0YTM1YWVlMzU1YzhjYTMxNWIxZWJjMGFmNTY5NzcxMzVlNGU0YTg5YTAzIiwidGFnIjoiIn0%3D"
    )
  ) {
    token = localStorage.getItem(
      "XSRF-TOKEN=eyJpdiI6IjRQbHIwWGkyOVhSRVZxWWw5V3doY1E9PSIsInZhbHVlIjoicWovMHF5OTdCRDVRblo3ZGlRWVUxTEgvaXNFUlpUSXJnS0dhWmVzelhwODdwbWN4THZFVVpOaTlrdkdXWTVaK3BXSWZTb21UV3lxWHFhTEJiTmdKeUZqVVNHTTZuY21CMEd5b203UklQZURhYVJKeGdxQzJ2MTBNbkxvdDZlNmYiLCJtYWMiOiJiMjIxNjlmYzc1Yjg4MDdmMDlmNTA5MDYxOTQ5NTBiNDNkN2Q5YTMzYjdkODQ4NzA5MzZmMWFlMjQ3OWZlMzZlIiwidGFnIjoiIn0%3D"
    );
  } else {
    token = localStorage.getItem(
      "XSRF-TOKEN=eyJpdiI6IlRJb21lQUZHV21aN1RIUDRvaHF0cVE9PSIsInZhbHVlIjoiSzNyQ0M5WUZvQ3B3RFhNZk9Pd3p6UVZ5S2k0bk9tcUNuWnVGVis0ZkhGSHJ1TFp4TXBsTXF6U0FaME9mV0hrOFlUWjVZdGtJQnAxZ0RhaGhBR2NGa2xZRTJnWDVIYXVOVlU5TjlOeFpWTFEzVTdacnozeE9lNjNkRkU1cTEwcGQiLCJtYWMiOiI1ZjRjOTEyZjczOGRmNDc1NGIxZDA0YTM1YWVlMzU1YzhjYTMxNWIxZWJjMGFmNTY5NzcxMzVlNGU0YTg5YTAzIiwidGFnIjoiIn0%3D"
    );
  }
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <AdminRoute path="/admin101" name="admin" />
          <Route path="/adminlogin">
            {localStorage.getItem(
              "XSRF-TOKEN=eyJpdiI6IjRQbHIwWGkyOVhSRVZxWWw5V3doY1E9PSIsInZhbHVlIjoicWovMHF5OTdCRDVRblo3ZGlRWVUxTEgvaXNFUlpUSXJnS0dhWmVzelhwODdwbWN4THZFVVpOaTlrdkdXWTVaK3BXSWZTb21UV3lxWHFhTEJiTmdKeUZqVVNHTTZuY21CMEd5b203UklQZURhYVJKeGdxQzJ2MTBNbkxvdDZlNmYiLCJtYWMiOiJiMjIxNjlmYzc1Yjg4MDdmMDlmNTA5MDYxOTQ5NTBiNDNkN2Q5YTMzYjdkODQ4NzA5MzZmMWFlMjQ3OWZlMzZlIiwidGFnIjoiIn0%3D"
            ) ? (
              <Redirect to="/admin101" />
            ) : (
              <LoginAdmin />
            )}
          </Route>
          <Master />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;

import { faCalendarCheck, faCalendarPlus, faCalendarWeek, faSackDollar, faUserGroup } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

function Dashboard() {
  const [loading, setLoading] = useState(true)
  const [client, setCLient] = useState()
  const [newre, setNewre] = useState()
  const [atraiter, setAtraiter] = useState()
  const [realise, setRealise] = useState()
  const [ca, setCa] = useState()
  const [mainca, setMainca] = useState()
  const [pieca, setPieca] = useState()

  useEffect(() => {
    fetchdata()
  }, [])

  const fetchdata = () => {
    axios.get('/api/ca').then(res => {
      setCa(res.data.ca)
      setMainca(res.data.mainca)
      setPieca(res.data.pieca)
      setNewre(res.data.new)
      setAtraiter(res.data.atraiter)
      setRealise(res.data.realise)
      setCLient(res.data.client)
      setLoading(false)
    })
  }

  if(loading){
    return (
      <div className="d-flex justify-content-center m-5">
        <div className="spinner-border text-success" role="status"></div>
      </div>
    )
  }

  return (
    <div className='container mt-4'>
      <h2>Dashboard</h2>
      <div className="row mt-5">
        <div className="col-sm-12 col-md-4 col-lg-4">
          <div className="card mb-3 text-dark bg-light">
            <div className="card-header">
                <h5 className="card-title text-capitalize">C. A. total<FontAwesomeIcon icon={faSackDollar} className='float-end' size='xl' /></h5>
            </div>
            <div className="card-body">
              <h1 className="card-title text-center">
                {ca ? (ca).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0 } Ar
              </h1>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4">
          <div className="card mb-3 text-dark bg-light">
            <div className="card-header">
                <h5 className="card-title text-capitalize">C. A. main d'œuvre<FontAwesomeIcon icon={faSackDollar} className='float-end' size='xl' /></h5>
            </div>
            <div className="card-body">
              <h1 className="card-title text-center">
                {mainca ? (mainca).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0 } Ar
              </h1>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-4">
          <div className="card mb-3 text-dark bg-light">
            <div className="card-header">
                <h5 className="card-title text-capitalize">C. A. pièce<FontAwesomeIcon icon={faSackDollar} className='float-end' size='xl' /></h5>
            </div>
            <div className="card-body">
              <h1 className="card-title text-center">
                {pieca ? (pieca).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0 } Ar
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className='row justify-content-evenly mt-5'>
        <div className="col-sm-12 col-md-4 col-lg-3">
          <div className="card mb-3 text-white bg-primary">
            <div className="card-header">
                <h5 className="card-title text-capitalize">clients <FontAwesomeIcon icon={faUserGroup} className='float-end' size='xl' /></h5>
            </div>
            <div className="card-body">
              <h1 className="card-title text-center">
                {client}
              </h1>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-3">
          <div className="card mb-3 text-white bg-info">
            <div className="card-header">
                <h5 className="card-title text-capitalize">nouvelles réservations <FontAwesomeIcon icon={faCalendarPlus} className='float-end' size='xl' /></h5>
            </div>
            <div className="card-body">
              <h1 className="card-title text-center">
                {newre}
              </h1>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-3">
          <div className="card mb-3 text-white bg-warning">
            <div className="card-header">
                <h5 className="card-title text-capitalize">réservations à traiter <FontAwesomeIcon icon={faCalendarWeek} className='float-end' size='xl' /></h5>
            </div>
            <div className="card-body">
              <h1 className="card-title text-center">
                {atraiter}
              </h1>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-4 col-lg-3">
          <div className="card mb-3 text-white bg-success">
            <div className="card-header">
                <h5 className="card-title text-capitalize">réservations réalisées <FontAwesomeIcon icon={faCalendarCheck} className='float-end' size='xl' /></h5>
            </div>
            <div className="card-body">
              <h1 className="card-title text-center">
                {realise}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard

import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Calendar } from "react-calendar";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

function Form3() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [velos, setVelos] = useState([]);
  const [clients, setClients] = useState([]);
  const [userId, setUserId] = useState(null);
  const [veloId, setVeloId] = useState(null);
  const [transport, setTransport] = useState(null);
  const [date, setDate] = useState(new Date());
  const [datePem, setDatePem] = useState(new Date());
  const [reservation, setReservation] = useState({
    velo_id: "",
    date: "",
    date_pem: "",
    lien: "",
    comments: "",
    transport: "",
    error: [],
  });

  const handleInput = (e) => {
    e.persist();
    setReservation({ ...reservation, [e.target.name]: e.target.value });
  };

  const [pies, setPies] = useState([]);
  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = () => {
    axios.get("/api/clientlistprevision").then((res) => {
      setClients(res.data.client);
    });
    axios.get("/api/velolistprevision").then((res) => {
      setVelos(res.data.velo);
    });
  };

  const clientlist = clients.map((client) => ({
    value: client.id,
    label: `${client.nom} ${client.prenom}`,
  }));

  const velolist =
    userId &&
    velos
      .filter((el) => el.user.id === userId.value)
      .map((velo) => ({
        value: velo.id,
        label: `${velo.marque} ${velo.modele}`,
      }));

  const adresse =
    userId &&
    clients
      .filter((el) => el.id === userId.value)
      .map((domicile) => domicile?.reservations[0]?.adresse_domicile);

  const transportlist = [
    { value: "aérien", label: "Aérien" },
    { value: "maritime", label: "Maritime" },
  ];

  const handleUserId = (userId) => {
    setUserId(userId);
  };

  const handleVeloId = (veloId) => {
    setVeloId(veloId);
  };

  const handleTransport = (transport) => {
    setTransport(transport);
  };

  const addPieces = () => {
    setPies([...pies, { designation: "", quantite: "", prix: "" }]);
  };

  const handlePieces = (i, e) => {
    const val = [...pies];
    val[i][e.target.name] = e.target.value;
    setPies(val);
  };

  const removePieces = (i) => {
    const val = [...pies];
    val.splice(i, 1);
    setPies(val);
  };

  var count_p = 0;
  pies.map((el) => {
    return (count_p += Number(el.prix * el.quantite));
  });

  const commandeSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      velo_id: veloId?.value,
      date:
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate(),
      date_pem:
        datePem.getFullYear() +
        "/" +
        (datePem.getMonth() + 1) +
        "/" +
        datePem.getDate(),
      intervention: "commande pièce",
      pbm_constates: "Importation pièce",
      adresse_domicile: adresse?.[0] || "Antsahavola",
      mecano: "stock",
      maintenance: "[]",
      count_m: 0,
      piece: pies,
      count_p: count_p,
      count: count_p,
      lien: reservation.lien,
      comments: reservation.comments,
      transport: transport?.value,
    };
    axios.post(`/api/commande`, data).then((res) => {
      if (res.data.status === 200) {
        setLoading(false);
        history.push("/admin101/maintenance");
      } else {
        setLoading(false);
        setReservation({ ...reservation, error: res.data.errors });
      }
    });
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center container py-5">
        <div className="spinner-border text-success my-5" role="status"></div>
      </div>
    );
  }

  return (
    <form
      className="form-group"
      onSubmit={commandeSubmit}
      id="reservation_form"
    >
      <div className="mb-3 form-group d-flex align-items-center justify-content-around">
        <div className="w-25">
          <Select
            options={clientlist}
            value={userId}
            isClearable
            placeholder="Nom du client"
            onChange={handleUserId}
          />
        </div>
        <div className="w-25">
          <Select
            options={velolist}
            value={veloId}
            isClearable
            placeholder="Vélo du client"
            onChange={handleVeloId}
          />
          <span className="text-danger">{reservation.error.velo_id}</span>
        </div>
      </div>
      <div className="mb-3 form-group row mt-4">
        <div className="col">
          <h6 className="text-center">Utile le :</h6>
          <Calendar
            name="date"
            onChange={setDate}
            value={date}
            className="w-75"
          />
          <span className="text-danger">{reservation.error.date}</span>
        </div>
        <div className="col">
          <h6 className="text-center">À commander le :</h6>
          <Calendar
            name="date_pem"
            onChange={setDatePem}
            value={datePem}
            className="w-75"
          />
          <span className="text-danger">{reservation.error.date_pem}</span>
        </div>
      </div>
      <div className="row mb-3 mt-5">
        <div className="form-group col">
          <input
            type="text"
            name="lien"
            className="form-control"
            placeholder="Lien de la pièce"
            onChange={handleInput}
            value={reservation.lien}
          />
          <span className="text-danger">{reservation.error.lien}</span>
        </div>
        <div className="form-group col-md-3">
          <Select
            options={transportlist}
            value={transport}
            isClearable
            placeholder="Mode de transport"
            onChange={handleTransport}
          />
          <span className="text-danger">{reservation.error.transport}</span>
        </div>
      </div>
      <div className="mb-3 form-group">
        <textarea
          name="comments"
          className="form-control"
          placeholder="Commentaire"
          cols="30"
          rows="10"
          onChange={handleInput}
          value={reservation.comments}
        ></textarea>
        <span className="text-danger">{reservation.error.comments}</span>
      </div>
      <div className="row">
        <div className="col-md-12 mb-2">
          <h6 className="card-title text-center text-capitalize">
            pièces&nbsp;
            <FontAwesomeIcon
              className="btn btn-secondary fw-bold"
              icon={faPlus}
              onClick={addPieces}
            />
          </h6>
          {pies?.map((piece, i) => (
            <div key={i} className="d-flex justify-content-between mb-3">
              <input
                type="text"
                className="form-control"
                name="designation"
                onChange={(e) => handlePieces(i, e)}
                value={piece.designation}
                placeholder="Désignation"
              />
              <input
                type="number"
                min="0"
                className="form-control mx-2"
                name="quantite"
                onChange={(e) => handlePieces(i, e)}
                value={piece.quantite}
                placeholder="Quantité"
              />
              <input
                type="number"
                min="0"
                className="form-control me-2"
                name="prix"
                onChange={(e) => handlePieces(i, e)}
                value={piece.prix}
                placeholder="Prix Unitaire"
              />
              <FontAwesomeIcon
                className="btn btn-danger fw-bold"
                icon={faMinus}
                onClick={() => removePieces(i)}
              />
            </div>
          ))}
        </div>
        <p className="fw-bold text-center mt-2">
          <span className="h4">Total :</span>
          <span className="h4">
            {" "}
            {count_p?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ar
          </span>
        </p>
      </div>
      <div className="row justify-content-center">
        <div className="col-auto">
          <button
            type="submit"
            className="border rounded-pill text-capitalize btn btn-outline-primary"
          >
            ajouter
          </button>
        </div>
        <div className="col-auto">
          <Link
            to="/admin101/reservation"
            className="text-capitalize btn btn-outline-secondary border rounded-pill"
          >
            retour
          </Link>
        </div>
      </div>
    </form>
  );
}

export default Form3;

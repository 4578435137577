import axios from 'axios'
import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'

function AddVelo() {
  const history = useHistory()
  const [velo, setVelo] = useState({
    user_id:'',
    type:'',
    marque:'',
    modele:'',
    taille:'',
    couleur:'',
    error:[],
  })

  const handleInput = (e) => {
    e.persist()
    setVelo({...velo, [e.target.name]: e.target.value })
  }

  const veloSubmit = (e) => {
    e.preventDefault()
    const data = {
      user_id: localStorage.getItem('auth_id'),
      type: velo.type,
      marque: velo.marque,
      modele: velo.modele,
      taille: velo.taille,
      couleur: velo.couleur,
    }
    axios.post(`/api/bikeadd`, data).then(res => {
      if(res.data.status === 200){
        history.push('/account/addrendezvous')
      } else {
        setVelo({...velo, error: res.data.errors})
      }
    })
  }

  return (
    <div className='container mt-5'>
    <div className="card">
      <div className="card-header text-capitalize h4">ajout vélo</div>
      <div className="card-body">
        <form className='form-group' onSubmit={veloSubmit}>
          <div className="mb-3 form-group">
            <input type="text" name='type' className="form-control" placeholder="Type du vélo" onChange={handleInput} value={velo.type} />
            <span className="text-danger">{velo.error.type}</span>
          </div>
          <div className="row mb-3 form-group">
            <div className="col-md form-group">
              <input type="text" name='marque' className="form-control" placeholder="Marque du vélo" onChange={handleInput} value={velo.marque} />
              <span className="text-danger">{velo.error.marque}</span>
            </div>
            <div className="col-md form-group">
              <input type="text" name='modele' className="form-control" placeholder="Modele du vélo" onChange={handleInput} value={velo.modele} />
              <span className="text-danger">{velo.error.modele}</span>
            </div>
          </div>
          <div className="row mb-3 form-group">
            <div className="col-md form-group">
              <input type="text" name='taille' className="form-control" placeholder="Taille du vélo" onChange={handleInput} value={velo.taille} />
              <span className="text-danger">{velo.error.taille}</span>
            </div>
            <div className="col-md form-group">
              <input type="text" name='couleur' className="form-control" placeholder="Couleur du vélo" onChange={handleInput} value={velo.couleur} />
              <span className="text-danger">{velo.error.couleur}</span>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-auto">
              <button type='submit' className="border rounded-pill text-capitalize btn btn-outline-primary">ajouter</button>
            </div>
            <div className="col-auto">
              <Link to='/account/dashboard' className='text-capitalize btn btn-outline-secondary border rounded-pill'>retour</Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  )
}

export default AddVelo

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function Listevelo() {
  const [velos, setVelos] = useState([])

  useEffect(() => {
    fetchdata()
  }, [])

  const fetchdata = () => {
    axios.get(`/api/bike/${localStorage.getItem('auth_id')}.${localStorage.getItem('auth_name')}`).then(res => {
      setVelos(res.data.bike)
    })
  }

  // const velolist = velos.map(velo => 
  //   <tr key={velo.id} className='text-capitalize text-center'>
  //     <td>{velo.type}</td>
  //     <td>{velo.marque}</td>
  //     <td>{velo.modele}</td>
  //     <td>{velo.taille}</td>
  //     <td>{velo.couleur}</td>
  //   </tr>
  //   )

  return (
    <div className='container mt-5'>
    <div className='d-flex justify-content-between'>
      <Link to='/account/dashboard' className='btn btn-secondary'>Retour</Link>
      <Link to='/account/addvelo' className='btn btn-primary'>Ajouter vélo</Link>
    </div>
      <div className="mt-4">
        <div className="card">
          <div className="card-header text-capitalize">
            <p className='h4 text-center'>mes vélos</p>
          </div>
          <div className="card-body">
            <table className="table table-bordered table-striped">
              <thead>
                <tr className="text-capitalize text-center">
                  <th><span className='btn fw-bold pe-none'>type</span></th>
                  <th><span className='btn fw-bold pe-none'>marque</span></th>
                  <th><span className='btn fw-bold pe-none'>modele</span></th>
                  <th><span className='btn fw-bold pe-none'>taille</span></th>
                  <th><span className='btn fw-bold pe-none'>couleur</span></th>
                </tr>
              </thead>
              <tbody>
                {velos ? velos.map(velo => 
                  <tr key={velo.id} className='text-capitalize text-center'>
                    <td>{velo.type}</td>
                    <td>{velo.marque}</td>
                    <td>{velo.modele}</td>
                    <td>{velo.taille}</td>
                    <td>{velo.couleur}</td>
                  </tr>) : null
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Listevelo

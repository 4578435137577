import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

function ListeRendezVous() {
  const [rdvs, setRdvs] = useState([])
  const [velos, setVelos] = useState([])

  useEffect(() => {
    fetchrdv()
    fetchbike()
  }, [])

  const fetchrdv = () => {
    axios.get(`/api/rdv/${localStorage.getItem('auth_id')}.${localStorage.getItem('auth_name')}`).then(res => {
      setRdvs(res.data.rdv)
    })
  }

  const fetchbike = () => {
    axios.get(`/api/bike/${localStorage.getItem('auth_id')}.${localStorage.getItem('auth_name')}`).then(res => {
      setVelos(res.data.bike)
    })
  }

  // const rdvlist = rdvs.map(rdv => 
  //   <tr key={rdv.id} className='text-capitalize text-center'>
  //     <td>
  //       {velos.map(velo => 
  //       (rdv.velo_id === velo.id ? (<span key={velo.id}>{velo.type} {velo.marque} {velo.modele} {velo.taille} {velo.couleur}</span>) : null))}
  //     </td>
  //     <td>{rdv.date}</td>
  //     <td>
  //       {rdv.etat === ' à traiter' ? <span className='badge rounded-pill p-2 text-bg-warning'>{rdv.etat}</span> : rdv.etat === 'en cours' ? <span className='badge rounded-pill p-2 text-bg-primary'>{rdv.etat}</span> : rdv.etat === 'réalisé' ? <span className='badge rounded-pill p-2 text-bg-success'>{rdv.etat}</span> : rdv.etat === 'en attente' ? <span className='badge rounded-pill p-2 text-bg-danger'>{rdv.etat}</span> : null}
  //     </td>
  //   </tr>
  //   )

  return (
    <div className='container mt-5'>
    <div className='d-flex justify-content-between'>
      <Link to='/account/dashboard' className='btn btn-secondary'>Retour</Link>
      <Link to='/account/addrendezvous' className='btn btn-primary'>Prendre rendez-vous</Link>
    </div>
      <div className="mt-4">
        <div className="card">
          <div className="card-header text-capitalize">
            <p className='h4 text-center'>mes réservations</p>
          </div>
          <div className="card-body">
            <table className="table table-bordered table-striped">
              <thead>
                <tr className="text-capitalize text-center">
                  <th><span className='btn fw-bold pe-none'>vélo</span></th>
                  <th><span className='btn fw-bold pe-none'>date</span></th>
                  <th><span className='btn fw-bold pe-none'>état</span></th>
                </tr>
              </thead>
              <tbody>
                {rdvs ? rdvs.map(rdv => 
                  <tr key={rdv.id} className='text-capitalize text-center'>
                    <td>
                      {velos.map(velo => 
                      (rdv.velo_id === velo.id ? (<span key={velo.id}>{velo.type} {velo.marque} {velo.modele} {velo.taille} {velo.couleur}</span>) : null))}
                    </td>
                    <td>{rdv.date}</td>
                    <td>
                      {rdv.etat === 'a traiter' ? <span className='badge rounded-pill p-2 text-bg-warning'>{rdv.etat}</span> : rdv.etat === 'en cours' ? <span className='badge rounded-pill p-2 text-bg-primary'>{rdv.etat}</span> : rdv.etat === 'réalisé' ? <span className='badge rounded-pill p-2 text-bg-success'>{rdv.etat}</span> : rdv.etat === 'en attente' ? <span className='badge rounded-pill p-2 text-bg-danger'>{rdv.etat}</span> : rdv.etat === 'annulé' ? <span className='badge rounded-pill p-2 text-bg-danger'>{rdv.etat}</span> : null}
                    </td>
                  </tr>) : null
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListeRendezVous

import axios from 'axios'
import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'

function FicheAdd() {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState()
  const [fiche, setFiche] = useState({
    velo:'',
    cadre:'',
    taille:'',
    amortisseur:'',
    fourche:'',
    boitier_pedalier:'',
    pedalier:'',
    pedale:'',
    chaine:'',
    cassette:'',
    derailleur_av:'',
    derailleur_ar:'',
    manette:'',
    frein:'',
    jeu_direction:'',
    potence:'',
    tige_selle:'',
    selle:'',
    collier_selle:'',
    poigne:'',
    moyeu_av:'',
    moyeu_ar:'',
    jante:'',
    rayon:'',
    pneu_av:'',
    pneu_ar:'',
    couleur:'',
    moteur:'',
    batterie:'',
    chargeur:'',
    ecran:'',
    error: []
  })

  const handleInput = (e) => {
    e.persist()
    setFiche({ ...fiche, [e.target.name]: e.target.value })
  }

  const ficheSubmit = (e) => {
    setLoading(true)
    e.preventDefault()
    var amort = document.getElementById('amort').value
    var mtr = document.getElementById('mtr').value
    var btr = document.getElementById('btr').value
    var chg = document.getElementById('chg').value
    var ecr = document.getElementById('ecr').value
    var data = new FormData()
    console.log(file)
    if(mtr.length === 0 && btr.length === 0 && chg.length === 0 && ecr.length === 0){
      if (amort.length === 0) {
          data.append("velo", fiche.velo)
          data.append("photo", file) 
          data.append("cadre", fiche.cadre)
          data.append("taille", fiche.taille) 
          data.append("fourche", fiche.fourche) 
          data.append("boitier_pedalier" ,fiche.boitier_pedalier)
          data.append("pedalier" ,fiche.pedalier)
          data.append("pedale", fiche.pedale) 
          data.append("chaine", fiche.chaine) 
          data.append("cassette", fiche.cassette) 
          data.append("derailleur_av" ,fiche.derailleur_av)
          data.append("derailleur_ar", fiche.derailleur_ar)
          data.append("manette", fiche.manette) 
          data.append("frein", fiche.frein)
          data.append("jeu_direction", fiche.jeu_direction)
          data.append("potence", fiche.potence)
          data.append("tige_selle", fiche.tige_selle)
          data.append("selle", fiche.selle)
          data.append("collier_selle", fiche.collier_selle)
          data.append("poigne", fiche.poigne)
          data.append("moye_av", fiche.moyeu_av)
          data.append("moyeu_ar", fiche.moyeu_ar)
          data.append("jante", fiche.jante)
          data.append("rayon", fiche.rayon)
          data.append("pneu_av", fiche.pneu_av)
          data.append("pneu_ar", fiche.pneu_ar)
          data.append("couleur", fiche.couleur)
      } else {
          data.append("velo", fiche.velo)
          data.append("photo", file) 
          data.append("cadre", fiche.cadre)
          data.append("amortisseur", fiche.amortisseur)
          data.append("taille", fiche.taille) 
          data.append("fourche", fiche.fourche) 
          data.append("boitier_pedalier" ,fiche.boitier_pedalier)
          data.append("pedalier" ,fiche.pedalier)
          data.append("pedale", fiche.pedale) 
          data.append("chaine", fiche.chaine) 
          data.append("cassette", fiche.cassette) 
          data.append("derailleur_av" ,fiche.derailleur_av)
          data.append("derailleur_ar", fiche.derailleur_ar)
          data.append("manette", fiche.manette) 
          data.append("frein", fiche.frein)
          data.append("jeu_direction", fiche.jeu_direction)
          data.append("potence", fiche.potence)
          data.append("tige_selle", fiche.tige_selle)
          data.append("selle", fiche.selle)
          data.append("collier_selle", fiche.collier_selle)
          data.append("poigne", fiche.poigne)
          data.append("moye_av", fiche.moyeu_av)
          data.append("moyeu_ar", fiche.moyeu_ar)
          data.append("jante", fiche.jante)
          data.append("rayon", fiche.rayon)
          data.append("pneu_av", fiche.pneu_av)
          data.append("pneu_ar", fiche.pneu_ar)
          data.append("couleur", fiche.couleur)
      }
    } else {
      if(amort.length === 0){
          data.append("velo", fiche.velo)
          data.append("photo", file) 
          data.append("cadre", fiche.cadre)
          data.append("taille", fiche.taille) 
          data.append("fourche", fiche.fourche) 
          data.append("boitier_pedalier" ,fiche.boitier_pedalier)
          data.append("pedalier" ,fiche.pedalier)
          data.append("pedale", fiche.pedale) 
          data.append("chaine", fiche.chaine) 
          data.append("cassette", fiche.cassette) 
          data.append("derailleur_av" ,fiche.derailleur_av)
          data.append("derailleur_ar", fiche.derailleur_ar)
          data.append("manette", fiche.manette) 
          data.append("frein", fiche.frein)
          data.append("jeu_direction", fiche.jeu_direction)
          data.append("potence", fiche.potence)
          data.append("tige_selle", fiche.tige_selle)
          data.append("selle", fiche.selle)
          data.append("collier_selle", fiche.collier_selle)
          data.append("poigne", fiche.poigne)
          data.append("moye_av", fiche.moyeu_av)
          data.append("moyeu_ar", fiche.moyeu_ar)
          data.append("jante", fiche.jante)
          data.append("rayon", fiche.rayon)
          data.append("pneu_av", fiche.pneu_av)
          data.append("pneu_ar", fiche.pneu_ar)
          data.append("couleur", fiche.couleur)
          data.append("moteur", fiche.moteur)
          data.append("batterie", fiche.batterie)
          data.append("chargeur", fiche.chargeur)
          data.append("ecran", fiche.ecran)
      } else {
          data.append("velo", fiche.velo)
          data.append("photo", file) 
          data.append("cadre", fiche.cadre)
          data.append("amortisseur", fiche.amortisseur)
          data.append("taille", fiche.taille) 
          data.append("fourche", fiche.fourche) 
          data.append("boitier_pedalier" ,fiche.boitier_pedalier)
          data.append("pedalier" ,fiche.pedalier)
          data.append("pedale", fiche.pedale) 
          data.append("chaine", fiche.chaine) 
          data.append("cassette", fiche.cassette) 
          data.append("derailleur_av" ,fiche.derailleur_av)
          data.append("derailleur_ar", fiche.derailleur_ar)
          data.append("manette", fiche.manette) 
          data.append("frein", fiche.frein)
          data.append("jeu_direction", fiche.jeu_direction)
          data.append("potence", fiche.potence)
          data.append("tige_selle", fiche.tige_selle)
          data.append("selle", fiche.selle)
          data.append("collier_selle", fiche.collier_selle)
          data.append("poigne", fiche.poigne)
          data.append("moye_av", fiche.moyeu_av)
          data.append("moyeu_ar", fiche.moyeu_ar)
          data.append("jante", fiche.jante)
          data.append("rayon", fiche.rayon)
          data.append("pneu_av", fiche.pneu_av)
          data.append("pneu_ar", fiche.pneu_ar)
          data.append("couleur", fiche.couleur)
          data.append("moteur", fiche.moteur)
          data.append("batterie", fiche.batterie)
          data.append("chargeur", fiche.chargeur)
          data.append("ecran", fiche.ecran)
      }
    }
    axios.post(`/api/ficheadd`, data).then(res => {
      if(res.data.status === 200) {
        history.push('/admin101/fiche')
      } else {
        setFiche({...fiche, error:res.data.errors})
        setLoading(false)
      }      
    })
  }

  if(loading){
    return (
      <div className="d-flex justify-content-center container py-5">
        <div className="spinner-border text-success my-5" role='status'></div>
      </div>
    )
  }

  return (
    <div className='container mt-5'>
      <div className='card'>
        <div className='card-header text-capitalize h4'>ajout fiche technique</div>
        <div className='card-body'>
          <form className='form-group' onSubmit={ficheSubmit} encType="multipart/form-data" id="fiche_form">
            <div className='row mb-3 form-group'>
              <div className="col-md form-group">
                <input type="file" name='photo' className="form-control" onChange={(e) => setFile(e.target.files[0])} />
                <span className='text-danger'>{fiche.error.photo}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='velo' className="form-control" placeholder="Nom" onChange={handleInput} value={fiche.velo} />
                <span className='text-danger'>{fiche.error.velo}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='taille' className="form-control" placeholder="Taille" onChange={handleInput} value={fiche.taille} />
                <span className='text-danger'>{fiche.error.taille}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='cadre' className="form-control" placeholder="Cadre" onChange={handleInput} value={fiche.cadre} />
                <span className='text-danger'>{fiche.error.cadre}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="fourche" name='fourche' className="form-control" placeholder="Fourche" onChange={handleInput} value={fiche.fourche} />
                <span className='text-danger'>{fiche.error.fourche}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='amortisseur' id='amort' className="form-control" placeholder="Amortisseur" onChange={handleInput} value={fiche.amortisseur} />
                <span className='text-danger'>{fiche.error.amortisseur}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='pedalier' className="form-control" placeholder="Pédalier" onChange={handleInput} value={fiche.pedalier} />
                <span className='text-danger'>{fiche.error.pedalier}</span>
              </div>              
              <div className="col-md form-group">
                <input type="text" name='boitier_pedalier' className="form-control" placeholder="Boîtier pédalier" onChange={handleInput} value={fiche.boitier_pedalier} />
                <span className='text-danger'>{fiche.error.boitier_pedalier}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">     
              <div className="col-md form-group">
                <input type="text" name='pedale' className="form-control" placeholder="Pédales" onChange={handleInput} value={fiche.pedale} />
                <span className='text-danger'>{fiche.error.pedale}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='chaine' className="form-control" placeholder="Chaîne" onChange={handleInput} value={fiche.chaine} />
                <span className='text-danger'>{fiche.error.chaine}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='cassette' className="form-control" placeholder="Cassette" onChange={handleInput} value={fiche.cassette} />
                <span className='text-danger'>{fiche.error.cassette}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='derailleur_av' className="form-control" placeholder="Dérailleur avant" onChange={handleInput} value={fiche.derailleur_av} />
                <span className='text-danger'>{fiche.error.derailleur_av}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='derailleur_ar' className="form-control" placeholder="Dérailleur arrière" onChange={handleInput} value={fiche.derailleur_ar} />
                <span className='text-danger'>{fiche.error.derailleur_ar}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='manette' className="form-control" placeholder="Manettes" onChange={handleInput} value={fiche.manette} />
                <span className='text-danger'>{fiche.error.manette}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='frein' className="form-control" placeholder="Freins" onChange={handleInput} value={fiche.frein} />
                <span className='text-danger'>{fiche.error.frein}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='jeu_direction' className="form-control" placeholder="Jeu de direction" onChange={handleInput} value={fiche.jeu_direction} />
                <span className='text-danger'>{fiche.error.jeu_direction}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='potence' className="form-control" placeholder="Potence" onChange={handleInput} value={fiche.potence} />
                <span className='text-danger'>{fiche.error.potence}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='tige_selle' className="form-control" placeholder="Tige de selle" onChange={handleInput} value={fiche.tige_selle} />
                <span className='text-danger'>{fiche.error.tige_selle}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='selle' className="form-control" placeholder="Selle" onChange={handleInput} value={fiche.selle} />
                <span className='text-danger'>{fiche.error.selle}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='collier_selle' className="form-control" placeholder="Collier de selle" onChange={handleInput} value={fiche.collier_selle} />
                <span className='text-danger'>{fiche.error.collier_selle}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='poigne' className="form-control" placeholder="Poignées" onChange={handleInput} value={fiche.poigne} />
                <span className='text-danger'>{fiche.error.poigne}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='moyeu_av' className="form-control" placeholder="Moyeu avant" onChange={handleInput} value={fiche.moyeu_av} />
                <span className='text-danger'>{fiche.error.moyeu_av}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='moyeu_ar' className="form-control" placeholder="Moyeu arrière" onChange={handleInput} value={fiche.moyeu_ar} />
                <span className='text-danger'>{fiche.error.moyeu_ar}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='jante' className="form-control" placeholder="Jantes" onChange={handleInput} value={fiche.jante} />
                <span className='text-danger'>{fiche.error.jante}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='rayon' className="form-control" placeholder="Rayon" onChange={handleInput} value={fiche.rayon} />
                <span className='text-danger'>{fiche.error.rayon}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='pneu_av' className="form-control" placeholder="Pneu avant" onChange={handleInput} value={fiche.pneu_av} />
                <span className='text-danger'>{fiche.error.pneu_av}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='couleur' className="form-control" placeholder="Couleur" onChange={handleInput} value={fiche.couleur} />
                <span className='text-danger'>{fiche.error.couleur}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='pneu_ar' className="form-control" placeholder="Pneu arrière" onChange={handleInput} value={fiche.pneu_ar} />
                <span className='text-danger'>{fiche.error.pneu_ar}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='moteur' id='mtr' className="form-control" placeholder="Moteur" onChange={handleInput} value={fiche.moteur} />
                <span className='text-danger'>{fiche.error.moteur}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='batterie' id='btr' className="form-control" placeholder="Battérie" onChange={handleInput} value={fiche.batterie} />
                <span className='text-danger'>{fiche.error.batterie}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='chargeur' id='chg' className="form-control" placeholder="Chargeur" onChange={handleInput} value={fiche.chargeur} />
                <span className='text-danger'>{fiche.error.chargeur}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='ecran' id='ecr' className="form-control" placeholder="Ecran" onChange={handleInput} value={fiche.ecran} />
                <span className='text-danger'>{fiche.error.ecran}</span>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <button type='submit' className="border rounded-pill text-capitalize btn btn-outline-primary">ajouter</button>
              </div>
              <div className="col-auto">
                <Link to='/admin101/fiche' className='text-capitalize btn btn-outline-secondary border rounded-pill' >retour</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default FicheAdd
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Pagination from '../Pagination'

function Maintenance() {
  const [maintenances, setMaintenance] = useState([])
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [typeDate, setTypeDate] = useState()
  const [typeIntervention, setTypeIntervention] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  
  useEffect(() => {
    fetchdata(currentPage)
  }, [currentPage])

  const fetchdata = (page) => {
    axios.get(`/api/reservationlist?page=${page}`).then(res => {
      setMaintenance(res.data.reservation.data)
      setTotalPage(res.data.reservation.last_page)
    })
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleStartDate = (date) => {
    setStartDate(date)
  }

  const handleEndDate = (date) => {
    setEndDate(date)
  }

  const handleTypeDate = (e) => {
    setTypeDate(e.target.value)
  }

  const handleTypeIntervention = (e) => {
    setTypeIntervention(e.target.value)
  }

  const atraiter = maintenances.filter(el => (el.etat === 'a traiter' && (startDate && endDate) && typeDate === "reservation" && typeIntervention === "curatif") ? (el.intervention !== "entretien préventif" && el.intervention !== "commande pièce" && moment(el.date, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'a traiter' && (startDate && endDate) && typeDate === "reservation" && typeIntervention === "preventif") ? (el.intervention === "entretien préventif" && moment(el.date, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'a traiter' && (startDate && endDate) && typeDate === "reservation" && typeIntervention === "commande") ? (el.intervention === "commande pièce" && moment(el.date, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'a traiter' && (startDate && endDate) && typeDate === "charge" && typeIntervention === "curatif") ? (el.intervention !== "entretien préventif" && el.intervention !== "commande pièce" && moment(el.date_pem, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'a traiter' && (startDate && endDate) && typeDate === "charge" && typeIntervention === "preventif") ? (el.intervention === "entretien préventif" && moment(el.date_pem, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'a traiter' && (startDate && endDate) && typeDate === "charge" && typeIntervention === "commande") ? (el.intervention === "commande pièce" && moment(el.date_pem, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : el.etat === "a traiter")
  const encours = maintenances.filter(el => (el.etat === 'en cours' && (startDate && endDate) && typeDate === "reservation" && typeIntervention === "curatif") ? (el.intervention !== "entretien préventif" && el.intervention !== "commande pièce" && moment(el.date, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'en cours' && (startDate && endDate) && typeDate === "reservation" && typeIntervention === "preventif") ? (el.intervention === "entretien préventif" && moment(el.date, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'en cours' && (startDate && endDate) && typeDate === "reservation" && typeIntervention === "commande") ? (el.intervention === "commande pièce" && moment(el.date, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'en cours' && (startDate && endDate) && typeDate === "charge" && typeIntervention === "curatif") ? (el.intervention !== "entretien préventif" && el.intervention !== "commande pièce" && moment(el.date_pem, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'en cours' && (startDate && endDate) && typeDate === "charge" && typeIntervention === "preventif") ? (el.intervention === "entretien préventif" && moment(el.date_pem, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'en cours' && (startDate && endDate) && typeDate === "charge" && typeIntervention === "commande") ? (el.intervention === "commande pièce" && moment(el.date_pem, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : el.etat === "en cours")
  const enattente = maintenances.filter(el => (el.etat === 'en attente' && (startDate && endDate) && typeDate === "reservation" && typeIntervention === "curatif") ? (el.intervention !== "entretien préventif" && el.intervention !== "commande pièce" && moment(el.date, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'en attente' && (startDate && endDate) && typeDate === "reservation" && typeIntervention === "preventif") ? (el.intervention === "entretien préventif" && moment(el.date, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'en attente' && (startDate && endDate) && typeDate === "reservation" && typeIntervention === "commande") ? (el.intervention === "commande pièce" && moment(el.date, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'en attente' && (startDate && endDate) && typeDate === "charge" && typeIntervention === "curatif") ? (el.intervention !== "entretien préventif" && el.intervention !== "commande pièce" && moment(el.date_pem, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'en attente' && (startDate && endDate) && typeDate === "charge" && typeIntervention === "preventif") ? (el.intervention === "entretien préventif" && moment(el.date_pem, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'en attente' && (startDate && endDate) && typeDate === "charge" && typeIntervention === "commande") ? (el.intervention === "commande pièce" && moment(el.date_pem, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : el.etat === "en attente")
  const realise = maintenances.filter(el => (el.etat === 'réalisé' && (startDate && endDate) && typeDate === "reservation" && typeIntervention === "curatif") ? (el.intervention !== "entretien préventif" && el.intervention !== "commande pièce" && moment(el.date, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'réalisé' && (startDate && endDate) && typeDate === "reservation" && typeIntervention === "preventif") ? (el.intervention === "entretien préventif" && moment(el.date, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'réalisé' && (startDate && endDate) && typeDate === "reservation" && typeIntervention === "commande") ? (el.intervention === "commande pièce" && moment(el.date, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'réalisé' && (startDate && endDate) && typeDate === "charge" && typeIntervention === "curatif") ? (el.intervention !== "entretien préventif" && el.intervention !== "commande pièce" && moment(el.date_pem, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'réalisé' && (startDate && endDate) && typeDate === "charge" && typeIntervention === "preventif") ? (el.intervention === "entretien préventif" && moment(el.date_pem, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : (el.etat === 'réalisé' && (startDate && endDate) && typeDate === "charge" && typeIntervention === "commande") ? (el.intervention === "commande pièce" && moment(el.date_pem, 'YYYY-MM-DD').isBetween(startDate, endDate, 'day', '[]')) : el.etat === "réalisé")

  return (
    <div className='container mt-2 w-100'>
      <div className="d-flex justify-content-around mb-3 form-group">
        <div className='d-flex align-items-center'>
          <div>Début : &nbsp;</div>
          <div><input type="date" className='form-control' onChange={(e) => handleStartDate(e.target.value)} /></div>
        </div>
        <div className='d-flex align-items-center'>
          <div>Fin : &nbsp;</div>
          <div><input type="date" className='form-control' onChange={(e) => handleEndDate(e.target.value)} /></div>
        </div>
        <div>
          <select name="typeDate" onChange={handleTypeDate} className='form-select text-capitalize'>
            <option value="" className='text-capitalize'>type date</option>
            <option value="reservation" className='text-capitalize'>réservation</option>
              <option value="charge" className='text-capitalize'>prise en charge</option>
          </select>
        </div>
        <div>
          <select name="typeIntervention" onChange={handleTypeIntervention} className='form-select text-capitalize'>
            <option value="" className='text-capitalize'>type intervention</option>
            <option value="curatif" className='text-capitalize'>curative</option>
            <option value="preventif" className='text-capitalize'>préventive</option>
            <option value="commande" className='text-capitalize'>commande pièce</option>
          </select>
        </div>
      </div>
      <div className='mt-4 d-flex align-items-center justify-content-center'>
        <Pagination currentPage={currentPage} totalPage={totalPage} onPageChange={handlePageChange} />
      </div>
      <div className="d-flex justify-content-between">
        <div className="rounded-3 w-100 m-2">
          <p className="h5 text-center my-3 border py-2 rounded-top bg-warning text-white">À traiter</p>
          {atraiter ? atraiter.map(atraite => 
            <Link to={`/admin101/maintenancedetails/${atraite.id}`} className='text-decoration-none text-black' key={atraite.id}>
              <div className="card mx-2 my-3">
                <div className="d-flex justify-content-between mt-1 mx-2">
                  <p></p>
                  <p>Réservation : {atraite.date}<br />Prise en charge : {atraite.date_pem ? atraite.date_pem : 'N/A'}<br/>Intervention : <span className='text-capitalize'>{atraite.intervention}</span></p>
                </div>
                <div className='mx-2'>
                  <p className="h5 text-capitalize">{atraite.velo.user.nom} {atraite.velo.user.prenom}</p>
                  <p><span className="text-uppercase">{atraite.velo.type} {atraite.velo.marque} {atraite.velo.modele}</span> Taille <span className="text-uppercase">{atraite.velo.taille}</span> / <span className="text-capitalize">{atraite.velo.couleur}</span></p>
                </div>
              </div>
            </Link>) : null
          }
        </div>
        <div className="rounded-3 w-100 m-2">
          <p className="h5 text-center my-3 border py-2 rounded-top bg-danger text-white">En attente</p>
          {enattente ? enattente.map(enattent => 
            <Link to={`/admin101/maintenancedetails/${enattent.id}`} className='text-decoration-none text-black'  key={enattent.id}>
              <div className="card mx-2 my-3">
                <div className="d-flex justify-content-between mt-1 mx-2">
                  <p><span className='rounded-pill px-2 bg-info pb-1 text-capitalize'>{enattent.mecano}</span></p>
                  <p>Réservation : {enattent.date}<br />Prise en charge : {enattent.date_pem ? enattent.date_pem : 'N/A'}<br/>Intervention : <span className='text-capitalize'>{enattent.intervention}</span></p>
                </div>
                <div className='mx-2'>
                  <p className="h5 text-capitalize">{enattent.velo.user.nom} {enattent.velo.user.prenom}</p>
                  <p><span className="text-uppercase">{enattent.velo.type} {enattent.velo.marque} {enattent.velo.modele}</span> Taille <span className="text-uppercase">{enattent.velo.taille}</span> / <span className="text-capitalize">{enattent.velo.couleur}</span></p>
                </div>
              </div>
            </Link>) : null
          }
        </div>
        <div className="rounded-3 w-100 m-2">
          <p className="h5 text-center my-3 border py-2 rounded-top bg-primary text-white">En cours</p>
          {encours ? encours.map(encour => 
            <Link to={`/admin101/maintenancedetails/${encour.id}`} className='text-decoration-none text-black' key={encour.id}>
              <div className="card mx-2 my-3">
                <div className="d-flex justify-content-between mt-1 mx-2">
                  <p><span className='rounded-pill px-2 bg-info pb-1 text-capitalize'>{encour.mecano}</span></p>
                  <p>Réservation : {encour.date}<br />Prise en charge : {encour.date_pem ? encour.date_pem : 'N/A'}<br/>Intervention : <span className='text-capitalize'>{encour.intervention}</span></p>
                </div>
                <div className='mx-2'>
                  <p className="h5 text-capitalize">{encour.velo.user.nom} {encour.velo.user.prenom}</p>
                  <p><span className="text-uppercase">{encour.velo.type} {encour.velo.marque} {encour.velo.modele}</span> Taille <span className="text-uppercase">{encour.velo.taille}</span> / <span className="text-capitalize">{encour.velo.couleur}</span></p>
                </div>
              </div>
            </Link>) : null
          }
        </div>
        <div className="rounded-3 w-100 m-2">
          <p className="h5 text-center my-3 border py-2 rounded-top bg-success text-white">Réalisé</p>
          {realise ? realise.map(realis => 
            <Link to={`/admin101/maintenancedetails/${realis.id}`} className='text-decoration-none text-black' key={realis.id}>
              <div className="card mx-2 my-3">
                <div className="d-flex justify-content-between mt-1 mx-2">
                  <p><span className='rounded-pill px-2 bg-info pb-1 text-capitalize'>{realis.mecano}</span></p>
                  <p>Réservation : {realis.date}<br />Prise en charge : {realis.date_pem ? realis.date_pem : 'N/A'}<br/>Intervention : <span className='text-capitalize'>{realis.intervention}</span></p>
                </div>
                <div className='mx-2'>
                  <p className="h5 text-capitalize">{realis.velo.user.nom} {realis.velo.user.prenom}</p>
                  <p><span className="text-uppercase">{realis.velo.type} {realis.velo.marque} {realis.velo.modele}</span> Taille <span className="text-uppercase">{realis.velo.taille}</span> / <span className="text-capitalize">{realis.velo.couleur}</span></p>
                  <p>{realis.statut === "payé - livré" ? <span className='rounded-pill px-2 bg-success pb-1 text-capitalize fw-bold text-white'>{realis.statut}</span> : <span className='rounded-pill px-2 bg-danger pb-1 text-capitalize fw-bold text-white'>{realis.statut}</span>}</p>
                </div>
              </div>
            </Link>) : null
          }
        </div>
      </div>
    </div>
  )
}

export default Maintenance

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBicycle, faBook, faMapLocationDot, faScrewdriver, faTachometerAlt, faToolbox, faTools, faUserGroup, faUsersGear, faUsersRectangle } from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import {Link} from 'react-router-dom'

function Sidebar() {
    var Sidelinks =''
    if (localStorage.getItem('admin_role') === 'admin') {
        Sidelinks = (
            <div className="nav">
                <div className="sb-sidenav-menu-heading"><hr/>Core</div>
                <Link className="nav-link" to="/admin101/dashboard">
                    <FontAwesomeIcon icon={faTachometerAlt} />Dashboard
                </Link>
                <div className="sb-sidenav-menu-heading"><hr/>Clients</div>
                <Link className="nav-link" to="/admin101/client">
                    <FontAwesomeIcon icon={faUsersRectangle} /> &nbsp; Clients
                </Link>
                <Link className="nav-link" to="/admin101/clientadresse">
                    <FontAwesomeIcon icon={faMapLocationDot} /> &nbsp; Adresses
                </Link>
                <Link className="nav-link" to="/admin101/velo">
                    <FontAwesomeIcon icon={faBicycle} /> &nbsp; Vélos
                </Link>
                <Link className="nav-link" to="/admin101/reservation">
                    <FontAwesomeIcon icon={faBook} /> &nbsp; Réservations
                </Link>
                <div className="sb-sidenav-menu-heading"><hr/>Atelier</div>
                <Link className="nav-link" to="/admin101/reparation">
                    <FontAwesomeIcon icon={faToolbox} /> &nbsp; Réparations
                </Link>
                <Link className="nav-link" to="/admin101/intervention">
                    <FontAwesomeIcon icon={faScrewdriver} /> &nbsp; Interventions
                </Link>
                <Link className="nav-link" to="/admin101/maintenance">
                    <FontAwesomeIcon icon={faTools} /> &nbsp; Maintenances
                </Link>
            </div>
        )
    } else {
        Sidelinks = (
            <div className="nav">
                <div className="sb-sidenav-menu-heading"><hr/>Core</div>
                <Link className="nav-link" to="/admin101/dashboard">
                    <FontAwesomeIcon icon={faTachometerAlt} /> &nbsp; Dashboard
                </Link>
                <div className="sb-sidenav-menu-heading"><hr/>Equipes</div>
                <Link className="nav-link" to="/admin101/employe">
                    <FontAwesomeIcon icon={faUserGroup} /> &nbsp; Assistants
                </Link>
                <Link className="nav-link" to="/admin101/mecano">
                    <FontAwesomeIcon icon={faUsersGear} /> &nbsp; Mécanos
                </Link>
                <div className="sb-sidenav-menu-heading"><hr/>Clients</div>
                <Link className="nav-link" to="/admin101/client">
                    <FontAwesomeIcon icon={faUsersRectangle} /> &nbsp; Clients
                </Link>
                <Link className="nav-link" to="/admin101/clientadresse">
                    <FontAwesomeIcon icon={faMapLocationDot} /> &nbsp; Adresses
                </Link>
                <Link className="nav-link" to="/admin101/velo">
                    <FontAwesomeIcon icon={faBicycle} /> &nbsp; Vélos
                </Link>
                <Link className="nav-link" to="/admin101/reservation">
                    <FontAwesomeIcon icon={faBook} /> &nbsp; Réservations
                </Link>
                <div className="sb-sidenav-menu-heading"><hr/>Atelier</div>
                <Link className="nav-link" to="/admin101/reparation">
                    <FontAwesomeIcon icon={faToolbox} /> &nbsp; Groupe intervention
                </Link>
                <Link className="nav-link" to="/admin101/intervention">
                    <FontAwesomeIcon icon={faScrewdriver} /> &nbsp; Interventions
                </Link>
                <Link className="nav-link" to="/admin101/maintenance">
                    <FontAwesomeIcon icon={faTools} /> &nbsp; Maintenances
                </Link>
            </div>
        )
    }
    
  return (
    <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
      <div className="sb-sidenav-menu">
          {Sidelinks}
      </div>
    </nav>
  )
}

export default Sidebar

import React, { useEffect, useState } from "react";
import axios from "axios";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import swal from "sweetalert";

function Prevision() {
  const [jour, setJour] = useState(0);
  const [info, setInfo] = useState({
    velo: "",
    terrain: "",
    distance: "",
  });

  const [velos, setVelos] = useState([]);
  const [velo, setVelo] = useState([]);
  const [clients, setClients] = useState([]);
  const [userId, setUserId] = useState(null);
  const [veloId, setVeloId] = useState(null);
  const [mecanos, setMecanos] = useState([]);
  const [maintenances, setMaintenances] = useState([]);
  const [pies, setPies] = useState([]);

  const addMaintenances = () => {
    setMaintenances([
      ...maintenances,
      { designation: "", quantite: "", prix: "" },
    ]);
  };

  const handleMaintenances = (i, e) => {
    const val = [...maintenances];
    val[i][e.target.name] = e.target.value;
    setMaintenances(val);
  };

  const removeMaintenances = (i) => {
    const val = [...maintenances];
    val.splice(i, 1);
    setMaintenances(val);
  };

  const addPieces = () => {
    setPies([...pies, { designation: "", quantite: "", prix: "" }]);
  };

  const handlePieces = (i, e) => {
    const val = [...pies];
    val[i][e.target.name] = e.target.value;
    setPies(val);
  };

  const removePieces = (i) => {
    const val = [...pies];
    val.splice(i, 1);
    setPies(val);
  };

  var count_m = 0;
  maintenances.map((el) => {
    return (count_m += Number(el.prix * el.quantite));
  });

  var count_p = 0;
  pies.map((el) => {
    return (count_p += Number(el.prix * el.quantite));
  });

  var count = count_m + count_p;

  useEffect(() => {
    fetchdata();
    fetchmecano();
    fetch(veloId?.value);
  }, [veloId?.value]);

  const fetchdata = () => {
    axios.get("/api/clientlistprevision").then((res) => {
      setClients(res.data.client);
    });
    axios.get("/api/velolistprevision").then((res) => {
      setVelos(res.data.velo);
    });
  };

  const clientlist = clients.map((client) => ({
    value: client.id,
    label: `${client.nom} ${client.prenom}`,
  }));

  const velolist =
    userId &&
    velos
      .filter((el) => el.user.id === userId.value)
      .map((velo) => ({
        value: velo.id,
        label: `${velo.marque} ${velo.modele}`,
      }));

  const handleUserId = (userId) => {
    setUserId(userId);
  };

  const handleVeloId = (veloId) => {
    setVeloId(veloId);
  };

  const fetch = (id) => {
    axios.get(`/api/veloedit/${id}`).then((res) => {
      setVelo(res.data.velo);
    });
  };

  const fetchmecano = () => {
    axios.get("/api/mecanolist").then((res) => {
      setMecanos(res.data.mecano);
    });
  };

  const mecanolist = mecanos.map((mecano) => (
    <option value={mecano.prenom} key={mecano.id} className="text-capitalize">
      {mecano.prenom}
    </option>
  ));

  const pieces = [
    { piece: "Fourche", km: 18000 },
    { piece: "Roues", km: 25000 },
    { piece: "Pneus", km: 8000 },
    { piece: "Poignées", km: 20000 },
    { piece: "Manettes", km: 36000 },
    { piece: "Leviers", km: 36000 },
    { piece: "Dérailleur", km: 10000 },
    { piece: "Pédales", km: 28000 },
    { piece: "Boîtier de pédalier", km: 25000 },
    { piece: "cassette", km: 12500 },
    { piece: "Chaîne", km: 7500 },
    { piece: "Plaquette", km: 1500 },
    { piece: "Disque", km: 5400 },
    { piece: "Selle", km: 15000 },
    { piece: "Tige de selle", km: 17500 },
  ];

  const handleInput = (e) => {
    e.persist();
    setInfo({ ...info, [e.target.name]: e.target.value });
  };

  const prevision = (e) => {
    e.preventDefault();
    const data = {
      velo: info.velo,
      terrain: info.terrain,
      distance: info.distance,
    };

    if (
      (data.terrain === "" && data.distance === "") ||
      data.terrain === "" ||
      data.distance === ""
    ) {
      setJour(0);
    } else if (data.terrain === "ville" && data.distance !== "") {
      const dist = Number(data.distance);
      setJour(dist);
    } else if (data.terrain === "raid" && data.distance !== "") {
      const dist = Number(data.distance) + 25;
      setJour(dist);
    }
  };

  const estimation = pieces.map((el) => {
    return (
      <p className="h5" key={el.piece}>
        {el.piece} :{" "}
        {el.piece === "Fourche"
          ? velo?.fourche
          : el.piece === "Roues"
          ? "Jante : " + velo?.jante + " / Rayon : " + velo?.rayon
          : el.piece === "Pneus"
          ? "Avant : " + velo?.pneu_av + " / Arrière : " + velo?.pneu_ar
          : el.piece === "Poignées"
          ? velo?.poigne
          : el.piece === "Manettes"
          ? velo?.manette
          : el.piece === "Dérailleur"
          ? velo?.derailleur_ar
          : el.piece === "Pédales"
          ? velo?.pedale
          : el.piece === "Boîtier de pédalier"
          ? velo?.boitier_pedalier
          : el.piece === "cassette"
          ? velo?.cassette
          : el.piece === "Chaîne"
          ? velo?.chaine
          : el.piece === "Selle"
          ? velo?.selle
          : el.piece === "Tige de selle"
          ? velo?.tige_selle
          : el.piece === "Leviers"
          ? velo?.frein
          : el.piece === "Plaquette"
          ? velo?.frein
          : el.piece === "Disque"
          ? velo?.frein
          : null}{" "}
        &nbsp;dans&nbsp;{" "}
        <span className="fw-bold">
          {jour === 0 ? 0 : Math.round(el.km / jour)} j
        </span>
      </p>
    );
  });

  const submit = (e) => {
    swal("Chargement....", "Traitement de la demande", "info");
    e.preventDefault();
    var date = document.getElementById("date").value;
    var mecano = document.getElementById("mecano").value;
    var intervention = document.getElementById("intervention").value;
    var adresse = "Antsahavola";
    var pbm = "Réparation prévisionnelle";
    const data = {
      velo_id: veloId?.value,
      date: date,
      date_pem: date,
      intervention: intervention,
      pbm_constates: pbm,
      adresse_domicile: adresse,
      mecano: mecano,
      maintenance: maintenances,
      count_m: count_m,
      piece: pies,
      count_p: count_p,
      count: count,
    };
    axios.post(`/api/prevision`, data).then((res) => {
      if (res.data.status === 200) {
        swal("Success", res.data.message, "success");
      }
    });
  };

  return (
    <div className="container mt-3">
      <div className="card">
        <div className="card-header">
          <p className="h4 text-center">
            Calcul prévisionnel de réparation des pièces de vélo
          </p>
        </div>
        <div className="card-body">
          <div className="m-auto">
            <form className="form" onSubmit={prevision}>
              <div className="row">
                <div className="col-md-6 form-group">
                  <div className="mb-3 form-group d-flex align-items-center justify-content-around">
                    <div className="me-2 w-50">
                      <Select
                        options={clientlist}
                        value={userId}
                        isClearable
                        placeholder="Nom du client"
                        onChange={handleUserId}
                      />
                    </div>
                    <div className="ms-2 w-50">
                      <Select
                        options={velolist}
                        value={veloId}
                        isClearable
                        placeholder="Vélo du client"
                        onChange={handleVeloId}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2 form-group mb-2">
                  <select
                    name="terrain"
                    onChange={handleInput}
                    className="form-select"
                  >
                    <option value="">Type de terrain</option>
                    <option value="ville">Ville</option>
                    <option value="raid">Raid</option>
                  </select>
                </div>
                <div className="col-md-2 form-group mb-2">
                  <input
                    type="number"
                    min={0}
                    name="distance"
                    onChange={handleInput}
                    className="form-control"
                    placeholder="Distance parcourue"
                  />
                </div>
                <div className="col-md-2 form-group mb-2 text-center">
                  <button
                    className="btn btn-outline-primary rounded-pill border"
                    type="submit"
                  >
                    Estimer
                  </button>
                </div>
              </div>
            </form>
            <div className="mt-2">{estimation}</div>
          </div>
        </div>
      </div>
      <div className="mt-4 card">
        <div className="card-body">
          <form className="form" onSubmit={submit}>
            <div className="col-md-6 row form-group mb-4 m-auto">
              <input type="date" id="date" className="form-control col m-2" />
              <select
                name="mecano"
                id="mecano"
                className="text-capitalize form-select col m-2"
              >
                <option value="" className="text-capitalize">
                  mécanicien
                </option>
                {mecanolist}
              </select>
              <select
                name="mecano"
                id="intervention"
                className="text-capitalize form-select col m-2"
              >
                <option value="entretien préventif" className="text-capitalize">
                  entretien préventif
                </option>
              </select>
            </div>
            <div className="row">
              <div className="col-md-6 mb-2">
                <h6 className="card-title text-center text-capitalize">
                  maintenances&nbsp;
                  <FontAwesomeIcon
                    className="btn btn-secondary fw-bold"
                    icon={faPlus}
                    onClick={addMaintenances}
                  />
                </h6>
                {maintenances.map((maintenance, i) => (
                  <div
                    key={i}
                    className="d-flex justify-content-between mb-3 m-2"
                  >
                    <input
                      type="text"
                      className="form-control"
                      name="designation"
                      onChange={(e) => handleMaintenances(i, e)}
                      value={maintenance.designation}
                      placeholder="Désignation"
                    />
                    <input
                      type="number"
                      min="0"
                      className="form-control mx-2"
                      name="quantite"
                      onChange={(e) => handleMaintenances(i, e)}
                      value={maintenance.quantite}
                      placeholder="Quantité"
                    />
                    <input
                      type="number"
                      min="0"
                      className="form-control me-2"
                      name="prix"
                      onChange={(e) => handleMaintenances(i, e)}
                      value={maintenance.prix}
                      placeholder="Prix Unitaire"
                    />
                    <FontAwesomeIcon
                      className="btn btn-danger fw-bold"
                      icon={faMinus}
                      onClick={() => removeMaintenances(i)}
                    />
                  </div>
                ))}
                {count_m ? (
                  <p className="h6 fw-bold text-end">
                    Sous-total main d'œuvre :{" "}
                    {count_m.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                    Ar
                  </p>
                ) : null}
              </div>
              <div className="col-md-6 mb-2">
                <h6 className="card-title text-center text-capitalize">
                  pièces&nbsp;
                  <FontAwesomeIcon
                    className="btn btn-secondary fw-bold"
                    icon={faPlus}
                    onClick={addPieces}
                  />
                </h6>
                {pies.map((piece, i) => (
                  <div key={i} className="d-flex justify-content-between mb-3">
                    <input
                      type="text"
                      className="form-control"
                      name="designation"
                      onChange={(e) => handlePieces(i, e)}
                      value={piece.designation}
                      placeholder="Désignation"
                    />
                    <input
                      type="number"
                      min="0"
                      className="form-control mx-2"
                      name="quantite"
                      onChange={(e) => handlePieces(i, e)}
                      value={piece.quantite}
                      placeholder="Quantité"
                    />
                    <input
                      type="number"
                      min="0"
                      className="form-control me-2"
                      name="prix"
                      onChange={(e) => handlePieces(i, e)}
                      value={piece.prix}
                      placeholder="Prix Unitaire"
                    />
                    <FontAwesomeIcon
                      className="btn btn-danger fw-bold"
                      icon={faMinus}
                      onClick={() => removePieces(i)}
                    />
                  </div>
                ))}
                {count_p ? (
                  <p className="h6 fw-bold text-end">
                    Sous-total pièce :{" "}
                    {count_p.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                    Ar
                  </p>
                ) : null}
              </div>
              <p className="fw-bold text-center mt-2">
                <span className="h4">Total :</span>
                <span className="h4">
                  {" "}
                  {count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ar
                </span>
              </p>
            </div>
            <div className="text-center form-group mb-2">
              <button
                type="submit"
                className="btn btn-outline-success rounded-pill text-capitalize"
              >
                valider
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Prevision;

import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import Footer from './Footer'
import '../../assets/admin/css/styles.css'
import '../../assets/admin/js/scripts'
import routes from '../../routes/routes'

function Master() {
  return (
    <div className='sb-nav-fixed'>
      <Navbar />
      <div id='layoutSidenav'>
        <div id='layoutSidenav_nav'>
            <Sidebar />
        </div>
        <div id='layoutSidenav_content'>
            <main>
                <Switch>
                  {routes.map((route, i) => {
                    return (
                      route.component && (
                        <Route 
                          key={i}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={(props) => (
                            <route.component {...props} />
                          )}
                        />
                      )
                    )
                  })}
                  <Redirect from="/admin101" to="/admin101/dashboard" />
                </Switch>
            </main>
            <Footer />
        </div>
      </div>
    </div>
  )
}

export default Master

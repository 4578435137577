import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Navbar from './Navbar'
import Footer from './Footer'
import clients from '../../routes/clients'
import AccountRoute from '../../AccountRoute'
import Login from '../../components/client/auth/Login'
import SignUp from '../../components/client/auth/SignUp'
import Forgotpwd from '../../components/client/auth/Forgotpwd'
import '../../assets/client/assets/css/style.css'

function Master() {
  return (
    <div className='main'>
      <Navbar />
        <div className='content'>
            <Switch>
              <Route path="/login">
                {localStorage.getItem('XSRF-TOKEN=eyJpdiI6IlRJb21lQUZHV21aN1RIUDRvaHF0cVE9PSIsInZhbHVlIjoiSzNyQ0M5WUZvQ3B3RFhNZk9Pd3p6UVZ5S2k0bk9tcUNuWnVGVis0ZkhGSHJ1TFp4TXBsTXF6U0FaME9mV0hrOFlUWjVZdGtJQnAxZ0RhaGhBR2NGa2xZRTJnWDVIYXVOVlU5TjlOeFpWTFEzVTdacnozeE9lNjNkRkU1cTEwcGQiLCJtYWMiOiI1ZjRjOTEyZjczOGRmNDc1NGIxZDA0YTM1YWVlMzU1YzhjYTMxNWIxZWJjMGFmNTY5NzcxMzVlNGU0YTg5YTAzIiwidGFnIjoiIn0%3D') ? <Redirect to='/' /> : <Login/>}
              </Route>
              <Route path="/signup">
                {localStorage.getItem('XSRF-TOKEN=eyJpdiI6IlRJb21lQUZHV21aN1RIUDRvaHF0cVE9PSIsInZhbHVlIjoiSzNyQ0M5WUZvQ3B3RFhNZk9Pd3p6UVZ5S2k0bk9tcUNuWnVGVis0ZkhGSHJ1TFp4TXBsTXF6U0FaME9mV0hrOFlUWjVZdGtJQnAxZ0RhaGhBR2NGa2xZRTJnWDVIYXVOVlU5TjlOeFpWTFEzVTdacnozeE9lNjNkRkU1cTEwcGQiLCJtYWMiOiI1ZjRjOTEyZjczOGRmNDc1NGIxZDA0YTM1YWVlMzU1YzhjYTMxNWIxZWJjMGFmNTY5NzcxMzVlNGU0YTg5YTAzIiwidGFnIjoiIn0%3D') ? <Redirect to='/' /> : <SignUp/>}
              </Route>
              <Route path="/forgotpassword">
                {localStorage.getItem('XSRF-TOKEN=eyJpdiI6IlRJb21lQUZHV21aN1RIUDRvaHF0cVE9PSIsInZhbHVlIjoiSzNyQ0M5WUZvQ3B3RFhNZk9Pd3p6UVZ5S2k0bk9tcUNuWnVGVis0ZkhGSHJ1TFp4TXBsTXF6U0FaME9mV0hrOFlUWjVZdGtJQnAxZ0RhaGhBR2NGa2xZRTJnWDVIYXVOVlU5TjlOeFpWTFEzVTdacnozeE9lNjNkRkU1cTEwcGQiLCJtYWMiOiI1ZjRjOTEyZjczOGRmNDc1NGIxZDA0YTM1YWVlMzU1YzhjYTMxNWIxZWJjMGFmNTY5NzcxMzVlNGU0YTg5YTAzIiwidGFnIjoiIn0%3D') ? <Redirect to='/' /> : <Forgotpwd/>}
              </Route>
                {clients.map ((client, i) => {
                    return (
                        client.component && (
                            <Route 
                                key={i}
                                path={client.path}
                                exact={client.exact}
                                name={client.name}
                                render={(props) => (
                                    <client.component {...props} />
                                )}
                            />
                        )
                    )
                })}
                <AccountRoute path="/account" name="account" />
            </Switch>
        </div>
      <Footer />
    </div>
  )
}


export default Master

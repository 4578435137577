import axios from 'axios'
import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'

function VeloAdd() {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [velo, setVelo] = useState({
    user_id:'',
    type:'',
    marque:'',
    modele:'',
    taille:'',
    cadre:'',
    fourche:'',
    boitier_pedalier:'',
    pedalier:'',
    pedale:'',
    chaine:'',
    cassette:'',
    amortisseur:'',
    derailleur_av:'',
    derailleur_ar:'',
    manette:'',
    frein:'',
    jeu_direction:'',
    potence:'',
    tige_selle:'',
    selle:'',
    collier_selle:'',
    poigne:'',
    moyeu_av:'',
    moyeu_ar:'',
    jante:'',
    rayon:'',
    pneu_av:'',
    pneu_ar:'',
    moteur:'',
    batterie:'',
    chargeur:'',
    ecran:'',
    couleur:'',
    error:[],
  })

  useEffect(() => {
    fetchdata()
  }, [])

  const fetchdata = () => {
    axios.get('/api/clientlistprevision').then(res => {
      setUsers(res.data.client)
    })
  }

  const clientlist = users.map(user => 
    <option className='text-capitalize' key={user.id} value={user.id}>{user.nom} {user.prenom}</option>
    )

  const handleInput = (e) => {
    e.persist()
    setVelo({...velo, [e.target.name]: e.target.value })
  }

  const veloSubmit = (e) => {
    setLoading(true)
    e.preventDefault()
    const data = {
      user_id: velo.user_id,
      type: velo.type,
      marque: velo.marque,
      modele: velo.modele,
      taille: velo.taille,
      cadre: velo.cadre,
      amortisseur: velo.amortisseur,
      fourche: velo.fourche,
      boitier_pedalier: velo.boitier_pedalier,
      pedalier: velo.pedalier,
      pedale: velo.pedale,
      chaine: velo.chaine,
      cassette: velo.cassette,
      derailleur_av: velo.derailleur_av,
      derailleur_ar: velo.derailleur_ar,
      manette: velo.manette,
      frein: velo.frein,
      jeu_direction: velo.jeu_direction,
      potence: velo.potence,
      tige_selle: velo.tige_selle,
      selle: velo.selle,
      collier_selle: velo.collier_selle,
      poigne: velo.poigne,
      moyeu_av: velo.moyeu_av,
      moyeu_ar: velo.moyeu_ar,
      jante: velo.jante,
      rayon: velo.rayon,
      pneu_av: velo.pneu_av,
      pneu_ar: velo.pneu_ar,
      moteur: velo.moteur,
      batterie: velo.batterie,
      chargeur: velo.chargeur,
      ecran: velo.ecran,
      couleur: velo.couleur,
    }
    axios.post(`/api/veloadd`, data).then(res => {
      if(res.data.status === 200){
        setLoading(false)
        history.push('/admin101/velo')
      } else {
        setVelo({...velo, error: res.data.errors})
        setLoading(false)
      }
    })
  }

  if(loading){
    return (
      <div className="d-flex justify-content-center container py-5">
        <div className="spinner-border text-success my-5" role='status'></div>
      </div>
    )
  }
  
  return (
    <div className='container mt-5'>
      <div className="card">
        <div className="card-header text-capitalize h4">ajout vélo</div>
        <div className="card-body">
          <form className='form-group' onSubmit={veloSubmit}>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <select name="user_id" className="form-select text-capitalize" value={velo.user_id} onChange={handleInput}>
                  <option value="" className='text-capitalize'>propriétaire</option>
                  {clientlist}
                </select>
                <span className="text-danger">{velo.error.user_id}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='type' className="form-control" placeholder="Type" onChange={handleInput} value={velo.type} />
                <span className="text-danger">{velo.error.type}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='marque' className="form-control" placeholder="Marque" onChange={handleInput} value={velo.marque} />
                <span className="text-danger">{velo.error.marque}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='modele' className="form-control" placeholder="Modele" onChange={handleInput} value={velo.modele} />
                <span className="text-danger">{velo.error.modele}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='taille' className="form-control" placeholder="Taille" onChange={handleInput} value={velo.taille} />
                <span className="text-danger">{velo.error.taille}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='couleur' className="form-control" placeholder="Couleur" onChange={handleInput} value={velo.couleur} />
                <span className="text-danger">{velo.error.couleur}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='cadre' className="form-control" placeholder="Cadre" onChange={handleInput} value={velo.cadre} />
                <span className="text-danger">{velo.error.cadre}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='amortisseur' className="form-control" placeholder="Amortisseur" onChange={handleInput} value={velo.amortisseur} />
                <span className="text-danger">{velo.error.amortisseur}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='fourche' className="form-control" placeholder="Fourche" onChange={handleInput} value={velo.fourche} />
                <span className="text-danger">{velo.error.fourche}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='boitier_pedalier' className="form-control" placeholder="Boîtier pédalier" onChange={handleInput} value={velo.boitier_pedalier} />
                <span className="text-danger">{velo.error.boitier_pedalier}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='pedalier' className="form-control" placeholder="Pédalier" onChange={handleInput} value={velo.pedalier} />
                <span className="text-danger">{velo.error.pedalier}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='pedale' className="form-control" placeholder="Pédale" onChange={handleInput} value={velo.pedale} />
                <span className="text-danger">{velo.error.pedale}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='chaine' className="form-control" placeholder="Chaine" onChange={handleInput} value={velo.chaine} />
                <span className="text-danger">{velo.error.chaine}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='cassette' className="form-control" placeholder="Cassette" onChange={handleInput} value={velo.cassette} />
                <span className="text-danger">{velo.error.cassette}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='derailleur_av' className="form-control" placeholder="Dérailleur avant" onChange={handleInput} value={velo.derailleur_av} />
                <span className="text-danger">{velo.error.derailleur_av}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='derailleur_ar' className="form-control" placeholder="Dérailleur arrière" onChange={handleInput} value={velo.derailleur_ar} />
                <span className="text-danger">{velo.error.derailleur_ar}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='manette' className="form-control" placeholder="Manette" onChange={handleInput} value={velo.manette} />
                <span className="text-danger">{velo.error.manette}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='frein' className="form-control" placeholder="Frein" onChange={handleInput} value={velo.frein} />
                <span className="text-danger">{velo.error.frein}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='jeu_direction' className="form-control" placeholder="Jeu de direction" onChange={handleInput} value={velo.jeu_direction} />
                <span className="text-danger">{velo.error.jeu_direction}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='potence' className="form-control" placeholder="Potence" onChange={handleInput} value={velo.potence} />
                <span className="text-danger">{velo.error.potence}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='tige_selle' className="form-control" placeholder="Tige de selle" onChange={handleInput} value={velo.tige_selle} />
                <span className="text-danger">{velo.error.tige_selle}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='selle' className="form-control" placeholder="Selle" onChange={handleInput} value={velo.selle} />
                <span className="text-danger">{velo.error.selle}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='collier_selle' className="form-control" placeholder="Collier de selle" onChange={handleInput} value={velo.collier_selle} />
                <span className="text-danger">{velo.error.collier_selle}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='poigne' className="form-control" placeholder="Poignés" onChange={handleInput} value={velo.poigne} />
                <span className="text-danger">{velo.error.poigne}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='moyeu_av' className="form-control" placeholder="Moyeu avant" onChange={handleInput} value={velo.moyeu_av} />
                <span className="text-danger">{velo.error.moyeu_av}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='moyeu_ar' className="form-control" placeholder="Moyeu arrière" onChange={handleInput} value={velo.moyeu_ar} />
                <span className="text-danger">{velo.error.moyeu_ar}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='jante' className="form-control" placeholder="Jante" onChange={handleInput} value={velo.jante} />
                <span className="text-danger">{velo.error.jante}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='rayon' className="form-control" placeholder="Rayon" onChange={handleInput} value={velo.rayon} />
                <span className="text-danger">{velo.error.rayon}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='pneu_av' className="form-control" placeholder="Pneu avant" onChange={handleInput} value={velo.pneu_av} />
                <span className="text-danger">{velo.error.pneu_av}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='pneu_ar' className="form-control" placeholder="Pneu arrière" onChange={handleInput} value={velo.pneu_ar} />
                <span className="text-danger">{velo.error.pneu_ar}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='moteur' className="form-control" placeholder="Moteur" onChange={handleInput} value={velo.moteur} />
                <span className="text-danger">{velo.error.moteur}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='batterie' className="form-control" placeholder="Battérie" onChange={handleInput} value={velo.batterie} />
                <span className="text-danger">{velo.error.batterie}</span>
              </div>
            </div>
            <div className="row mb-3 form-group">
              <div className="col-md form-group">
                <input type="text" name='chargeur' className="form-control" placeholder="Chargeur" onChange={handleInput} value={velo.chargeur} />
                <span className="text-danger">{velo.error.chargeur}</span>
              </div>
              <div className="col-md form-group">
                <input type="text" name='ecran' className="form-control" placeholder="Ecran" onChange={handleInput} value={velo.ecran} />
                <span className="text-danger">{velo.error.ecran}</span>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-auto">
                <button type='submit' className="border rounded-pill text-capitalize btn btn-outline-primary">ajouter</button>
              </div>
              <div className="col-auto">
                <Link to='/admin101/velo' className='text-capitalize btn btn-outline-secondary border rounded-pill'>retour</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default VeloAdd

import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faNewspaper, faSort } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ReservationDetails from "./ReservationDetails";
import Pagination from "../Pagination";

function Reservation() {
  const [reservations, setReservations] = useState([]);
  const [show, setShow] = useState(false);
  const [tempdata, setTempdata] = useState([]);
  const [order, setOrder] = useState("asc");
  const [query, setQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const sort = (col) => {
    if (order === "asc") {
      const sorted = [...reservations].sort((a, b) =>
        a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
      );
      setReservations(sorted);
      setOrder("dsc");
    }
    if (order === "dsc") {
      const sorted = [...reservations].sort((a, b) =>
        a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
      );
      setReservations(sorted);
      setOrder("asc");
    }
  };

  const click = (detail) => {
    setTempdata(detail);
    setShow(true);
  };

  useEffect(() => {
    fetchdata(currentPage);
  }, [currentPage]);

  const fetchdata = (page) => {
    axios.get(`/api/reservationlist?page=${page}`).then((res) => {
      setReservations(res.data.reservation.data);
      setTotalPage(res.data.reservation.last_page)
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }


  return (
    <div className="container mt-5">
      <div className="d-flex flex-row-reverse">
        <Link to="/admin101/reservationadd" className="btn btn-primary">
          Ajouter réservation
        </Link>
      </div>
      <div className='mt-3 d-flex align-items-center justify-content-center'>
        <Pagination currentPage={currentPage} totalPage={totalPage} onPageChange={handlePageChange} />
      </div>
      <div className="mt-4">
        <div className="card">
          <div className="card-header text-capitalize">
            <p className="h4 text-center">liste des réservations</p>
          </div>
          <div className="card-body">
            <div className="col-md-6 mb-3 m-auto">
              <input
                type="search"
                name="search"
                className="form-control rounded"
                placeholder="Search"
                onChange={(e) => setQuery(e.target.value)}
              />
            </div>
            <table className="table table-bordered table-striped">
              <thead>
                <tr className="text-capitalize text-center">
                  <th>
                    <span className="btn fw-bold pe-none">client</span>
                  </th>
                  <th>
                    <span className="btn fw-bold pe-none">vélo</span>
                  </th>
                  <th onClick={() => sort("date")}>
                    <span className="btn fw-bold">
                      date <FontAwesomeIcon icon={faSort} />
                    </span>
                  </th>
                  <th onClick={() => sort("etat")}>
                    <span className="btn fw-bold">
                      état <FontAwesomeIcon icon={faSort} />
                    </span>
                  </th>
                  <th>
                    <span className="btn fw-bold pe-none">détails</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {reservations
                  ? reservations
                      .filter(
                        (el) =>
                          el.velo.user.nom.toLowerCase().includes(query) ||
                          el.velo.user.prenom.toLowerCase().includes(query) ||
                          el.velo.marque.toLowerCase().includes(query) ||
                          el.velo.modele.toLowerCase().includes(query)
                      )
                      .map((reservation) => (
                        <tr key={reservation.id} className="text-capitalize">
                          <td className="text-center">
                            {reservation.velo.user.nom}{" "}
                            {reservation.velo.user.prenom}
                          </td>
                          <td className="text-center">
                            {reservation.velo.marque} {reservation.velo.modele}
                          </td>
                          <td className="text-center">{reservation.date}</td>
                          <td className="text-center">
                            {reservation.etat === "a traiter" ? (
                              <span className="badge rounded-pill p-2 text-bg-warning">
                                {reservation.etat}
                              </span>
                            ) : reservation.etat === "en cours" ? (
                              <span className="badge rounded-pill p-2 text-bg-primary">
                                {reservation.etat}
                              </span>
                            ) : reservation.etat === "réalisé" ? (
                              <span className="badge rounded-pill p-2 text-bg-success">
                                {reservation.etat}
                              </span>
                            ) : reservation.etat === "en attente" ? (
                              <span className="badge rounded-pill p-2 text-bg-danger">
                                {reservation.etat}
                              </span>
                            ) : reservation.etat === "annulé" ? (
                              <span className="badge rounded-pill p-2 text-bg-danger">
                                {reservation.etat}
                              </span>
                            ) : null}
                          </td>
                          <td className="text-center">
                            <FontAwesomeIcon
                              icon={faNewspaper}
                              className="btn btn-info"
                              onClick={() => click(reservation)}
                            />
                          </td>
                        </tr>
                      ))
                  : null}
              </tbody>
            </table>
          </div>
        </div>
        <ReservationDetails
          show={show}
          detail={tempdata}
          onClose={() => setShow(false)}
        />
      </div>
    </div>
  );
}

export default Reservation;

import React from "react"
const Pagination = ({ currentPage, totalPage, onPageChange }) => {
    const maxPageToShow = 15
    const pages = []
    let startPage = Math.max(1, currentPage - Math.floor(maxPageToShow / 2))
    let endPage = Math.min(totalPage, startPage + maxPageToShow - 1)
    
    if (totalPage > maxPageToShow) {
      if (endPage - startPage < maxPageToShow - 1) {
        startPage = Math.max(1, endPage - maxPageToShow + 1);
      }
      if (startPage > 1) {
          pages.push(1);
          if (startPage > 2) {
              pages.push('...');
          }
      }
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i)      
    }

    if (totalPage > maxPageToShow) {
      if (endPage < totalPage) {
          if (endPage < totalPage - 1) {
              pages.push('...');
          }
          pages.push(totalPage);
      }
    }

    return (
      <nav>
        <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => onPageChange(currentPage - 1)}>Précédent</button>
            </li>
            {pages.map((page, index) => (
                <li key={index} className={`page-item ${page === '...' ? 'disabled' : ''}`}>
                    {page === '...' ? (
                        <span className="page-link">...</span>
                    ) : (
                        <button className={`page-link ${page === currentPage ? 'active' : ''}`} onClick={() => onPageChange(page)}>{page}</button>
                    )}
                </li>
            ))}
            <li className={`page-item ${currentPage === totalPage ? 'disabled' : ''}`}>
                <button className="page-link" onClick={() => onPageChange(currentPage + 1)}>Suivant</button>
            </li>
        </ul>
      </nav>
    );
  }

export default Pagination
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faPen } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function Employe() {
  const [employes, setEmployes] = useState([])

  useEffect(() => {
    fetchdata()
  }, [])

  const fetchdata = () => {
    axios.get('/api/employelist').then(res => {
      setEmployes(res.data.employe)
    })
  }

  const employedelete = (id) => {
    axios.delete(`/api/employedelete/${id}`).then(res => {
      fetchdata()
    })
  }

  // const employelist = employes.map(employe =>
  //   <tr key={employe.id} className='text-center' >
  //   <td className='text-uppercase fw-bold' >{employe.nom}</td>
  //   <td className='text-capitalize' >{employe.prenom}</td>
  //   <td className='text-lowercase' >{employe.email}</td>
  //   <td className='text-lowercase' >{employe.role}</td>
  //   <td className='d-flex justify-content-around' >
  //     <Link to={`/admin101/employeedit/${employe.id}`} ><FontAwesomeIcon icon={faPen} className='btn btn-warning' /></Link>
  //     <span><FontAwesomeIcon icon={faTrashCan} className='btn btn-danger' onClick={() => employedelete(employe.id)} /></span>
  //   </td>
  // </tr>
  // )

  return (
    <div className='container mt-5'>
    <div className='d-flex flex-row-reverse'>
      <Link to='/admin101/employeadd' className='btn btn-primary'>Ajouter employé</Link>
    </div>
      <div className="mt-4">
        <div className="card">
          <div className="card-header text-capitalize">
            <p className='h4 text-center'>liste des employés</p>
          </div>
          <div className="card-body">
            <table className="table table-bordered table-striped">
              <thead>
                <tr className="text-capitalize text-center">
                  <th><span className='btn fw-bold pe-none'>nom</span></th>
                  <th><span className='btn fw-bold pe-none'>prénom</span></th>
                  <th><span className='btn fw-bold pe-none'>email</span></th>
                  <th><span className='btn fw-bold pe-none'>role</span></th>
                  <th><span className='btn fw-bold pe-none'>actions</span></th>
                </tr>
              </thead>
              <tbody>
                {employes ? employes.map(employe =>
                  <tr key={employe.id} className={employe.email === "admin@admin.mg" ? 'd-none' : 'text-center' } >
                    <td className='text-uppercase fw-bold' >{employe.nom}</td>
                    <td className='text-capitalize' >{employe.prenom}</td>
                    <td className='text-lowercase' >{employe.email}</td>
                    <td className='text-lowercase' >{employe.role}</td>
                    <td className='d-flex justify-content-around' >
                      <Link to={`/admin101/employeedit/${employe.id}`} ><FontAwesomeIcon icon={faPen} className='btn btn-warning' /></Link>
                      <span><FontAwesomeIcon icon={faTrashCan} className='btn btn-danger' onClick={() => employedelete(employe.id)} /></span>
                    </td>
                  </tr>) : null
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Employe

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Pagination from '../Pagination'

function Client() {
  const [clients, setClients] = useState([])
  const [query, setQuery] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  useEffect(() => {
    fetchdata(currentPage)
  }, [currentPage])

  const fetchdata = (page) => {
    axios.get(`/api/clientlist?page=${page}`).then(res => {
      setClients(res.data.client.data)
      setTotalPage(res.data.client.last_page)
    })
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const deleteclient = (id) => {
    axios.delete(`/api/clientdelete/${id}`)
    fetchdata()
  }

  return (
    <div className='container mt-5'>
      <div className='d-flex flex-row-reverse'>
        <Link to='/admin101/clientadd' className='btn btn-primary'>Ajouter client</Link>
      </div>
      <div className='mt-4 d-flex align-items-center justify-content-center'>
        <Pagination currentPage={currentPage} totalPage={totalPage} onPageChange={handlePageChange} />
      </div>
      <div className="mt-4">
        <div className="card">
          <div className="card-header text-capitalize">
            <p className='h4 text-center'>liste des clients</p>
          </div>
          <div className="card-body">
            <div className='col-md-6 mb-3 m-auto'>
              <input type="search" name="search" className='form-control rounded' placeholder='Search' onChange={e => setQuery(e.target.value)} />
            </div>
            <table className="table table-bordered table-striped">
              <thead>
                <tr className="text-capitalize text-center">
                  <th><span className='btn fw-bold pe-none'>nom</span></th>
                  <th><span className='btn fw-bold pe-none'>prénom</span></th>
                  <th><span className='btn fw-bold pe-none'>email</span></th>
                  <th><span className='btn fw-bold pe-none'>phone</span></th>
                  <th><span className='btn fw-bold pe-none'>actions</span></th>
                </tr>
              </thead>
              <tbody>
                {clients ? clients.filter(el => el.nom.toLowerCase().includes(query) || el.prenom.toLowerCase().includes(query) || el.email.toLowerCase().includes(query)).map((client) => 
                  <tr key={client.id} className='text-center'>
                    <td className='text-uppercase'>{client.nom}</td>
                    <td className='text-capitalize'>{client.prenom}</td>
                    <td id='test'>{client.email}</td>
                    <td>{client.phone}</td>
                    <td className='d-flex justify-content-around'>
                      <Link to={`/admin101/clientedit/${client.id}`}><FontAwesomeIcon icon={faPen} className='btn btn-warning' /></Link>
                      <FontAwesomeIcon icon={faTrash} className='btn btn-danger' onClick={() => deleteclient(client.id)}/>
                    </td>
                  </tr>) : null
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Client

import React from "react";
import { Redirect, Route } from "react-router-dom";
import Master from "./layouts/admin/Master";

function AdminRoute({ ...res }) {
  return (
    <Route
      {...res}
      render={({ props, location }) =>
        localStorage.getItem(
          "XSRF-TOKEN=eyJpdiI6IjRQbHIwWGkyOVhSRVZxWWw5V3doY1E9PSIsInZhbHVlIjoicWovMHF5OTdCRDVRblo3ZGlRWVUxTEgvaXNFUlpUSXJnS0dhWmVzelhwODdwbWN4THZFVVpOaTlrdkdXWTVaK3BXSWZTb21UV3lxWHFhTEJiTmdKeUZqVVNHTTZuY21CMEd5b203UklQZURhYVJKeGdxQzJ2MTBNbkxvdDZlNmYiLCJtYWMiOiJiMjIxNjlmYzc1Yjg4MDdmMDlmNTA5MDYxOTQ5NTBiNDNkN2Q5YTMzYjdkODQ4NzA5MzZmMWFlMjQ3OWZlMzZlIiwidGFnIjoiIn0%3D"
        ) ? (
          <Master {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/adminlogin", state: { from: location } }}
          />
        )
      }
    />
  );
}

export default AdminRoute;

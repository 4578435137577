import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import ReparationAdd from './ReparationAdd'

function Reparation() {
  const [test, setTest] =useState(false)
  const [reparations, setReparations] = useState([])

  useEffect(() => {
    fetchdata()
    setTest(false)
  }, [test])

  const fetchdata = () => {
    axios.get('/api/reparationlist').then(res => {
      setReparations(res.data.reparation)
    })
  }

  const reparationdelete = (id) => {
    axios.delete(`/api/reparationdelete/${id}`).then(res => {
      fetchdata()
    })
  }

  // const reparationlist = reparations.map(reparation => 
  //   <tr key={reparation.id} className='text-left' >
  //     <td className='text-capitalize'>{reparation.nom}</td>
  //     <td className='d-flex justify-content-center'>
  //       <span><FontAwesomeIcon icon={faTrashCan} className='btn btn-danger' onClick={() => reparationdelete(reparation.id)} /></span>
  //     </td>
  //   </tr>
  //   )

  return (
    <div className='container mt-5'>
      <div className='d-flex flex-row-reverse'>
        <ReparationAdd setTest={setTest} />
      </div>
      <div className="mt-4">
        <div className="card">
          <div className="card-header text-capitalize">
            <p className="h4 text-center">liste des réparations</p>
          </div>
          <div className="card-body">
            <table className='table table-bordered table-striped'>
              <thead>
                <tr className="text-capitalize text-center">
                  <th><span className='btn fw-bold pe-none'>titre</span></th>
                  <th><span className='btn fw-bold pe-none'>actions</span></th>
                </tr>
              </thead>
              <tbody>
                {reparations ? reparations.map(reparation => 
                  <tr key={reparation.id} className='text-left' >
                    <td className='text-capitalize'>{reparation.nom}</td>
                    <td className='d-flex justify-content-center'>
                      <span><FontAwesomeIcon icon={faTrashCan} className='btn btn-danger' onClick={() => reparationdelete(reparation.id)} /></span>
                    </td>
                  </tr>) : null
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reparation

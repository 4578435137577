import React from 'react'
import info from '../../../assets/client/img/4.jpg'
import reservation from '../../../assets/client/img/2.png'
import velo from '../../../assets/client/img/3.jpg'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAlt, faCalendarAlt, faBicycle } from '@fortawesome/free-solid-svg-icons'

function Dashboard() {

  return (
    <div className='container mx-auto mt-5 mb-5'>
      <div className="d-flex justify-content-evenly row">
        <div className="text-center col-lg col-md-auto mb-5">
          <img src={velo} alt='vélo' className="rounded-circle mx-auto py-3" width="70%" />
          <Link to={`/account/listevelo/${localStorage.getItem('auth_id')}.${localStorage.getItem('auth_name')}`} className='text-capitalize nav-link'><br /><FontAwesomeIcon icon={faBicycle} size="2x" /> <br /> mes vélos</Link>
        </div>
        <div className="text-center col-lg col-md-auto mb-5">
          <img src={info} alt='profile' className="rounded-circle mx-auto py-3" width="70%" />
          <Link to={`/account/profile/${localStorage.getItem('auth_id')}.${localStorage.getItem('auth_name')}`} className='text-capitalize nav-link'><br /><FontAwesomeIcon icon={faUserAlt} size="2x" /> <br /> mes informations</Link>
        </div>
        <div className="text-center col-lg col-md-auto mb-5">
          <img src={reservation} alt='réservation' className="rounded-circle mx-auto py-3" width="70%" />
          <Link to={`/account/listerendezvous/${localStorage.getItem('auth_id')}.${localStorage.getItem('auth_name')}`} className='text-capitalize nav-link'><br /><FontAwesomeIcon icon={faCalendarAlt} size="2x" /> <br /> mes réservations</Link>
        </div>
      </div>
      <div className="text-center text-capitalize mt-5">
        <Link to='/account/addrendezvous' className='nav-link'>réserver réparation</Link>
      </div>
    </div>
  )
}

export default Dashboard

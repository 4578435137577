import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import swal from 'sweetalert'

function Login() {
  const history = useHistory()
  const [loading, setLoading] = useState()
  const [loginInput, setLoginInput] = useState({
    email:'',
    password:'',
    error: [],
  })

  const handleInput = (e) => {
    e.persist()
    setLoginInput({...loginInput, [e.target.name]: e.target.value })
  }

  const loginSubmit = (e) => {
    e.preventDefault()
    const data = {
      email: loginInput.email,
      password: loginInput.password,
    }
    
    axios.get('/sanctum/csrf-cookie').then(() => {
      setLoading(true)
      axios.post(`/api/login`, data).then(res => {
        if (res.data.status === 200) {
          localStorage.setItem('XSRF-TOKEN=eyJpdiI6IlRJb21lQUZHV21aN1RIUDRvaHF0cVE9PSIsInZhbHVlIjoiSzNyQ0M5WUZvQ3B3RFhNZk9Pd3p6UVZ5S2k0bk9tcUNuWnVGVis0ZkhGSHJ1TFp4TXBsTXF6U0FaME9mV0hrOFlUWjVZdGtJQnAxZ0RhaGhBR2NGa2xZRTJnWDVIYXVOVlU5TjlOeFpWTFEzVTdacnozeE9lNjNkRkU1cTEwcGQiLCJtYWMiOiI1ZjRjOTEyZjczOGRmNDc1NGIxZDA0YTM1YWVlMzU1YzhjYTMxNWIxZWJjMGFmNTY5NzcxMzVlNGU0YTg5YTAzIiwidGFnIjoiIn0%3D', res.data.token)
          localStorage.setItem('auth_name', res.data.name)
          localStorage.setItem('auth_id', res.data.id)
          swal('Success', res.data.message, 'success')
          history.push('/account/dashboard')
          setLoading(false)
        } else if(res.data.status === 401) {
          swal('Warning', res.data.message, 'warning')
          setLoading(false)
        } else{
          setLoginInput({...loginInput, error: res.data.errors})
          setLoading(false)
        }
      })
    })
  }

  if (loading) {
    return (
      <div className="d-flex justify-content-center container py-5">
        <div className="spinner-border text-success my-5" role="status"></div>
      </div> 
    )
  }

  return (
    <div className='container py-5'>
      <div className='row justify-content-center'>
        <div className='col-md-6'>
          <div className='card'>
            <div className='card-header'>
              <h4 className='text-capitalize text-center'>connexion</h4>
            </div>
            <div className='card-body'>
              <form onSubmit={loginSubmit}>
                <div className='form-group mb-3'>
                  <label className='text-capitalize'>email</label>
                  <input type="text" name="email" className="form-control" onChange={handleInput} value={loginInput.email}  />
                  <span className='text-danger'>{loginInput.error.email}</span>
                </div>
                <div className='form-group mb-3'>
                  <label className='text-capitalize'>mot de passe</label>
                  <input type="password" name="password" className="form-control" onChange={handleInput} value={loginInput.password}  />
                  <span className='text-danger'>{loginInput.error.password}</span>
                </div>
                <div className='form-group mb-3'>
                  <div className='row'>
                    <div className='col text-capitalize small'>
                    <label className='small'><input type="checkbox" name='remember' /><span className='ps-2'>se souvenir de moi</span></label>
                    </div>
                    <div className='col text-end text-capitalize small'>
                      <Link to="/forgotpassword" className='text-decoration-none small'>mot de passe oublié ?</Link>
                    </div>
                  </div>
                </div>
                <div className='form-group text-center'>
                  <button type='submit' className="border rounded-pill text-capitalize btn btn-outline-success">se connecter</button> <br/>
                  <p className="mt-3 text-capitalize">pas encore compte ? <Link to="/signup" className='text-decoration-none text-primary'>inscription</Link></p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login

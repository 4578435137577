import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'

function Tarif() {
  const [loading, setLoading] = useState()
  const [reparations, setReparations] = useState([])
  const [interventions, setInterventions] = useState([])

  useEffect(() => {
    fetchinter()
  }, [])

  const fetchinter = () => {
    setLoading(true)
    axios.get('/api/tarif').then(res => {
      setReparations(res.data.titre)
      setInterventions(res.data.tarif)
      setLoading(false)
    })
  }

  if (loading) {
    return (
      <div className="d-flex justify-content-center container py-5">
        <div className="spinner-border text-success my-5" role="status"></div>
      </div> 
    )
  }

  return (
    <div className="container mt-4 mb-4">
      <h1 className="text-center text-capitalize">nos tarifs</h1>
      <p className="text-center">Les tarifs mentionnés ci-après sont à titre indicatif.</p>
      <div className="mt-3">
          <Tabs defaultActiveKey='1' type="card" className='text-capitalize'>
              {reparations ? reparations.map(reparation => 
                <Tabs.TabPane tab={reparation.nom} key={reparation.id}>
                  <table className="table table-bordered">
                    <thead>
                      <tr className="text-capitalize text-center">
                        <th>désignation</th>
                        <th>montant</th>
                      </tr>
                    </thead>
                    <tbody>
                      {interventions ? interventions.map(intervention => 
                        (reparation.nom === intervention.reparation.nom ?
                          (
                            <tr key={intervention.id}>
                              <td className='text-capitalize'>{intervention.nom}</td>
                              <td className='text-capitalize'>{(intervention.tarif).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ar</td>
                            </tr>) : null
                        )) : null
                      }
                    </tbody>
                  </table>
                </Tabs.TabPane>) : null
              }
          </Tabs>
      </div>
    </div>
  )
}

export default Tarif

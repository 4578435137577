import React from 'react'
import { Link } from 'react-router-dom'
import fond from '../../assets/client/img/1.jpg'

function Home() {
  return (
    <div className='container mx-auto mt-5 mb-5'>
      <div className='row'>
          <div className='col-lg col-md-auto'>
              <p className='text-justify'>Faites la réparation, l'entretien ou le montage de votre vélo avec MBIKE.<br></br><br></br>Notre atelier vélo est à votre disposition pour tous vos réparations et entretiens : purge de freins, révision vélos, montage de ruban de cintre, réglage de votre transmission VTT et Route, vidange de fourche ... .<br></br><br></br>De plus, nos techniciens confirmés sont disponibles pour la préparation, l’entretien et la réparation de vos vélos. N’hésitez pas à nous rendre visite.</p><br></br>
              <p className='text-center'>
              <button className="border rounded-pill text-capitalize btn btn-outline-success"><Link className='nav-link p-1 text-black' to="/account/addvelo" >prendre rendez - vous</Link></button>
              </p>
          </div>
          <div className="col-lg col-md-auto">
              <img src={fond} alt="" className='img-fluid rounded h-100'/>
          </div>
      </div>
    </div>
  )
}

export default Home

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom'

function ClientEdit(props) {
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [client, setClient] = useState({
        nom:'',
        prenom:'',
        email:'',
        phone:'',
        password:'',
        error:[],
    })

    useEffect(() => {
        const id = props.match.params.id
        axios.get(`/api/clientedit/${id}`).then(res => {
            if (res.data.status === 200) {
                setClient(res.data.client)
                setLoading(false)
            } else {
                history.push('/admin101/client')
            }
        })
    }, [props.match.params.id, history])

    const handleInput = (e) => {
        e.persist()
        setClient({...client, [e.target.name]: e.target.value })
    }

    const clientSubmit = (e) => {
      setLoading(true)
        e.preventDefault()
        var pwd = document.getElementById('pwd').value
        const id = props.match.params.id
        var data = {}
        if(pwd.length === 0){
            data = {
                nom: client.nom,
                prenom: client.prenom,
                phone: client.phone,
                email: client.email,
            }
        } else {
            data = {
                nom: client.nom,
                prenom: client.prenom,
                phone: client.phone,
                email: client.email,
                password: client.password,
            }
        }
        axios.put(`/api/clientupdate/${id}`, data).then(res => {
            if(res.data.status === 200){
                history.push('/admin101/client')
            } else {
                setClient({...client, error: res.data.errors})
            }
        })
    }

    if(loading){
      return (
        <div className="d-flex justify-content-center container py-5">
          <div className="spinner-border text-success my-5" role='status'></div>
        </div>
      )
    }
    
  return (
    <div className='container mt-5'>
    <div className='card'>
      <div className='card-header text-capitalize h4'>modification client</div>
      <div className='card-body'>
        <form className='form-group' onSubmit={clientSubmit} id="client_form">
          <div className="row mb-3 form-group">
            <div className="col-md form-group">
              <input type="text" name='nom' className="form-control" placeholder="Nom" onChange={handleInput} value={client.nom} />
            </div>
            <div className="col-md form-group">
              <input type="text" name='prenom' className="form-control" placeholder="Prénom" onChange={handleInput} value={client.prenom} />
            </div>
          </div>
          <div className='mb-3 form-group'>
            <input type="text" name='email' className="form-control" placeholder="Email" onChange={handleInput} value={client.email} />
          </div>
          <div className='mb-3 form-group'>
            <input type="text" name='phone' className="form-control" placeholder="Téléphone" onChange={handleInput} value={client.phone} />
          </div>
          <div className='mb-3 form-group'>
            <input type="password" id='pwd' name='password' className="form-control" placeholder="Mot de passe" onChange={handleInput} value={client.password || ''} />
          </div>
          <div className="row justify-content-center">
            <div className="col-auto">
              <button type='submit' className="border rounded-pill text-capitalize btn btn-outline-primary">modifier</button>
            </div>
            <div className="col-auto">
              <Link to='/admin101/client' className='text-capitalize btn btn-outline-secondary border rounded-pill' >retour</Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  )
}

export default ClientEdit

import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { Calendar } from "react-calendar";
import Select from "react-select";
import Form2 from "./Form2";
import Form3 from "./Form3";

function ReservationAdd() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [form, setForm] = useState("form1");
  const [velos, setVelos] = useState([]);
  const [clients, setClients] = useState([]);
  const [userId, setUserId] = useState(null);
  const [veloId, setVeloId] = useState(null);
  const [date, setDate] = useState(new Date());
  const [reservation, setReservation] = useState({
    velo_id: "",
    date: "",
    intervention: "",
    pbm_constates: "",
    adresse_domicile: "",
    error: [],
  });

  const handleInput = (e) => {
    e.persist();
    setReservation({ ...reservation, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = () => {
    axios.get("/api/clientlistprevision").then((res) => {
      setClients(res.data.client);
    });
    axios.get("/api/velolistprevision").then((res) => {
      setVelos(res.data.velo);
    });
  };

  const clientlist = clients.map((client) => ({
    value: client.id,
    label: `${client.nom} ${client.prenom}`,
  }));

  const velolist =
    userId &&
    velos
      .filter((el) => el.user.id === userId.value)
      .map((velo) => ({
        value: velo.id,
        label: `${velo.marque} ${velo.modele}`,
      }));

  const adresse =
    userId &&
    clients
      .filter((el) => el.id === userId.value)
      .map((domicile) => domicile?.reservations[0]?.adresse_domicile);

  const handleForm = (e) => {
    setForm(e.target.value);
  };

  const handleUserId = (userId) => {
    setUserId(userId);
  };

  const handleVeloId = (veloId) => {
    setVeloId(veloId);
  };

  const reservationSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const data = {
      velo_id: veloId?.value,
      date:
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate(),
      date_pem:
        date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate(),
      intervention: reservation.intervention,
      pbm_constates: reservation.pbm_constates,
      adresse_domicile: reservation.adresse_domicile || adresse?.[0],
      maintenance: "[]",
      piece: "[]",
    };
    axios.post(`/api/reservationadd`, data).then((res) => {
      if (res.data.status === 200) {
        setLoading(false);
        history.push("/admin101/maintenance");
      } else {
        setLoading(false);
        setReservation({ ...reservation, error: res.data.errors });
      }
    });
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center container py-5">
        <div className="spinner-border text-success my-5" role="status"></div>
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <div className="d-flex align-items-center justify-content-around mb-4">
        <label htmlFor="curatif" role="button">
          <input
            type="radio"
            onChange={handleForm}
            value="form1"
            checked={form === "form1"}
            id="curatif"
          />
          &nbsp;&nbsp;<span className="text-capitalize">entretien curatif</span>
        </label>
        <label htmlFor="preventif" role="button">
          <input
            type="radio"
            onChange={handleForm}
            value="form2"
            checked={form === "form2"}
            id="preventif"
          />
          &nbsp;&nbsp;
          <span className="text-capitalize">entretien préventif</span>
        </label>
        <label htmlFor="commande" role="button">
          <input
            type="radio"
            onChange={handleForm}
            value="form3"
            checked={form === "form3"}
            id="commande"
          />
          &nbsp;&nbsp;<span className="text-capitalize">commande pièce</span>
        </label>
      </div>
      <div className="card">
        <div className="card-header text-capitalize h4">ajout réservation</div>
        <div className="card-body">
          {form === "form1" && (
            <Form1
              userId={userId}
              veloId={veloId}
              date={date}
              reservation={reservation}
              clientlist={clientlist}
              velolist={velolist}
              adresse={adresse}
              handleUserId={handleUserId}
              handleVeloId={handleVeloId}
              setDate={setDate}
              handleInput={handleInput}
              reservationSubmit={reservationSubmit}
            />
          )}
          {form === "form2" && <Form2 />}
          {form === "form3" && <Form3 />}
        </div>
      </div>
    </div>
  );
}

function Form1({
  userId,
  veloId,
  date,
  reservation,
  clientlist,
  velolist,
  adresse,
  handleUserId,
  handleVeloId,
  setDate,
  handleInput,
  reservationSubmit,
}) {
  return (
    <form
      className="form-group"
      onSubmit={reservationSubmit}
      id="reservation_form"
    >
      <div className="mb-3 form-group d-flex align-items-center justify-content-around">
        <div className="w-25">
          <Select
            options={clientlist}
            value={userId}
            isClearable
            placeholder="Nom du client"
            onChange={handleUserId}
          />
        </div>
        <div className="w-25">
          <Select
            options={velolist}
            value={veloId}
            isClearable
            placeholder="Vélo du client"
            onChange={handleVeloId}
          />
          <span className="text-danger">{reservation.error.velo_id}</span>
        </div>
      </div>
      <div className="mb-3 form-group">
        <Calendar name="date" onChange={setDate} value={date} />
        <span className="text-danger">{reservation.error.date}</span>
      </div>
      <div className="mb-3 form-group">
        <input
          type="text"
          name="intervention"
          className="form-control"
          placeholder="Intervention"
          onChange={handleInput}
          value={reservation?.intervention}
        />
        <span className="text-danger">{reservation.error.intervention}</span>
      </div>
      <div className="mb-3 form-group">
        <input
          type="text"
          name="adresse_domicile"
          className="form-control"
          placeholder="Adresse du client"
          onChange={handleInput}
          value={reservation?.adresse_domicile || adresse}
        />
        <span className="text-danger">
          {reservation.error.adresse_domicile}
        </span>
      </div>
      <div className="mb-3 form-group">
        <textarea
          name="pbm_constates"
          className="form-control"
          placeholder="Expliquez brièvement les problèmes constatés"
          cols="30"
          rows="10"
          onChange={handleInput}
          value={reservation?.pbm_constates}
        ></textarea>
        <span className="text-danger">{reservation.error.pbm_constates}</span>
      </div>
      <div className="row justify-content-center">
        <div className="col-auto">
          <button
            type="submit"
            className="border rounded-pill text-capitalize btn btn-outline-primary"
          >
            ajouter
          </button>
        </div>
        <div className="col-auto">
          <Link
            to="/admin101/reservation"
            className="text-capitalize btn btn-outline-secondary border rounded-pill"
          >
            retour
          </Link>
        </div>
      </div>
    </form>
  );
}

export default ReservationAdd;

import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import "../../../assets/admin/css/modal.css"

function FicheDetails(props) {
  const imgUrl = 'https://api.reparation.mbike.mg/img/fiches/'
  // const imgUrl = 'http://localhost:8000/img/fiches/'
  const print = (id) => {
    document.title = "FT_" + props.detail.velo
    var content = document.getElementById(id).innerHTML
    var origin = document.body.innerHTML
    document.body.innerHTML = content
    window.print()
    document.body.innerHTML = origin
    window.location.reload()
  }

  if(!props.show){
    return null
  }
  return (
    <div className='test-modal' onClick={props.onClose}>
      <div className="test-modal-content" onClick={e => e.stopPropagation()}>
        <div className="test-modal-header">
            <h3 className='text-white'>.</h3>
          <div className='test-print'>
            <button className='btn btn-secondary' onClick={() => {print("fiche")}}><FontAwesomeIcon icon={faPrint} /></button>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button className='btn btn-close' onClick={props.onClose}></button>
          </div>
        </div>
        <div className="test-modal-body" id="fiche">
          <h3 className="test-modal-title text-uppercase">{props.detail.velo}</h3>
          <div className='row row-cols-1 row-cols-md-3 g-3'>
            <div className="col">
              <div className="card">
                <div className="card-body">
                  <img src={imgUrl + props.detail.photo} alt="velo" className='img-fluid w-100' />
                </div>
              </div>
            </div>
            <div className='col'>
              <div className="card">
                <div className="card-body">
                  <span className='fw-bold h6'>Cadre : </span><span>{props.detail.cadre}</span><p></p>
                  <span className='fw-bold h6'>Taille : </span><span>{props.detail.taille}</span><p></p>
                  <span className='fw-bold h6'>Amortisseur : </span><span>{props.detail.amortisseur != null ? props.detail.amortisseur : 'N/A'}</span><p></p>
                  <span className='fw-bold h6'>Fourche : </span><span>{props.detail.fourche}</span><p></p>
                  <span className='fw-bold h6'>Boîtier de pédalier : </span><span>{props.detail.boitier_pedalier}</span><p></p>
                  <span className='fw-bold h6'>Pédalier : </span><span>{props.detail.pedalier}</span><p></p>
                  <span className='fw-bold h6'>Pédales : </span><span>{props.detail.pedale}</span><p></p>
                  <span className='fw-bold h6'>Chaîne : </span><span>{props.detail.chaine}</span><p></p>
                  <span className='fw-bold h6'>Cassette : </span><span>{props.detail.cassette}</span><p></p>
                  <span className='fw-bold h6'>Dérailleur avant : </span><span>{props.detail.derailleur_av != null ? props.detail.derailleur_av : 'N/A'}</span><p></p>
                  <span className='fw-bold h6'>Dérailleur arrière : </span><span>{props.detail.derailleur_ar}</span><p></p>
                  <span className='fw-bold h6'>Manettes : </span><span>{props.detail.manette}</span><p></p>
                  <span className='fw-bold h6'>Freins : </span><span>{props.detail.frein}</span><p></p>
                  <span className='fw-bold h6'>Jeu de direction : </span><span>{props.detail.jeu_direction}</span><p></p>
                  <span className='fw-bold h6'>Potence : </span><span>{props.detail.potence}</span> 
                </div>
              </div>
            </div>
              <div className='col'>
                <div className="card">
                  <div className="card-body">
                    <span className='fw-bold h6'>Tige de selle : </span><span>{props.detail.tige_selle}</span><p></p>
                    <span className='fw-bold h6'>Selle : </span><span>{props.detail.selle}</span><p></p>
                    <span className='fw-bold h6'>Collier de selle : </span><span>{props.detail.collier_selle}</span><p></p>
                    <span className='fw-bold h6'>Poignées : </span><span>{props.detail.poigne}</span><p></p>
                    <span className='fw-bold h6'>Moyeu avant : </span><span>{props.detail.moyeu_av}</span><p></p>
                    <span className='fw-bold h6'>Moyeu arrière : </span><span>{props.detail.moyeu_ar}</span><p></p>
                    <span className='fw-bold h6'>Jantes : </span><span>{props.detail.jante}</span><p></p>
                    <span className='fw-bold h6'>Rayons : </span><span>{props.detail.rayon}</span><p></p>
                    <span className='fw-bold h6'>Pneu avant : </span><span>{props.detail.pneu_av}</span><p></p>
                    <span className='fw-bold h6'>Pneu arrière : </span><span>{props.detail.pneu_ar}</span><p></p>
                    <span className='fw-bold h6'>Couleur : </span><span>{props.detail.couleur}</span><p></p>
                    <span className='fw-bold h6'>Moteur : </span><span>{props.detail.moteur != null ? props.detail.moteur : 'N/A' }</span><p></p>
                    <span className='fw-bold h6'>Battérie : </span><span>{props.detail.batterie != null ? props.detail.batterie : 'N/A' }</span><p></p>
                    <span className='fw-bold h6'>Ecran : </span><span>{props.detail.ecran != null ? props.detail.ecran : 'N/A' }</span><p></p>
                    <span className='fw-bold h6'>Chargeur : </span><span>{props.detail.chargeur != null ? props.detail.chargeur : 'N/A' }</span>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FicheDetails
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../../assets/admin/css/modal.css";
import Devis from "./Devis";
import Facture from "./Facture";

function Details(props) {
  const history = useHistory();
  const [reservation, setReservation] = useState([]);
  const [info, setInfo] = useState({
    etat: "",
    mecano: "",
    date: "",
    date_pem: "",
    hd_pem: "",
    hf_pem: "",
    statut: "",
    lien: "",
    transport: "",
    comments: "",
    error: [],
  });
  const [intervention, setIntervention] = useState(null);
  const [mecanos, setMecanos] = useState([]);
  const [loading, setLoading] = useState(true);
  const id = props.match.params.id;
  const [maintenances, setMaintenances] = useState([]);
  const [pieces, setPieces] = useState([]);

  const addMaintenances = () => {
    setMaintenances([
      ...maintenances,
      { designation: "", quantite: "", prix: "" },
    ]);
  };

  const handleMaintenances = (i, e) => {
    const val = [...maintenances];
    val[i][e.target.name] = e.target.value;
    setMaintenances(val);
  };

  const removeMaintenances = (i) => {
    const val = [...maintenances];
    val.splice(i, 1);
    setMaintenances(val);
  };

  const addPieces = () => {
    setPieces([...pieces, { designation: "", quantite: "", prix: "" }]);
  };

  const handlePieces = (i, e) => {
    const val = [...pieces];
    val[i][e.target.name] = e.target.value;
    setPieces(val);
  };

  const removePieces = (i) => {
    const val = [...pieces];
    val.splice(i, 1);
    setPieces(val);
  };

  useEffect(() => {
    fetchmecano();
    fetch(id);
  }, [id]);

  const fetch = (id) => {
    axios.get(`/api/detail/${id}`).then((res) => {
      if (res.data.status === 200) {
        setIntervention(res.data.details[0].intervention);
        setReservation(res.data.details);
        // setPieces(JSON.parse(res?.data?.details[0]?.piece));
        // setMaintenances(JSON.parse(res?.data?.details[0]?.maintenance));
        const fetchedMaintenances = JSON.parse(res?.data?.details[0]?.maintenance);
        const fetchedPieces = JSON.parse(res?.data?.details[0]?.piece)
        if (Array.isArray(fetchedMaintenances)) {
          setMaintenances(fetchedMaintenances);
        } else {
          setMaintenances([]);
        }
        if (Array.isArray(fetchedPieces)) {
          setPieces(fetchedPieces);
        } else {
          setPieces([]);
        }
        setLoading(false);
      }
    });
  };

  const fetchmecano = () => {
    axios.get("/api/mecanolist").then((res) => {
      setMecanos(res.data.mecano);
    });
  };

  const redirect = () => {
    history.push("/admin101/maintenance");
  };

  const test = (e) => {
    e.persist();
    setInfo({ ...info, [e.target.name]: e.target.value });
  };

  var nom = "";
  reservation.map((el) => {
    return (nom = el.velo.user.nom + " " + el.velo.user.prenom);
  });

  var velo = "";
  reservation.map((el) => {
    return (velo =
      el.velo.type +
      " " +
      el.velo.marque +
      " " +
      el.velo.modele +
      " / Taille " +
      el.velo.taille);
  });

  var phone = "";
  reservation.map((el) => {
    return (phone = el.velo.user.phone);
  });

  var email = "";
  reservation.map((el) => {
    return (email = el.velo.user.email);
  });

  var adresse = "";
  reservation.map((el) => {
    return (adresse = el.adresse_domicile);
  });

  var date = "";
  reservation.map((el) => {
    return (date = el.date);
  });

  var etat = "";
  reservation.map((el) => {
    return (etat = el.etat);
  });

  var mecano = "";
  reservation.map((el) => {
    return (mecano = el.mecano);
  });

  var count_m = 0;
  maintenances?.map((el) => {
    return (count_m += Number(el.prix * el.quantite));
  });

  var count_p = 0;
  pieces?.map((el) => {
    return (count_p += Number(el.prix * el.quantite));
  });

  var count = count_m + count_p;

  var main = "";
  reservation.map((el) => {
    if (el.maintenance) {
      main = JSON.parse(el.maintenance);
    }
    return main;
  });

  var main_t = 0;
  reservation.map((el) => {
    return (main_t = el.count_m);
  });

  var pies = "";
  reservation.map((el) => {
    if (el.piece) {
      pies = JSON.parse(el.piece);
    }
    return pies;
  });

  var pies_t = 0;
  reservation.map((el) => {
    return (pies_t = el.count_p);
  });

  var devis = "";
  reservation.map((el) => {
    return (devis = el.count);
  });

  const mecanolist = mecanos.map((mecano) => (
    <option value={mecano.prenom} key={mecano.id} className="text-capitalize">
      {mecano.prenom}
    </option>
  ));

  var date_pem = "";
  reservation.map((el) => {
    return (date_pem = el.date_pem);
  });

  var hd_pem = "";
  reservation.map((el) => {
    return (hd_pem = el.hd_pem);
  });

  var hf_pem = "";
  reservation.map((el) => {
    return (hf_pem = el.hf_pem);
  });

  var statut = "";
  reservation.map((el) => {
    return (statut = el.statut);
  });

  var unik = "";
  reservation.map((el) => {
    return (unik = el.id);
  });

  var inter = "";
  reservation.map((el) => {
    return (inter = el.intervention);
  });

  var stock = "";
  reservation.map((el) => {
    return (stock = el.mecano);
  });

  var lien = "";
  reservation.map((el) => {
    return (lien = el.lien);
  });

  var transport = "";
  reservation.map((el) => {
    return (transport = el.transport);
  });

  var comments = "";
  reservation.map((el) => {
    return (comments = el.comments);
  });

  const submit = (e) => {
    setLoading(true);
    e.preventDefault();
    var selectEtat = document.getElementById("etat").value;
    var selectMecano = document.getElementById("mecano").value;
    const id = props.match.params.id;
    var datepem = document.getElementById("datepem").value;
    var date = document.getElementById("date").value;
    var link = document.getElementById("lien").value;
    var trans = document.getElementById("transport").value;
    var comms = document.getElementById("comments").value;
    var hdpem = document.getElementById("hdpem").value;
    var hfpem = document.getElementById("hfpem").value;
    var statu = document.getElementById("statut").value;
    var data = {};
    if (intervention === "commande pièce") {
      data = {
        etat: selectEtat,
        mecano: stock,
        piece: pieces,
        count_p: count_p,
        count: count,
        date: date,
        date_pem: datepem,
        lien: link,
        transport: trans,
        comments: comms,
        statut: statu,
      };
    } else if (
      selectEtat === "annulé" &&
      maintenances?.length !== 0 &&
      pieces?.length !== 0
    ) {
      data = {
        etat: selectEtat,
        mecano: "-",
        maintenance: "[]",
        count_m: 0,
        piece: "[]",
        count_p: 0,
        count: 0,
        date_pem: null,
        hd_pem: null,
        hf_pem: null,
        statut: statu,
      };
    } else if (
      selectEtat === "annulé" &&
      maintenances?.length === 0 &&
      pieces?.length === 0
    ) {
      data = {
        etat: selectEtat,
        mecano: "-",
        maintenance: "[]",
        count_m: 0,
        piece: "[]",
        count_p: 0,
        count: 0,
        date_pem: null,
        hd_pem: null,
        hf_pem: null,
        statut: statu,
      };
    } else if (selectEtat === "annulé" && maintenances?.length !== 0) {
      data = {
        etat: selectEtat,
        mecano: "-",
        maintenance: "[]",
        count_m: 0,
        piece: "[]",
        count_p: 0,
        count: 0,
        date_pem: null,
        hd_pem: null,
        hf_pem: null,
        statut: statu,
      };
    } else if (selectEtat === "annulé" && maintenances?.length === 0) {
      data = {
        etat: selectEtat,
        mecano: "-",
        maintenance: "[]",
        count_m: 0,
        piece: "[]",
        count_p: 0,
        count: 0,
        date_pem: null,
        hd_pem: null,
        hf_pem: null,
        statut: statu,
      };
    } else if (selectEtat === "annulé") {
      data = {
        etat: selectEtat,
        mecano: "-",
        maintenance: "[]",
        count_m: 0,
        piece: "[]",
        count_p: 0,
        count: 0,
        date_pem: null,
        hd_pem: null,
        hf_pem: null,
        statut: statu,
      };
    } else if (selectEtat === "a traiter") {
      data = {
        etat: "a traiter",
        mecano: selectMecano,
        maintenance: maintenances,
        count_m: count_m,
        piece: pieces,
        count_p: count_p,
        count: count,
        date_pem: datepem,
        hd_pem: hdpem,
        hf_pem: hfpem,
        statut: statu,
      };
    } else if (maintenances?.length !== 0 && pieces?.length !== 0) {
      data = {
        etat: selectEtat,
        mecano: selectMecano,
        maintenance: maintenances,
        count_m: count_m,
        piece: pieces,
        count_p: count_p,
        count: count,
        date_pem: datepem,
        hd_pem: hdpem,
        hf_pem: hfpem,
        statut: statu,
      };
    } else if (maintenances?.length !== 0) {
      data = {
        etat: selectEtat,
        mecano: selectMecano,
        maintenance: maintenances,
        count_m: count_m,
        count: count,
        date_pem: datepem,
        hd_pem: hdpem,
        hf_pem: hfpem,
        statut: statu,
      };
    } else {
      data = {
        etat: selectEtat,
        mecano: selectMecano,
        date_pem: datepem,
        hd_pem: hdpem,
        hf_pem: hfpem,
        statut: statu,
      };
    }
    axios.put(`api/reservationupdate/${id}`, data).then((res) => {
      if (res.data.status === 200) {
        redirect("/admin101/maintenance");
      } else {
        setInfo({ ...info, error: res.data.errors });
        setLoading(false);
      }
    });
  };

  if (loading) {
    return (
      <div className="test-modal">
        <div className="test-modal-content">
          <div className="d-flex justify-content-center m-5">
            <div className="spinner-border text-success" role="status"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div onClick={redirect} className="test-modal">
      <div className="test-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="test-modal-header">
          <h6 className="test-modal-title text-capitalize">
            {intervention !== "commande pièce"
              ? "maintenance"
              : "commande pèce"}{" "}
            pour {nom}
          </h6>
          <div className="d-flex">
            <Devis
              unik={unik}
              date_pem={date}
              nom={nom}
              email={email}
              phone={phone}
              main={main}
              main_t={main_t}
              pies={pies}
              pies_t={pies_t}
              total={devis}
              velo={velo}
              inter={inter}
            />
            &nbsp;&nbsp;&nbsp;
            <Facture
              unik={unik}
              date_pem={date}
              nom={nom}
              email={email}
              phone={phone}
              main={main}
              main_t={main_t}
              pies={pies}
              pies_t={pies_t}
              total={devis}
              velo={velo}
              inter={inter}
            />
            &nbsp;&nbsp;&nbsp;
            <button className="btn btn-close" onClick={redirect}></button>
          </div>
        </div>
        <div className="test-modal-body">
          <form>
            <div className="card mb-3 border-0">
              <div className="card-body">
                <h5 className="card-title text-capitalize text-center mb-sm-3">
                  {nom}
                </h5>
                <div className="row row-cols-1 row-cols-md-2 g-2 m-auto">
                  <div className="col-md-6 mb-sm-3 text-center">
                    <h6 className="card-title">Phone : {phone}</h6>
                    <h6 className="card-title">Email : {email}</h6>
                    <h6 className="card-title">Adresse : {adresse}</h6>
                    <h6 className="card-title">
                      Velo : <span className="text-uppercase">{velo}</span>{" "}
                    </h6>
                    <h6 className="card-title">
                      Intervention :{" "}
                      <span className="text-uppercase">{inter}</span>{" "}
                    </h6>
                  </div>
                  <div className="col-md-6 mb-sm-3 text-center">
                    {intervention !== "commande pièce" ? (
                      <>
                        <h6 className="card-title">
                          Date de réservation : {date}
                        </h6>
                        <input type="hidden" id="date" value={date} />
                        <input type="hidden" id="lien" value="" />
                        <input type="hidden" id="transport" value="" />
                        <input type="hidden" id="comments" value="" />
                      </>
                    ) : (
                      <>
                        <h6 className="card-title">
                          Utile le :{" "}
                          <input
                            type="date"
                            name="date"
                            id="date"
                            onChange={test}
                            value={info.date || date}
                          />
                        </h6>
                      </>
                    )}
                    {intervention !== "commande pièce" ? (
                      <>
                        <h6 className="card-title">
                          Date et heure de prise en charge : <br />{" "}
                          <input
                            type="date"
                            name="date_pem"
                            id="datepem"
                            onChange={test}
                            value={info.date_pem || date_pem}
                          />
                          &nbsp;&nbsp;De&nbsp;&nbsp;
                          <input
                            type="time"
                            name="hd_pem"
                            id="hdpem"
                            onChange={test}
                            value={info.hd_pem || hd_pem}
                          />
                          &nbsp;&nbsp;à&nbsp;&nbsp;
                          <input
                            type="time"
                            name="hf_pem"
                            id="hfpem"
                            onChange={test}
                            value={info.hf_pem || hf_pem}
                          />
                        </h6>
                      </>
                    ) : (
                      <>
                        <h6 className="card-title">
                          À commander le :{" "}
                          <input
                            type="date"
                            name="date_pem"
                            id="datepem"
                            onChange={test}
                            value={info.date_pem || date_pem}
                          />
                        </h6>
                        <input type="hidden" id="hdpem" value={null} />
                        <input type="hidden" id="hfpem" value={null} />
                      </>
                    )}
                    <h6 className="card-title">
                      Etat : &nbsp;&nbsp;
                      <select
                        name="etat"
                        id="etat"
                        className="text-capitalize"
                        value={info.etat || etat}
                        onChange={test}
                      >
                        <option value="a traiter" className="text-capitalize">
                          à traiter
                        </option>
                        <option value="en attente" className="text-capitalize">
                          en attente
                        </option>
                        <option value="en cours" className="text-capitalize">
                          en cours
                        </option>
                        <option value="réalisé" className="text-capitalize">
                          réalisé
                        </option>
                        <option value="annulé" className="text-capitalize">
                          annulé
                        </option>
                      </select>
                    </h6>
                    {intervention !== "commande pièce" ? (
                      <>
                        <h6 className="card-title">
                          Mécanicien : &nbsp;&nbsp;
                          <select
                            name="mecano"
                            id="mecano"
                            className="text-capitalize"
                            value={info.mecano || mecano}
                            onChange={test}
                          >
                            <option value="" className="text-capitalize">
                              mécanicien
                            </option>
                            {mecanolist}
                          </select>
                          <br />
                          <span className="text-danger">
                            {info.error.mecano}
                          </span>
                        </h6>
                      </>
                    ) : (
                      <>
                        <h6 className="card-title">
                          Responsable : &nbsp;&nbsp;
                          <span className="h6 text-capitalize">{stock}</span>
                        </h6>
                        <input id="mecano" value={stock} type="hidden" />
                      </>
                    )}
                    {etat === "réalisé" ? (
                      <h6 className="card-title">
                        Statut : &nbsp;&nbsp;
                        <select
                          name="statut"
                          id="statut"
                          className="text-capitalize"
                          value={info.statut || statut}
                          onChange={test}
                        >
                          <option
                            value="non payé - non livré"
                            className="text-capitalize"
                          >
                            non payé - non livré
                          </option>
                          <option
                            value="payé - livré"
                            className="text-capitalize"
                          >
                            payé - livré
                          </option>
                        </select>
                      </h6>
                    ) : (
                      <input
                        type="hidden"
                        name="statut"
                        id="statut"
                        value="non payé - non livré"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                intervention !== "commande pièce"
                  ? "row row-cols-1 row-cols-md-2 g-2"
                  : ""
              }
            >
              <div className="col mb-sm-3">
                {intervention !== "commande pièce" ? (
                  <div className="card mb-3">
                    <div className="card-header text-white bg-success d-flex justify-content-between">
                      <h5 className="card-title">Main d'œuvre</h5>
                      <FontAwesomeIcon
                        className="btn btn-light fw-bold"
                        icon={faPlus}
                        onClick={addMaintenances}
                      />
                    </div>
                    <div className="card-body">
                      {Array.isArray(maintenances) && maintenances?.map((maintenance, i) => (
                        <div
                          key={i}
                          className="d-flex justify-content-between mb-3"
                        >
                          <input
                            type="text"
                            className="form-control"
                            name="designation"
                            onChange={(e) => handleMaintenances(i, e)}
                            value={maintenance.designation}
                            placeholder="Désignation"
                          />
                          <input
                            type="number"
                            min="0"
                            className="form-control mx-2 w-25"
                            name="quantite"
                            onChange={(e) => handleMaintenances(i, e)}
                            value={maintenance.quantite}
                            placeholder="Quantité"
                          />
                          <input
                            type="number"
                            min="0"
                            className="form-control mx-2 w-50"
                            name="prix"
                            onChange={(e) => handleMaintenances(i, e)}
                            value={maintenance.prix}
                            placeholder="Prix Unitaire"
                          />
                          <FontAwesomeIcon
                            className="btn btn-danger fw-bold"
                            icon={faMinus}
                            onClick={() => removeMaintenances(i)}
                          />
                        </div>
                      ))}
                      {count_m ? (
                        <p className="h6 fw-bold text-end">
                          Sous-total main d'œuvre :{" "}
                          {count_m
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                          Ar
                        </p>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className="card mb-3">
                    <div className="card-header text-white bg-success d-flex justify-content-between">
                      <h5 className="card-title">Informations</h5>
                    </div>
                    <div className="card-body">
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          name="lien"
                          id="lien"
                          value={info.lien || lien}
                          onChange={test}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <select
                          name="transport"
                          id="transport"
                          className="form-select text-capitalize"
                          value={info.transport || transport}
                          onChange={test}
                        >
                          <option value="" className="text-capitalize">
                            transport
                          </option>
                          <option value="aérien" className="text-capitalize">
                            aérien
                          </option>
                          <option value="maritime" className="text-capitalize">
                            maritime
                          </option>
                        </select>
                      </div>
                      <div className="form-group">
                        <textarea
                          className="form-control"
                          value={info.comments || comments}
                          onChange={test}
                          name="comments"
                          id="comments"
                          cols="30"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col mb-sm-3">
                <div className="card mb-3">
                  <div className="card-header text-white bg-success d-flex justify-content-between">
                    <h5 className="card-title">Pièces</h5>
                    <FontAwesomeIcon
                      className="btn btn-light fw-bold"
                      icon={faPlus}
                      onClick={addPieces}
                    />
                  </div>
                  <div className="card-body">
                    {pieces?.map((piece, i) => (
                      <div
                        key={i}
                        className="d-flex justify-content-between mb-3"
                      >
                        <input
                          type="text"
                          className="form-control"
                          name="designation"
                          onChange={(e) => handlePieces(i, e)}
                          value={piece.designation}
                          placeholder="Désignation"
                        />
                        <input
                          type="number"
                          min="0"
                          className="form-control mx-2 w-25"
                          name="quantite"
                          onChange={(e) => handlePieces(i, e)}
                          value={piece.quantite}
                          placeholder="Quantité"
                        />
                        <input
                          type="number"
                          min="0"
                          className="form-control mx-2 w-50"
                          name="prix"
                          onChange={(e) => handlePieces(i, e)}
                          value={piece.prix}
                          placeholder="Prix Unitaire"
                        />
                        <FontAwesomeIcon
                          className="btn btn-danger fw-bold"
                          icon={faMinus}
                          onClick={() => removePieces(i)}
                        />
                      </div>
                    ))}
                    {count_p ? (
                      <p className="h6 fw-bold text-end">
                        Sous-total pièce :{" "}
                        {count_p
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                        Ar
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <p className="fw-bold">
              <span className="h4">
                Total : {count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}{" "}
                Ar
              </span>
            </p>
            <p className="text-center pt-3">
              <button
                className="border btn btn-outline-primary rounded-pill text-capitalize me-4"
                onClick={submit}
              >
                enregistrer
              </button>
              <button
                className="border btn btn-outline-secondary rounded-pill text-capitalize ms-4"
                onClick={redirect}
              >
                annuler
              </button>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Details;

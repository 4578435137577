import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

function Rapport() {
  const [rapports, setRapports] = useState([]);
  const [mecanos, setMecanos] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectMecano, setSelectMecano] = useState("");
  const [typeIntervention, setTypeIntervention] = useState();

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = () => {
    axios.get(`/api/rapportactivity`).then((res) => {
      setRapports(res.data.rapportactivity);
      setMecanos(res.data.mecano);
    });
  };

  const handleStartDate = (date) => {
    setStartDate(date);
  };

  const handleEndDate = (date) => {
    setEndDate(date);
  };

  const handleSelectMecano = (e) => {
    setSelectMecano(e.target.value);
  };

  const handleTypeIntervention = (e) => {
    setTypeIntervention(e.target.value);
  };

  const filtered = rapports.filter((item) => {
    const itemDate = moment(item.date_pem, "YYYY-MM-DD");
    if (
      startDate &&
      endDate &&
      selectMecano !== "" &&
      typeIntervention === "curatif"
    ) {
      return (
        itemDate.isBetween(startDate, endDate, "day", "[]") &&
        item.mecano === selectMecano &&
        item.intervention !== "commande pièce" &&
        item.intervention !== "entretien préventif"
      );
    } else if (startDate && endDate) {
      return itemDate.isBetween(startDate, endDate, "day", "[]");
    } else if (selectMecano !== "") {
      return item.mecano === selectMecano;
    } else if (
      startDate &&
      endDate &&
      selectMecano !== "" &&
      typeIntervention === "preventif"
    ) {
      return (
        itemDate.isBetween(startDate, endDate, "day", "[]") &&
        item.mecano === selectMecano &&
        item.intervention === "entretien préventif"
      );
    } else if (
      startDate &&
      endDate &&
      selectMecano !== "" &&
      typeIntervention === "commande"
    ) {
      return (
        itemDate.isBetween(startDate, endDate, "day", "[]") &&
        item.mecano === selectMecano &&
        item.intervention === "commande pièce"
      );
    } else if (typeIntervention === "preventif") {
      return item.intervention === "entretien préventif";
    } else if (typeIntervention === "commande") {
      return item.intervention === "commande pièce";
    } else if (typeIntervention === "curatif") {
      return (
        item.intervention !== "commande pièce" &&
        item.intervention !== "entretien préventif"
      );
    }
    return true;
  });

  var c = 0;
  filtered.map((el) => {
    return (c += Number(el.count));
  });

  var m = 0;
  filtered.map((el) => {
    return (m += Number(el.count_m));
  });

  return (
    <div className="container-fluid mt-4">
      <div className="card">
        <div className="card-header text-capitalize">
          <p className="h4 text-center">rapport d'activités</p>
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-around mb-3 form-group">
            <div className="d-flex align-items-center">
              <div>Début : &nbsp;</div>
              <div>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => handleStartDate(e.target.value)}
                />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div>Fin : &nbsp;</div>
              <div>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) => handleEndDate(e.target.value)}
                />
              </div>
            </div>
            <div>
              <select
                name="mecano"
                id="mecano"
                value={selectMecano}
                onChange={handleSelectMecano}
                className="form-select text-capitalize"
              >
                <option value="" className="text-capitalize">
                  responsable
                </option>
                {mecanos.map((mecano) => (
                  <option
                    key={mecano.id}
                    value={mecano.prenom}
                    className="text-capitalize"
                  >
                    {mecano.prenom}
                  </option>
                ))}
                <option value="stock" className="text-capitalize">
                  stock
                </option>
              </select>
            </div>
            <div>
              <select
                name="mecano"
                id="mecano"
                value={typeIntervention}
                onChange={handleTypeIntervention}
                className="form-select text-capitalize"
              >
                <option value="" className="text-capitalize">
                  type intervention
                </option>
                <option value="curatif" className="text-capitalize">
                  curative
                </option>
                <option value="preventif" className="text-capitalize">
                  préventive
                </option>
                <option value="commande" className="text-capitalize">
                  commande pièce
                </option>
              </select>
            </div>
          </div>
          <table className="table table-bordered table-striped">
            <thead>
              <tr className="text-capitalize text-center">
                <th>
                  <span className="btn fw-bold pe-none">facture n°</span>
                </th>
                <th>
                  <span className="btn fw-bold pe-none">client</span>
                </th>
                <th>
                  <span className="btn fw-bold pe-none">
                    date & heure de prise en charge
                  </span>
                </th>
                <th>
                  <span className="btn fw-bold pe-none">durée</span>
                </th>
                <th>
                  <span className="btn fw-bold pe-none">responsable</span>
                </th>
                <th>
                  <span className="btn fw-bold pe-none">état</span>
                </th>
                <th>
                  <span className="btn fw-bold pe-none">main d'oeuvre</span>
                </th>
                <th>
                  <span className="btn fw-bold pe-none">montant</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {filtered.map((item) =>
                item.etat === "annulé" || item.date_pem === null ? null : (
                  <tr key={item.id}>
                    <td className="text-capitalize">
                      {item.date_pem !== null
                        ? item.date_pem.slice(0, 7)
                        : "N/A"}
                      -
                      {item.id < 10
                        ? "00" + item.id
                        : item.id < 100
                        ? "0" + item.id
                        : item.id}
                    </td>
                    <td className="text-center text-capitalize">
                      {item.velo.user.nom} {item.velo.user.prenom} -{" "}
                      <span className="text-uppercase">
                        {item.velo.type} {item.velo.marque} {item.velo.modele}
                      </span>
                    </td>
                    <td className="text-center">
                      {item.date_pem === null ? "N/A" : item.date_pem} de{" "}
                      {item.hd_pem === null ? "N/A" : item.hd_pem.slice(0, 5)} à{" "}
                      {item.hf_pem === null ? "N/A" : item.hf_pem.slice(0, 5)}
                    </td>
                    <td className="text-center">
                      {(item.hf_pem ? item.hf_pem.slice(0, 2) : null) -
                        (item.hd_pem ? item.hd_pem.slice(0, 2) : null)}{" "}
                      h{" "}
                      {(item.hf_pem ? item.hf_pem.slice(3, 5) : null) -
                        (item.hd_pem ? item.hd_pem.slice(3, 5) : null)}{" "}
                      mn
                    </td>
                    <td className="text-center text-capitalize">
                      {item.mecano}
                    </td>
                    <td className="text-center text-capitalize">{item.etat}</td>
                    <td className="text-end">
                      {item.count_m
                        ? item.count_m
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                        : 0}{" "}
                      Ar
                    </td>
                    <td className="text-end">
                      {item.count
                        ? item.count
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
                        : 0}{" "}
                      Ar
                    </td>
                  </tr>
                )
              )}
              <tr>
                <td className="text-end fw-bolder" colSpan="6">
                  Total
                </td>
                <td className="text-center fw-bolder">
                  {m.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ar
                </td>
                <td className="text-center fw-bolder">
                  {c.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} Ar
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Rapport;

import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import axios from 'axios'
import FicheDetails from './FicheDetails'

function Fiche() {
  const imgUrl = 'https://api.reparation.mbike.mg/img/fiches/'
  // const imgUrl = 'http://localhost:8000/img/fiches/'
  const [fiches, setFiches] = useState([])
  const [show, setShow] = useState(false)
  const [tempdata, setTempdata] = useState([])

  const click = (detail) => {
    setTempdata(detail)
    setShow(true)
  }

  useEffect(() => {
    fetchdata()
  }, [])

  const fetchdata = () => {
    axios.get('/api/fichelist').then(res => {
      setFiches(res.data.fiche)
    })
  }

  const fichedelete = (id) => {
    axios.delete(`/api/fichedelete/${id}`).then(res => {
      fetchdata()
    })
  }

  return (
    <div className='container mt-5'>
      <div className="d-flex flex-row-reverse">
        <Link to='/admin101/ficheadd' className='btn btn-primary'>Ajouter une fiche technique</Link>
      </div>
      <div className="mt-4">
        <div className="card">
          <div className="card-header text-capitalize">
            <p className="h4 text-center">bibliothèque de vélos</p>
          </div>
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr className="text-capitalize text-center">
                  <th><span className='btn fw-bold pe-none'>photo</span></th>
                  <th><span className='btn fw-bold pe-none'>vélo</span></th>
                  <th><span className='btn fw-bold pe-none'>actions</span></th>
                </tr>
              </thead>
              <tbody>
                {fiches ? fiches.map(fiche =>
                  <tr key={fiche.id}>
                    <td className='text-center w-50'><img src={imgUrl + fiche.photo} alt="" className='img-fluid rounded w-50' /></td>
                    <td className='text-center text-capitalize fw-bold'>{fiche.velo}</td>
                    <td className='d-flex justify-content-around'>
                      <FontAwesomeIcon icon={faNewspaper} className='btn btn-info' onClick={() => click(fiche)} />
                      <FontAwesomeIcon icon={faTrash} className='btn btn-danger' onClick={() => fichedelete(fiche.id)} />
                    </td>
                  </tr>) : null
                  }
              </tbody>
            </table>
          </div>
        </div>
        <FicheDetails show={show} detail={tempdata} onClose={() => setShow(false)} />
      </div>
    </div>
  )
}

export default Fiche
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import MecanoAdd from './MecanoAdd'

function Mecano() {
  const [test, setTest] = useState(false)
  const [mecanos, setMecanos] = useState([])
  const [camecano, setCamecano] = useState([])

  useEffect(() => { 
    fetchdata()
    setTest(false)
  }, [test])
  
  const fetchdata = () => {
    axios.get('/api/mecanolist').then(res => {
      setMecanos(res.data.mecano)
      setCamecano(res.data.camecano)
    })
  }

  const mecanodelete = (id) => {
    axios.delete(`/api/mecanodelete/${id}`).then(res => {
      fetchdata()
    })
  }

  return (
    <div className='container mt-5'>
    <div className='d-flex flex-row-reverse'>
      <MecanoAdd setTest={setTest} />
    </div>
      <div className="mt-4">
        <div className="card">
          <div className="card-header text-capitalize">
            <p className='h4 text-center'>liste des mécanos</p>
          </div>
          <div className="card-body">
            <table className="table table-bordered table-striped">
              <thead>
                <tr className="text-capitalize text-center">
                  <th><span className='btn fw-bold pe-none'>prénom</span></th>
                  <th><span className='btn fw-bold pe-none'>C. A. généré</span></th>
                  <th><span className='btn fw-bold pe-none'>actions</span></th>
                </tr>
              </thead>
              <tbody>
                {mecanos ? mecanos.map(mecano => 
                  <tr key={mecano.id} className='text-center'>
                    <td className='text-capitalize'>{mecano.prenom}</td>
                    <td className='text-capitalize'>{camecano ? camecano.map(ca => 
                    (mecano.prenom === ca.mecano ? <span key={ca.ca}>{ca.ca ? (ca.ca).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0} ar</span> : null)) : null}</td>
                    <td>
                      <span><FontAwesomeIcon icon={faTrashCan} className='btn btn-danger' onClick={() => mecanodelete(mecano.id)} /></span>
                    </td>
                  </tr>) : null
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mecano

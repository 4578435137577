import axios from 'axios'
import React, { useState } from 'react'

function MecanoAdd({setTest}) {
  const [mecano, setMecano] = useState({
    prenom:'',
  })

  const handleInput = (e) => {
    e.persist()
    setMecano({...mecano, [e.target.name]: e.target.value })
  }

  const mecanoSubmit = (e) => {
    e.preventDefault()
    const data = {
      prenom: mecano.prenom,
    }
    axios.post(`/api/mecanoadd`, data).then((res) => {
      setTest(true)
      setMecano({prenom:'',})
    })
  }

  return (
    <div className='container text-center mt-5'>
      <div className='card'>
        <div className='card-header text-capitalize h4'>ajout mécano</div>
        <div className='card-body'>
          <form className='form-group' onSubmit={mecanoSubmit} id="mecano_form">
            <div className="row justify-content-center">
              <div className="col-auto">
                <input type="text" name='prenom' className="form-control" placeholder="Prénom" onChange={handleInput} value={mecano.prenom} />
              </div>
              <div className="col-auto">
                <button type='submit' className="border rounded-pill text-capitalize btn btn-outline-primary">ajouter</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default MecanoAdd

import axios from 'axios'
import React from 'react'
import {Link, useHistory} from 'react-router-dom'
import logo from '../../assets/client/icons/logo.png'
import swal from 'sweetalert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTachometerAltFast, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

function Navbar() {
    const history = useHistory()
    const logout = (e) => {
        e.preventDefault()
        axios.post('/api/logout').then(res => {
            if (res.data.status === 200){
                localStorage.clear()
                swal('Success', res.data.message, 'success')
                history.push('/')
            }
        })
    }
    var Authlinks = ''
    if (!localStorage.getItem('XSRF-TOKEN=eyJpdiI6IlRJb21lQUZHV21aN1RIUDRvaHF0cVE9PSIsInZhbHVlIjoiSzNyQ0M5WUZvQ3B3RFhNZk9Pd3p6UVZ5S2k0bk9tcUNuWnVGVis0ZkhGSHJ1TFp4TXBsTXF6U0FaME9mV0hrOFlUWjVZdGtJQnAxZ0RhaGhBR2NGa2xZRTJnWDVIYXVOVlU5TjlOeFpWTFEzVTdacnozeE9lNjNkRkU1cTEwcGQiLCJtYWMiOiI1ZjRjOTEyZjczOGRmNDc1NGIxZDA0YTM1YWVlMzU1YzhjYTMxNWIxZWJjMGFmNTY5NzcxMzVlNGU0YTg5YTAzIiwidGFnIjoiIn0%3D')) {
        Authlinks = (
            <button className='border rounded btn btn-outline-light'><Link className='nav-link p-1 text-capitalize text-black' to="/login" >connexion</Link></button>
        )
    } else {
        Authlinks = (
            <ul className='navbar-nav'>
                <li className="nav-item dropdown">
                    <Link to="#" className="nav-link dropdown-toggle text-capitalize" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">{localStorage.getItem('auth_name')}</Link>
                    <ul className="dropdown-menu dropdown-menu-end text-capitalize" aria-labelledby="navbarDropdown">
                        <li><Link className="dropdown-item" to="/account/dashboard"><FontAwesomeIcon icon={faTachometerAltFast} /> dashboard</Link></li>
                        <li><hr className="dropdown-divider" /></li>
                        <li><Link to="" className="dropdown-item" onClick={logout}><FontAwesomeIcon icon={faSignOutAlt} /> se deconnecter</Link></li>
                    </ul>
                </li>
            </ul>
        )
    }
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-success">
        <div className="container-fluid">
            <Link className="navbar-brand" to="/"><img src={logo} alt="" width="100%" /></Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                        <Link className="nav-link active text-capitalize" aria-current="page" to="/">accueil</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link active text-capitalize" to="/tarif">nos tarifs</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link active text-capitalize" to="/contact">contact</Link>
                    </li>
                    </ul>
                    {Authlinks}
            </div>
        </div>
    </nav>
  )
}

export default Navbar
